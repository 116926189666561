// import { mapTo } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import {mapTo, delay} from 'rxjs/operators'
import actions from '../actions/constants'
const loaderStartEpic = action$ =>
    action$.pipe(
        ofType(
            actions.FETCH_REGAVAILAIBILITY,
            actions.UPDATE_REGAVAILABILITY,
            actions.RESET_REGAVAILAIBILITY,
            actions.AUTH_USER,
            actions.GET_PATIENT_USER_SLOTS,
            actions.GET_CANCELLED_APPOINTMENTS,
            actions.GET_AVAILABLESLOT_USERS,
            actions.GET_ALL_PENDING_PATINET_BOOKINGS,
            actions.GET_ALL_BOOKED_PATIENTS,
            actions.GET_ALL_CLERKED_PATIENTS,
            actions.GET_ALL_REGISTRAT_DNA,
            actions.GET_ALL_PATIENT_DNA,
            actions.GET_ALL_REGISTRAR_PATIENT_BOOKING,
            actions.GET_ALL_AWAITING_BOOKED_PATIENTS,
            ),
            delay(100), 
            mapTo({
                type:  actions.IS_FETCHING
        })
    );

export default loaderStartEpic