import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../src/components';
import Login from "./pages/Login/login";
import { userService } from './service/UserService';
import RegAvalability from "./pages/RegAvailability/index";
import Appointment from "./pages/Appointment/index";
import Admin from "./pages/Admin/index";
import PatientLogin from "./pages/Login/PatientLogin";
import Loading from './components/Container/Loading/index'
import actions from './store/actions/constants'
import { connect } from 'react-redux'
import fetch from './service/Fetch/fetchApi'
import urls from './util/urls'
import ErrorBoundary from './components/ErrorBoundary/index'
import Close from './pages/Thanks/close'
import Cancel from './pages/Thanks/cancel'
import Error500 from './pages/Error/error500'
import ErrorPage from './pages/Error/index'

const mapStateToProps = state => ({
    userDetails: state.userDetails.data
})

const mapDispatchToProps = dispatch => ({
    UserValidation: (payload) => {
        dispatch({
            type: actions.AUTH_USER,
            payload
        });
    },
    dispatch: dispatch,

})
class App extends Component {
    constructor(props) {
        localStorage.clear();
        super(props);
        this.state = {
            loading: true,
            error: null,
        };
        this.stateloading = this.stateloading.bind(this);
    }

    componentDidMount() {
        const data = this.getParameterByName()
        if (data) {
            this.stateloading(true);
            localStorage.clear();
            this.userValidation(data);
        }
        else {
            this.stateloading(false);
            this.renderredirect(null)
        }
    }

    userValidation(token) {
        fetch(`${urls.AuthTokenUser}`,
            {
                "Token": token,
            },
            actions.AUTH_USER)
            .then(response => {
                if (response) {
                    this.props.dispatch({
                        type: actions.AUTH_USER_SUCCESS,
                        payload: response
                    });
                }
                if (response.Token) {
                    if (response.UserType == "PAT") {
                        sessionStorage.setItem('token', '?' + token);
                    }
                    window.localStorage.setItem('token', response.Token);
                    window.localStorage.setItem('userid', response.UserId);
                    window.localStorage.setItem('usertype', response.UserType);
                    this.setState({ error: null })
                    this.stateloading(false);
                    this.renderredirect(response.UserType)
                }
                else {
                    userService.clearall();
                    this.setState({ error: this.props.userDetails.Message, errorObject:this.props.userDetails })
                    this.stateloading(false);
                }
            }).catch((error) => {
                this.setState({ error })
                console.log(error);
                userService.clearall();
                this.stateloading(false);

            })
    }
    renderredirect(usertype) {
        if (usertype === null) {
            return <Redirect to='/PatientLogin' />
        }
        else if (usertype === "SR") {
            return <Redirect to='/RegAvalability' />
        }
        else if (usertype === "MedeferAdmin") {
            return <Redirect to='/Admin' />
        }
        else if (usertype === "PAT") {
            return <Redirect to='/Appointment' />
        }
    }
    componentWillUnmount() {
        localStorage.clear();
    }
    stateloading(value) {
        this.setState({ loading: value });
    }
    getParameterByName() {
        var token = sessionStorage.getItem('token');
        var matchToken;
        if (window.location.search && window.location.search != '') {
            matchToken = window.location.search;
        }
        else {
            matchToken = token ?? '';
        }
        var match = RegExp('[?&]([^&]*)').exec(matchToken);
        return match && decodeURIComponent(match[1]);
    }

    render() {

        return (
            <React.Fragment>
                <ErrorBoundary>
                    {!this.state.loading && this.state.error ?
                            <React.Fragment>
                                <ErrorPage errorObject={this.state.errorObject} errorMessage={this.state.error} ></ErrorPage>
                            </React.Fragment>
                        : !this.state.loading && !this.state.error ?
                            <div className="App">
                                <Router>
                                    <Switch>
                                        <PrivateRoute path='/Admin' component={Admin} />
                                        <PrivateRoute path='/RegAvalability' component={RegAvalability} />
                                        <PrivateRoute path='/Appointment' component={Appointment} />
                                        <Route path='/PatientLogin' component={PatientLogin} />
                                        <Route exact path='/login' component={Login} />
                                        <Route exact path='/close' component={Close} />
                                        <Route exact path='/cancel' component={Cancel} />
                                        <Route exact path='/server-error' component={Error500} />
                                        <Route path='*' component={Admin} />
                                    </Switch>
                                </Router>
                            </div>
                            :
                            <div className="App">
                                <React.Fragment>
                                    <div className={"initialLoad m-2"} >
                                        <p className="m-auto">Connecting to Appointment Application</p>
                                        <div className="m-auto loader"></div>
                                    </div>
                                </React.Fragment>
                            </div>
                    }
                    <Loading />
                </ErrorBoundary>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
