import fetch from 'isomorphic-fetch'
import settings from '../../AppSettings.json'
import utility from '../../util/utility'

const authString = 'Basic ' + Buffer.from('medapiuser:ap%4dgajh09876Shjg1$#10j*gtf132', 'ascii').toString('base64');
var domainURL=utility.getUrlConfig(process.env.REACT_APP_DOMAIN);
const constructRequestOption = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authString
        },
        body: JSON.stringify(data)
    };
    return requestOptions
}

const GlobalFetch = (SERVICE_URL, data, action) => {
    SERVICE_URL = domainURL + SERVICE_URL;
    let option = constructRequestOption(data);

    return fetch(SERVICE_URL, option).then((response) => {
        const statusCode = response.status;
        const data = response.json();
        if (response.status !== 200) {
            window.location.href = '/server-error';
        }
        else {
            return Promise.all([statusCode, action, data]);
        }
    }).then((response) => {
        const status = response[0];
        response = response[2];
        if (status >= 400) {
            window.location.href='/server-error'
        }  else {
            return response
        }
    }, (err) => {
        throw new Error(err);
    }).catch((err) => {
        throw new Error(err);
    })
}


export default GlobalFetch
