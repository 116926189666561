import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Reg from '@material-ui/icons/LocalHospital';
import Booked from '@material-ui/icons/CalendarTodayTwoTone';
import TabPanel from '../Admin/utils/tabPanel';
import MyCalendar from './calendar'
import actions from '../../store/actions/constants'
import BookedPatients from './booked'
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const mapStateToProps = state => ({
    registrarDetails: state.regAvailability,
    userDetails: state.userDetails.data,
    adminDetails: state.admin
})

const mapDispatchToProps = dispatch => ({
    getRegistrarAvailability: (payload) => {
        dispatch({
            type: actions.FETCH_REGAVAILAIBILITY,
            payload
        });
    },
    resetData: () => {
        dispatch({
            type: actions.RESET_REGAVAILAIBILITY
        })
    },
    getAllPendingPatinetBookings: () => {
        dispatch({
            type: actions.GET_ALL_PENDING_PATINET_BOOKINGS,
        });
    },
    resetPendingPatinetBookings: () => {
        dispatch({
            type: actions.RESET_ALL_PENDING_PATINET_BOOKINGS,
        });
    },
    fetchingDone: () => {
        dispatch({
            type: actions.FETCHING_DONE
        });
    },
    isFetching: () => {
        dispatch({
            type: actions.IS_FETCHING
        });
    },
    getAllBookedPatinets: (payload) => {
        dispatch({
            type: actions.GET_ALL_REGISTRAR_PATIENT_BOOKING,
            payload
        })
    },
    resetAllBookedPatinets: () => {
        dispatch({
            type: actions.RESET_REGISTRAR_PATIENT_BOOKING,

        })
    },
    dispatch: dispatch,

})
class RegAvailability extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };

    }
    UNSAFE_componentWillMount() {
        this.UserId = this.props.userDetails.UserId;
        this.usertype = this.props.userDetails.UserType;

    }
    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };
    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };


    render() {
        return (
            <React.Fragment>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active">Registrar Availability</li>
                            </ol>
                            <TabContext value={this.state.value}>
                                <AppBar position="static" color="default">
                                    <Tabs
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        indicatorColor="secondary"
                                        textColor="primary"
                                        variant="fullWidth"
                                    >
                                        <Tab icon={<Booked />} label=" Booked" {...a11yProps(0)} />
                                        <Tab label="  Registrar Booking" icon={<Reg />}{...a11yProps(1)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={this.state.value} index={0} dir={'ltr'}>
                                    <div className="card">
                                        <div className="class-body">
                                            <BookedPatients {...this.props}></BookedPatients>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={this.state.value} index={1} dir={'ltr'}>
                                    <div className="card">
                                        <div className="class-body">
                                            <MyCalendar {...this.props}></MyCalendar>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RegAvailability)
