import React, { Component } from 'react';
import Header from "../../elements/header";
import Footer from "../../elements/footer";
import { Redirect } from 'react-router-dom';
import { Calendar, Views, Navigate } from 'react-big-calendar'
import moment from 'moment'

import { AppontmentService } from '../../service/Appointment';

import Swal from 'sweetalert2';
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import * as dates from 'date-arithmetic'
import CalendarToolbar from '../../components/MyCalendar/ToolBar/index'
import { ColoredDateCellWrapper, localizer } from '../../components/MyCalendar/Utils/util'
import { connect } from 'react-redux'
import actions from '../../store/actions/constants'
import constants from '../../util/constants'
import { userService } from '../../service/UserService';
import PatientInfo from './patientInfo'
import PatientBooked from './patientBooked'
import MobileUpdate from './Modal/mobileUpdate'
import EmailUpdate from './Modal/emailUpdate'
import urls from '../../util/urls'
import fetch from '../../service/Fetch/fetchApi'

import appSetting from '../../AppSettings.json'
//***********************************************************/
class MyWeek extends React.Component {
    render() {
        let { date } = this.props
        let range = MyWeek.range(date)

        return <TimeGrid {...this.props} range={range} eventOffset={20} />
    }
}
MyWeek.range = date => {
    let start = date
    let end = dates.add(start, 3, 'day')

    let current = start
    let range = []

    while (dates.lte(current, end, 'day')) {
        range.push(current)
        current = dates.add(current, 1, 'day')
    }

    return range
}

MyWeek.navigate = (date, action) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return dates.add(date, -4, 'day')
        case Navigate.NEXT:
            return dates.add(date, 4, 'day')
        default:
            return date
    }
}

MyWeek.title = date => {
    // return `My awesome week: ${date.toLocaleDateString()}`
    return moment.parseZone(date).format('DD-MMM-YY') + ' to ' + moment.parseZone(dates.add(date, 3, 'day')).format('DD-MMM-YY');

}
//**************************************************************************************** */
const mapStateToProps = state => ({
    regAvailability: state.regAvailability.data,
    userDetails: state.userDetails.data
})

const mapDispatchToProps = dispatch => ({
    getRegistrarAvailability: (payload) => {
        dispatch({
            type: actions.FETCH_REGAVAILAIBILITY,
            payload
        });
    },
    dispatch: dispatch,

})
class Appointment extends Component {
    constructor(props) {
        super(props);
        this.selectedDate = new Date();
        this.submitForm = this.submitForm.bind(this);
        this.submitEmailForm = this.submitEmailForm.bind(this);
        this.deleteappointment = this.deleteappointment.bind(this);
        this.changeAppointment = this.changeAppointment.bind(this);
        this.state = {
            // isLoading: false,
            events: [],
            selectedDate: new Date(),
            isBooked: null,
            UserId: '',
            patinetinfo: null,

        };
    }

    UNSAFE_componentWillMount() {
        var userDetails = this.props.userDetails;
        this.usertype = userDetails.UserType;
        this.setState({
            UserId: userDetails.UserId,
            usertype: userDetails.UserType
        })
        this.props.dispatch({
            type: actions.IS_FETCHING
        })
        this.setState({ isLoading: false, events: [] })
        this.GetPatinetAppointment(userDetails.UserId, userDetails.ReferralId);
        this.GetPatientUserSlotsList(userDetails.UserId, userDetails.ReferralId);
    }

    closeButtonClick = () => {
        localStorage.clear();
        this.props.history.push('/close')
    }

    reScheduleAppointment = () => {
        this.setState({
            isBooked: false
        })
    }
    changeAppointment = () => {
        let deleteContentHtml =
            `<div class="row">
        <div class="col-5 text-left font-weight-bold">Date</div>
        <div class="col-1">:</div>
        <div class="col-6 text-left">${moment.parseZone(this.state.patinetinfo.startDate).format('DD-MM-YYYY')}</div>
    </div>
    <div class="row">
        <div class="col-5 text-left font-weight-bold">Time</div>
        <div class="col-1">:</div>
        <div class="col-6 text-left">${moment.parseZone(this.state.patinetinfo.startDate).format('HH:mm')} - ${moment.parseZone(this.state.patinetinfo.endDate).format('HH:mm')}</div>
    </div>`
        Swal.fire({
            title: 'Change the time and date of this appointment?',
            html: deleteContentHtml,
            showCloseButton: true,
            showCancelButton: true,
            reverseButtons: true,
            buttonsStyling: false,
            cancelButtonText: 'Keep appointment',
            confirmButtonText: 'Reschedule appointment',
            customClass: {
                confirmButton: 'med-btn-primary mr-2',
                cancelButton: 'med-btn-default mr-2'
            },
        })
    }
    deleteappointment = (reason) => {
        this.DeleteAppointment(this.state.patinetinfo.patientBookingID, this.state.patinetinfo.availBookingID, this.state.patinetinfo.patientId, reason,
            this.state.UserId, this.state.patinetinfo.referralId, this.state.patinetinfo.startDate, this.state.patinetinfo.endDate,
            this.state.patinetinfo.RegistrarName, this.state.patinetinfo.RegistrarEmail)
    }
    deleteappointment2 = () => {
        let deleteContentHtml =
            `<div class="row">
            <div class="col-5 text-left font-weight-bold">Date</div>
            <div class="col-1">:</div>
            <div class="col-6 text-left">${moment.parseZone(this.state.patinetinfo.startDate).format('DD-MM-YYYY')}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Time</div>
            <div class="col-1">:</div>
            <div class="col-6 text-left">${moment.parseZone(this.state.patinetinfo.startDate).format('HH:mm')} - ${moment.parseZone(this.state.patinetinfo.endDate).format('HH:mm')}</div>
        </div>`
        Swal.fire({
            title: constants.PATINET_CANCEL_POPUP,
            html: deleteContentHtml,
            showCloseButton: true,
            showCancelButton: true,
            reverseButtons: true,
            buttonsStyling: false,
            confirmButtonText: 'Cancel appointment',
            cancelButtonText: 'Keep appointment',
            customClass: {
                confirmButton: 'med-btn-primary mr-2',
                cancelButton: 'med-btn-default mr-2'
            },
        })
            .then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: constants.CANCELLATION_COMMENT_TITLE,
                        text: constants.CANCELLATION_COMMENT_TEXT,
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCloseButton: true,
                        customClass: {
                            confirmButton: 'med-btn-primary mr-2',
                            cancelButton: 'med-btn-default mr-2 '
                        },
                        reverseButtons: true,
                        buttonsStyling: false,
                        cancelButtonText: 'Close',
                        showCancelButton: true,
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                        preConfirm: (result) => {
                            if (result !== '' && result.length >= 10) {
                                this.DeleteAppointment(this.state.patinetinfo.patientBookingID, this.state.patinetinfo.availBookingID, this.state.patinetinfo.patientId, result,
                                    this.state.UserId, this.state.patinetinfo.referralId, this.state.patinetinfo.startDate, this.state.patinetinfo.endDate,
                                    this.state.patinetinfo.RegistrarName, this.state.patinetinfo.RegistrarEmail)
                                // this.setState({ isBooked: false });
                            }
                            else {
                                var message = constants.COMMENTS_REQUIRED
                                if (result !== '' && result.length <= 9) {
                                    message = constants.COMMENTS_MINIMUM;
                                }
                                Swal.showValidationMessage(
                                    message
                                )
                            }
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    })
                }
            });
    };

    handlesaveappointment = event => {
        if (new Date() <= event.start && event.title !== "PatientBooked") {
            let data = { "StartDate": event.start.toLocaleString('en'), "EndDate": event.end.toLocaleString('en'),"ReferralId": event.ReferralId }
            fetch(`${urls.GetUserAvailableSlots}`,
                data,
                actions.AUTH_USER)
                .then(response => {
                    if (response) {
                        var registrarName = response[0] && response[0] ? response[0].Name : ''
                        var emailAddress = response[0] && response[0] ? response[0].EmailAddress : '';
                        var AvailBookingID= response[0] && response[0] ? response[0].AvailBookingID : ''; 
                        this.setState({ registrarName: registrarName, EmailAddress: emailAddress, AvailBookingID:AvailBookingID });
                        let patinetCreatedContent =
                            `<div class="row">
            <div class="col-5 text-left font-weight-bold">Referral ID</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left">${event.ReferralId}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Patient Name</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left ">${event.PatientName}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">NHS Number</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left ">${event.NhsNumber}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Mobile Telephone</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left">${event.MobileTelephone}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Date</div>
            <div class="col-1">:</div>
            <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Time</div>
            <div class="col-1">:</div>
            <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
        </div>
            `;
                        var prevBooking = this.state.patinetinfo && this.state.patinetinfo.referralId;
                        var title = prevBooking ? constants.RESCHEDULE_CONFIRM_HEADING : constants.CONFIRM_HEADING;
                        var btnHeading = prevBooking ? "Reschedule" : "Book"
                        Swal.fire({
                            title: title,
                            html: patinetCreatedContent,
                            showCloseButton: true,
                            showCancelButton: true,
                            reverseButtons: true,
                            buttonsStyling: false,
                            confirmButtonText: btnHeading,
                            customClass: {
                                confirmButton: 'med-btn-primary mr-2',
                                cancelButton: 'med-btn-default mr-2'
                            },
                            // onBeforeOpen: function () {
                            //     Swal.getContainer().style.position = 'absolute';
                            //     Swal.getContainer().style.scrollBehavior = 'smooth';
                            // },
                        }).then((ok) => {
                            if (ok.value) {
                                var registrarName = this.state.registrarName;
                                var registrarEmailAddress = this.state.EmailAddress;
                                var AvailBookingID= this.state.AvailBookingID;
                                let oldBookingId = (this.state.patinetinfo && this.state.patinetinfo.patientBookingID) ?? null
                                this.InsetPatientAppointment(event.start, event.end, event.id, this.state.UserId, oldBookingId,
                                    registrarName, registrarEmailAddress, AvailBookingID)
                            }
                        });
                    }
                });
        }
        if (new Date() <= event.start && event.title === "PatientBooked") {
            this.setState({
                isBooked: true
            })
        }
        else {

        }
    };
    eventPropGetter = (event, start, end, isSelected) => {
        let newStyle = 'createdstatus';

        if (event.title === "Available") {
            newStyle = 'inprogressstatus';
        }

        return {
            className: newStyle,
            style: ""
        };
    };
    submitForm = (value) => {
        this.setState({ isBooked: true });
        this.UpdatePatientMobileNumber(this.state.UserId, this.state.patinetinfo.referralId, value);
        this.hideModal();
    }
    submitEmailForm = (value) => {

        this.setState({ isBooked: true });
        this.UpdatePatientEmailId(this.state.UserId, this.state.patinetinfo.referralId, value);
        this.hideEmailModal();
    }
    updatemobilenumber = (event) => {
        this.setState({ showModal: true });
    };
    updateemailid = (event) => {
        this.setState({ showEmailModal: true });
    };

    UpdatePatientMobileNumber(UserId, referralId, MobileNumber) {
        userService.UpdatePatientMobileNumber(UserId, referralId, MobileNumber)
            .then(
                result => {
                    if (result.success) {
                        this.setState({ events: [], patinetinfo: {} });
                        this.GetPatinetAppointment(this.state.UserId, referralId);
                        if (!this.state.isBooked) {
                            this.GetPatientUserSlotsList(this.state.UserId, referralId);
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            );
    };

    UpdatePatientEmailId(UserId, referralId, emailId) {

        userService.UpdatePatientEmailId(UserId, referralId, emailId)
            .then(
                result => {
                    if (result.success) {
                        this.setState({ events: [], patinetinfo: {} });
                        this.GetPatinetAppointment(this.state.UserId, referralId);
                        if (!this.state.isBooked) {
                            this.GetPatientUserSlotsList(this.state.UserId, referralId);
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            );
    };

    GetPatinetAppointment(UserId, ReferralId) {
        AppontmentService.GetPatientAppointment(UserId, ReferralId)
            .then(
                result => {
                    var eventslist = this.state.events;
                    if (result.length > 0 && result[0].StatusCode === "2") {
                        var item = {
                            patientBookingID: result[0].PatientBookingID,
                            availBookingID: result[0].AvailBookingID,
                            referralId: result[0].ReferralId,
                            patientId: result[0].PatientId,
                            nhsNumber: result[0].NhsNumber,
                            patientName: result[0].PatientName,
                            mobileTelephone: result[0].MobileTelephone,
                            emailId: result[0].EmailId,
                            startDate: result[0].StartDate,
                            endDate: result[0].EndDate,
                            statusCode: result[0].StatusCode,
                            statusDesc: result[0].StatusDesc,
                            ccgName: result[0].CCGName,
                            ccgid: result[0].CCGId,
                            speciality: result[0].Speciality,
                            spcialistname: result[0].SpcialistName,
                            splhospitalname: result[0].SplHospitalName,
                            gpName: result[0].GPName,
                            gphospitalname: result[0].GPHospitalName,
                            id: result[0].ReferralId,
                            title: result[0].StatusDesc,
                            start: new Date(result[0].StartDate),
                            end: new Date(result[0].EndDate),
                            desc: result[0].PatientName,
                            ReferralId: result[0].ReferralId,
                            PatientName: result[0].PatientName ?? '',
                            NhsNumber: result[0].NhsNumber ?? '',
                            MobileTelephone: result[0].MobileTelephone ?? '',
                            RegistrarName: result[0].RegistrarName ?? '',
                            RegistrarEmail: result[0].RegistrarEmail ?? '',
                        }
                        eventslist.push(item)
                        this.setState({ events: eventslist })
                        this.setState({ patinetinfo: item });
                        this.setState({ isBooked: true });
                    }
                    else {
                        this.setState({ isBooked: false });
                    }
                    this.props.dispatch({
                        type: actions.FETCHING_DONE
                    })

                },
                error => {
                    this.setState({ isLoading: false })
                    this.props.dispatch({
                        type: actions.FETCHING_DONE
                    })
                    console.log(error);
                }
            );
    };
    GetPatientUserSlotsList(UserId, ReferralId) {
        this.props.dispatch({
            type: actions.IS_FETCHING
        })
        AppontmentService.GetPatientUserSlots(UserId, ReferralId)
            .then(
                result => {
                    if (result) {
                        var eventslist = this.state.events;
                        result.forEach((el, index) => {
                            eventslist.push({
                                id: el.ReferralId,
                                title: el.StatusDesc,
                                start: new Date(el.StartDate),
                                end: new Date(el.EndDate),
                                desc: el.PatientName,
                                ReferralId: el.ReferralId,
                                PatientName: el.PatientName ?? '',
                                NhsNumber: el.NhsNumber ?? '',
                                MobileTelephone: el.MobileTelephone ?? ''
                            });
                        })
                        this.setState({ events: eventslist });
                        this.setState({ isLoading: true })
                    }
                    this.props.dispatch({
                        type: actions.FETCHING_DONE
                    })
                },
                error => {
                    this.setState({ isLoading: false })
                    this.props.dispatch({
                        type: actions.FETCHING_DONE
                    })
                }
            );
    };

    InsetPatientAppointment(StartDate, EndDate, ReferralId, CreatedBy, oldBookingId, registrarName, registrarEmailAddress, AvailabilityId) {
        this.props.dispatch({
            type: actions.IS_FETCHING
        })
        AppontmentService.InsertPatientAppointment(StartDate, EndDate, ReferralId, CreatedBy, oldBookingId, false, registrarName, registrarEmailAddress,AvailabilityId)
            .then(
                result => {
                    if (result) {
                        this.setState({ events: [], patinetinfo: {} });
                        this.GetPatinetAppointment(this.state.UserId, ReferralId);
                        if (!this.state.isBooked) {
                            this.GetPatientUserSlotsList(this.state.UserId, ReferralId);
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            );
    };
    DeleteAppointment(PatientBookingID, AvailBookingID, PatientId, Remarks, ModifiedBy, referralId, startDate, endDate, RegistrarName, RegistrarEmail) {
        this.props.dispatch({
            type: actions.IS_FETCHING
        })
        AppontmentService.UpdatePatientAppointment(PatientBookingID, AvailBookingID, PatientId, Remarks, ModifiedBy, referralId, false, startDate, endDate, RegistrarName, RegistrarEmail)
            .then(
                result => {
                    if (result) {
                        this.props.dispatch({
                            type: actions.FETCHING_DONE
                        })
                        localStorage.clear();
                        this.props.history.push('/cancel');
                    }
                },
                error => {
                    this.props.dispatch({
                        type: actions.FETCHING_DONE
                    })
                    this.props.history.push('/cancel');
                    console.log(error);
                }
            );
    };

    hideModal = () => {
        this.setState({ showModal: false });
    };

    hideEmailModal = () => {
        this.setState({ showEmailModal: false });
    };
    render() {
        if (this.usertype === null) {
            return <Redirect to='/Login' />
        }
        else if (this.usertype === "SR") {
            return <Redirect to='/RegAvalability' />
        }
        else if (this.usertype === "MedeferAdmin") {
            return <Redirect to='/Admin' />
        }
        else {
              let formats={dateFormat:'dd',
        timeGutterFormat:'HH:mm'
            }
            return (
                <React.Fragment>
                    <Header />
                    <div id="wrapper" className="patientContainer">
                        <div id="content-wrapper">
                            <div className="container-fluid customcontainer">
                                {this.state.isLoading && this.props.userDetails && this.state.isBooked != null &&
                                    <React.Fragment>

                                        <React.Fragment>
                                            {this.state.showModal &&
                                                <MobileUpdate submitForm={this.submitForm} handleClose={this.hideModal} {...this.props}></MobileUpdate>
                                            }
                                            {this.state.showEmailModal &&
                                                <EmailUpdate submitEmailForm={this.submitEmailForm} handleClose={this.hideEmailModal} {...this.props}></EmailUpdate>
                                            }
                                            {(this.state.patinetinfo == null || this.state.isBooked == false) &&
                                                <React.Fragment>
                                                    <div className="row pb-2">
                                                        <div className="col-12"><h1>Book your appointment</h1></div>
                                                    </div>
                                                    <div className="card mb-3 ">
                                                        <div className="card-body patient">
                                                            {this.props.userDetails &&
                                                                <PatientInfo data={this.state.events} {...this.props}></PatientInfo>
                                                            }
                                                            {this.state.events.length > 0 ?
                                                                <Calendar
                                                                    events={this.state.events}
                                                                    views={{ week: MyWeek, day: true, month: true }}
                                                                    step={20}
                                                                    titleAccessor="patient"
                                                                    localizer={localizer}
                                                                    timeslots={1}
                                                                    defaultView={Views.WEEK}
                                                                    startAccessor="start"
                                                                    endAccessor="end"
                                                                    showMultiDayTimes={true}
                                                                    defaultDate={this.state.selectedDate}
                                                                    onSelectEvent={event => this.handlesaveappointment(event)}
                                                                    dayLayoutAlgorithm={"no-overlap"}
                                                                    min={new Date(2000, 0, 1, appSetting.startTime, 0)}
                                                                    max={new Date(2100, 0, 1, appSetting.endTime, 59)}
                                                                    formats={formats}
                                                                    components={{
                                                                        timeSlotWrapper: ColoredDateCellWrapper,
                                                                        toolbar: CalendarToolbar
                                                                    }}
                                                                    toolbar={true}
                                                                    eventPropGetter={(this.eventPropGetter)}
                                                                /> :
                                                                <div className="row mt-5">
                                                                    <div className="col-sm-12 m-auto">
                                                                        {constants.PATIENT_NO_SLOTS}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {this.state.isBooked && this.state.events.length > 0 && this.state.patinetinfo != null &&
                                                <PatientBooked closeButtonClick={this.closeButtonClick} changeAppointment={this.changeAppointment} deleteappointment={this.deleteappointment} reScheduleAppointment={this.reScheduleAppointment} updatemobilenumber={this.updatemobilenumber} patInfo={this.state.patinetinfo} updateemailid={this.updateemailid}></PatientBooked>
                                            }
                                        </React.Fragment>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    <Footer />
                </React.Fragment >
            );
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Appointment)