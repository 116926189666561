import actions from '../../store/actions/constants'

export default function adminReducer(state = {}, action) {
  switch (action.type) {
    case actions.GET_CANCELLED_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        canceledAppointment: action.payload,
      }
    case actions.RESET_CANCELLED_APPOINTMENTS:
      return {
        ...state,
        canceledAppointment: null
      }
    case actions.GET_AVAILABLESLOT_USERS_SUCCESS:
      return {
        ...state,
        availableUserSlot: action.payload,
      }
    case actions.UPDATE_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS:
      return {
        ...state,
        isAutoMapRegistrar: action.payload,
      }
    case actions.RESET_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS:
      return {
        ...state,
        isAutoMapRegistrar: null,
      }
    case actions.GET_ALL_PENDING_PATINET_BOOKINGS_SUCCESS:
      return {
        ...state,
        pendingPatientBookings: action.payload,
      }
    case actions.PATIENT_VIEW:
      return {
        ...state,
        patientView: action.payload,
      }
    case actions.GET_ALL_BOOKED_PATIENTS_SUCCESS:
      return {
        ...state,
        bookedPatients: action.payload
      }
    case actions.GET_ALL_AWAITING_BOOKED_PATIENTS_SUCCESS:
      return {
        ...state,
        awaitingPatients: action.payload
      }
    case actions.RESET_ALL_AWAITING_BOOKED_PATIENTS:
      return {
        ...state,
        awaitingPatients: null
      }
    case actions.UPDATE_CANCELED_APPOINTMENT_SUCCESS:
      return {
        ...state,
        updateCanceled: action.payload
      }
    case actions.RESET_UPDATE_CANCELED_APPOINTMENT:
      return {
        ...state,
        updateCanceled: null
      }
    case actions.RESET_ALL_BOOKED_PATIENTS:
      return {
        ...state,
        bookedPatients: null
      }
    case actions.RESET_ALL_PENDING_PATINET_BOOKINGS:
      return {
        ...state,
        pendingPatientBookings: null
      }
    case actions.GET_ALL_CLERKED_PATIENTS_SUCCESS:
      return {
        ...state,
        clerkedpatients: action.payload
      }
    case actions.RESET_ALL_CLERKED_PATIENTS:
      return {
        ...state,
        clerkedpatients: null
      }
    case actions.GET_ALL_REGISTRAT_DNA_SUCCESS:
      return {
        ...state,
        registrarDNA: action.payload
      }
    case actions.RESET_ALL_REGISTRAT_DNA:
      return {
        ...state,
        registrarDNA: null
      }

    case actions.GET_ALL_PATIENT_DNA_SUCCESS:
      return {
        ...state,
        patientDNA: action.payload
      }
    case actions.RESET_ALL_PATIENT_DNA:
      return {
        ...state,
        patientDNA: null
      }
    case actions.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userLists: action.payload
      }
    default:
      return state;
  }
}