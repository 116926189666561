import React from 'react'

import LiveChatIcon from "../../assets/Images/svg/LiveChat_Icon.svg";
import EmailIcon from "../../assets/Images/svg/Email_icon.svg";
import PhoneIcon from "../../assets/Images/svg/Phone_icon.svg";

export default function Help() {
    return (
        <div>
            <p>If you need further help;</p>
            {/* <p><img className="helpSectionSvg" src={LiveChatIcon}></img> Message us using live chat</p> */}
            <p><img className="helpSectionSvg" src={EmailIcon}></img> Email us on <a href="mailto:admin.medefer@nhs.net">admin.medefer@nhs.net</a></p>
            <p><img className="helpSectionSvg" src={PhoneIcon}></img> Call <a href="tel:08000 112 113">08000 112 113</a> from Mon-Fri 8am-6pm </p>
        </div>
    )
}