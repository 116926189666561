import { combineEpics, ofType } from 'redux-observable'
import actions from '../../store/actions/constants'
import urls from '../../util/urls'
import { mergeMap } from 'rxjs/operators';
import fetch from '../../service/Fetch/fetchApi';
import moment from 'moment'
//import constructResponse from './index'
const fetchRegAvailabilityFulfilled = payload => ({ type: actions.FETCH_REGAVAILAIBILITY_SUCCESS, payload });
const getRegistrarBookedPatientsEpicFulfilled = payload => ({ type: actions.GET_ALL_REGISTRAR_PATIENT_BOOKING_SUCCESS, payload });

const fetchRegAvailabilityEpic = (action$, store) =>
  action$.pipe(ofType(actions.FETCH_REGAVAILAIBILITY)
    , mergeMap(action =>
      fetch(`${urls.GetUserAvailability}`, action.payload, action.type)
        .then(response => fetchRegAvailabilityFulfilled(constructResponse(response)))
        .catch(() => console.log('errors'))
    ));

const updateRegAvailabilityEpic = (action$, store) =>
  action$.pipe(ofType(actions.UPDATE_REGAVAILABILITY)
    , mergeMap(action =>
      fetch(`${urls.UpdateUserAvailability}`, action.payload, action.type)
        .then(response => fetchRegAvailabilityFulfilled(constructResponse(response)))
        .catch(() => console.log('errors'))
    ));


const getRegistrarBookedPatientsEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_ALL_REGISTRAR_PATIENT_BOOKING)
    , mergeMap(action =>
      fetch(`${urls.GetRegistrarBookedPatients}`, action.payload, action.type)
        .then(response => getRegistrarBookedPatientsEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));


const constructResponse = (response) => {
  let eventslist = [];
  response.forEach(el => {
    let start = moment(el.StartDate).format()
    let end = moment(el.EndDate).format();
    var resp={};
    if(el.IsDifferentPlatform){
     resp = {
      "id": el.AvailBookingID,
      title: el.StatusDesc + (el.BookedAppType==1?' (Secure)':' (IOW)'),
      "start": new Date(start),
      "end": new Date(end),
      StatusCode: el.StatusCode,
      desc: 'Slot booked in other platform',
      // "desc": 'Referral ID : ' + el.ReferralId + ', Patient Name : ' + el.PatientName,
      // "ReferralId": el.ReferralId,
      // "PatientName": el.PatientName ?? '',
      // "PatientBookingID": el.PatientBookingID ?? null,
      // "NhsNumber": el.NhsNumber ?? '',
      IsDifferentPlatform: el.IsDifferentPlatform
    }
  }
  else {
     resp = {
      "id": el.AvailBookingID,
      "title": el.StatusDesc,
      "start": new Date(start),
      StatusCode: el.StatusCode,
      "end": new Date(end),
      "desc": 'Referral ID : ' + el.ReferralId + ', Patient Name : ' + el.PatientName,
      "ReferralId": el.ReferralId,
      "PatientName": el.PatientName ?? '',
      "PatientBookingID": el.PatientBookingID ?? null,
      "NhsNumber": el.NhsNumber ?? '',
      IsDifferentPlatform: el.IsDifferentPlatform,
      RegistrarFullName: el.RegistrarFullName
    }
  }
    eventslist.push(resp);
  });
  return eventslist;
}

export default combineEpics(fetchRegAvailabilityEpic, updateRegAvailabilityEpic, getRegistrarBookedPatientsEpic)