import React from 'react'
import Header from '../../elements/header'
import Footer from '../../elements/footer'
import constants from '../../util/constants'
const Close = () => {

    return (
        <React.Fragment>
            <Header />
            <div className="row">
                <div className="myClass col-md-8 m-auto">
                    <div className="card border-dark p-3 text-center card-header text-primary font-weight-bold">
                    {constants.PATIENT_CLOSE_MESSAGE}
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}


export default Close