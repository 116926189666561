import React from "react";
import Presentation from './pendingPatientPresentation'

class PendingPatients extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

  }
  componentDidMount() {
    this.props.isFetching();
    this.props.getAllPendingPatinetBookings();
    //this.props.fetchingDone();
  }
  UNSAFE_componentWillMount() {

  }

  componentWillUnmount() {
    this.props.resetPendingPatinetBookings();
  }

  render() {

    return (
      <React.Fragment>
        {this.props.adminDetails && this.props.adminDetails.pendingPatientBookings &&
          <div className="card">
            <div className="card-body">
              <Presentation {...this.props} rowData={this.props.adminDetails.pendingPatientBookings}></Presentation>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default PendingPatients