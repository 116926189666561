import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Registrar from './Registrar'
// import Dashboard from '@material-ui/icons/Dashboard';
import Cancel from '@material-ui/icons/Cancel';
import Reg from '@material-ui/icons/LocalHospital';
// import Person from '@material-ui/icons/Person';
import Clerked from '@material-ui/icons/DoneAll';
import Booked from '@material-ui/icons/CalendarToday';
import Schedule from '@material-ui/icons/Schedule';
import DNA from '@material-ui/icons/NotInterestedRounded'
import CancelRequest from './CancelRequest/'
// import PatientAppointment from './Patient/'
import PendingPatients from './Patient/pendingPatients'
import BookedPatients from './Patient/bookedpatients'
import ClerkedPatient from './Patient/clerkedPatients'
import PatientDNA from './Patient/patientDNA'
import actions from '../../store/actions/constants'
import TabPanel from './utils/tabPanel'

function a11yProps(index) {
    // return {
    //     id: `full-width-tab-${index}`,
    //     'aria-controls': `full-width-tabpanel-${index}`,
    // };
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
      };
}


const mapStateToProps = state => ({
    userDetails: state.userDetails.data,
    adminDetails: state.admin
})

const mapDispatchToProps = dispatch => ({
    getCancelledAppointments: () => {
        dispatch({
            type: actions.GET_CANCELLED_APPOINTMENTS,
        });
    },
    resetCancelledAppointments: () => {
        dispatch({
            type: actions.RESET_CANCELLED_APPOINTMENTS,
        });
    },
    getAvilableUserSlots: (payload) => {
        dispatch({
            type: actions.IS_FETCHING
        });
        dispatch({
            type: actions.GET_AVAILABLESLOT_USERS,
            payload
        });
    },
    fetchingDone: () => {
        dispatch({
            type: actions.FETCHING_DONE
        });
    },
    isFetching: () => {
        dispatch({
            type: actions.IS_FETCHING
        });
    },
    updateAdminAutoMap: (payload) => {
        dispatch({
            type: actions.UPDATE_ADMIN_AUTO_MAP_REGISTRAR,
            payload
        })
        dispatch({
            type: actions.RESET_CANCELLED_APPOINTMENTS,
        });
    },
    resetUpdateAdminAutoMap: () => {
        dispatch({
            type: actions.RESET_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS
        })
    },
    getAllPendingPatinetBookings: () => {
        dispatch({
            type: actions.GET_ALL_PENDING_PATINET_BOOKINGS,
        });
    },
    resetPendingPatinetBookings: () => {
        dispatch({
            type: actions.RESET_ALL_PENDING_PATINET_BOOKINGS,
        });
    },
    setPatientViewData: (payload) => {
        dispatch({
            type: actions.PATIENT_VIEW,
            payload
        })
    },
    getAllBookedPatinets: (payload) => {
        dispatch({
            type: actions.GET_ALL_BOOKED_PATIENTS,
            payload
        })
    },
    resetAllBookedPatinets: (payload) => {
        dispatch({
            type: actions.RESET_ALL_BOOKED_PATIENTS,
            payload
        })
    },
    getAllAwaitingPatinets: (payload) => {
        dispatch({
            type: actions.GET_ALL_AWAITING_BOOKED_PATIENTS,
            payload
        })
    },
    resetAllAwaitingPatinets: (payload) => {
        dispatch({
            type: actions.RESET_ALL_AWAITING_BOOKED_PATIENTS,
            payload
        })
    },
    getAllClerkedPatinets: () => {
        dispatch({
            type: actions.RESET_ALL_CLERKED_PATIENTS,
        })
        dispatch({
            type: actions.GET_ALL_CLERKED_PATIENTS,
        })
    },
    resetClerkedPatinets: () => {
        dispatch({
            type: actions.RESET_ALL_CLERKED_PATIENTS,
        })
    },
    getAllRegistrarDNA: () => {
        dispatch({
            type: actions.RESET_ALL_REGISTRAT_DNA,
        })
        dispatch({
            type: actions.GET_ALL_REGISTRAT_DNA,
        })
    },
    resetRegistrarDNA: () => {
        dispatch({
            type: actions.RESET_ALL_REGISTRAT_DNA,
        })
    },
    getAllPatinetDNA: () => {
        dispatch({
            type: actions.RESET_ALL_PATIENT_DNA,
        })
        dispatch({
            type: actions.GET_ALL_PATIENT_DNA,
        })
    },
    resetPatinetDNA: () => {
        dispatch({
            type: actions.RESET_ALL_PATIENT_DNA,
        })
    },
    getUserList: () => {
        dispatch({
            type: actions.GET_USER_LIST,
        })
    },
    updateAppointmentList: (payload) => {
        dispatch({
            type: actions.UPDATE_CANCELED_APPOINTMENT,
            payload
        })
        dispatch({
            type: actions.RESET_CANCELLED_APPOINTMENTS
        })
    },
    resetUpdateAppointmentList: (payload) => {
        dispatch({
            type: actions.RESET_UPDATE_CANCELED_APPOINTMENT,
            payload
        })
        
    },
    dispatch: dispatch,

})

class Admin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }


    classes = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
          },
        // root: {
        //     backgroundColor: "red",
        //     width: 500,
        // },
    }));

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };
    

    render() {
        if (!this.props.userDetails) {
            return <Redirect to='/PatientLogin' />
        }
        if (this.props.userDetails.UserType === null) {
            return <Redirect to='/Login' />
        }
        else if (this.props.userDetails.UserType === "SR") {
            return <Redirect to='/RegAvalability' />
        }
        else if
            (this.props.userDetails.UserType === "PAT") {

            var isvalid = localStorage.getItem('isvalidpatinet');
            if (isvalid === "true") {
                return <Redirect to='/Appointment' />
            }
            else {
                return <Redirect to='/PatientLogin' />
            }
        }
        else {
            return (

                <div className={this.classes.root}>
                    <div id="wrapper">
                        <div id="content-wrapper">
                            <div className="container-fluid">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active">Appointment Administration</li>
                                </ol>
                                <TabContext value={this.state.value}>
                                    <AppBar position="static" color="default">
                                        <Tabs
                                            value={this.state.value}
                                            onChange={this.handleChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="on"
                                        >
                                            <Tab icon={<Schedule />} label=" Pending" {...a11yProps(0)} />
                                            <Tab icon={<Booked />} label=" Booked" {...a11yProps(1)} />
                                            <Tab icon={<Clerked />} label=" Clerked" {...a11yProps(2)} />
                                            <Tab icon={<DNA />} label=" DNA"  {...a11yProps(3)} />
                                            <Tab label="  Cancellations" icon={<Cancel />} {...a11yProps(4)} />
                                            <Tab label="  Registrar" icon={<Reg />}{...a11yProps(5)} />
                                        </Tabs>
                                    </AppBar>

                                    <TabPanel value={this.state.value} index={0} dir={'ltr'}>
                                        <PendingPatients {...this.props}></PendingPatients>
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={1} dir={'ltr'}>
                                        <BookedPatients {...this.props}></BookedPatients>
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={2} dir={'ltr'}>
                                        <ClerkedPatient {...this.props}></ClerkedPatient>
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={3} dir={'ltr'}>
                                        <PatientDNA {...this.props}></PatientDNA>
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={4} dir={'ltr'}>
                                        <CancelRequest {...this.props} />
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={5} dir={'ltr'}>
                                        <Registrar />
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
