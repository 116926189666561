import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-datepicker/dist/react-datepicker.css'
// import './assets/css/sb-admin.css';
import React from 'react';
import ReactDOM from 'react-dom';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
import './assets/css/react-big-calendar.css'
import 'react-dropdown/style.css';
import './index.css';
import './assets/css/Custom.css'
import App from './App';
import { Provider } from 'react-redux';
import store from './store/config/configStore';

const MyApp = () => (
    <Provider store={store}>
        <App />
    </Provider>
)
ReactDOM.render(<MyApp />, document.getElementById('root'));