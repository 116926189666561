import React from 'react'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        if (typeof window !== "undefined") {
            localStorage.clear();
        }
        //console.log(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="row">
                    <div className="myClass col-md-8 m-auto">
                        <div className="card border-danger p-3 text-center card-header text-danger font-weight-bold">
                            Some Thing has gone wrong
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary
