import actions from '../../store/actions/constants'

export default function appointmentReducer(state = {}, action) {
  switch (action.type) {
    case actions.GET_PATIENT_USER_SLOTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state;
  }
}
