import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'



class CalendarToolbar extends Toolbar {

    state = {
        date: new Date(),
    }
    onChange = date => this.setState({ date })

    UNSAFE_componentWillMount() {
        const view = this.props.view;
    }

    render() {
        return (
            <React.Fragment>
                <div className="calendar-bg">
                    <div className="rbc-toolbar toolbar m-2">
                        <div className="rbc-btn-group">
                            <button type="button" onClick={() => this.navigate('TODAY')}>
                                Today
                    </button>
                        </div>
                        <div className="rbc-toolbar-label">
                            {new Date() <= this.props.date &&
                                <span type="button" onClick={() => this.navigate('PREV')}>
                                    <FontAwesomeIcon icon={faChevronCircleLeft} />
                                </span>
                            }
                            {"  "}
                            {this.props.label}
                            {" "}
                            <span type="button" onClick={() => this.navigate('NEXT')}>
                                <FontAwesomeIcon icon={faChevronCircleRight} />
                            </span>
                        </div>
                        <div className="rbc-btn-group">
                            <button type="button" onClick={this.view.bind(null, 'day')}>Day</button>
                            <button type="button" onClick={this.view.bind(null, 'week')}>Week</button>
                            <button type="button" onClick={this.view.bind(null, 'month')}>Month</button>
                            {this.props.views.includes('agenda') &&
                                <button type="button" onClick={this.view.bind(null, 'agenda')}>Agenda</button>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CalendarToolbar