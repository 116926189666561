import React from "react";
import ReactDataGrid from "react-data-grid";
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import utility from '../../../util/utility'
import Pagination from '@material-ui/core/TablePagination';
import Swal from 'sweetalert2'
import modal from './modal';
import constants from '../../../util/constants'
const selectors = Data.Selectors;

const {
  SingleSelectFilter
} = Filters;

const initialColumns = [
  {
    key: "ReferralId",
    name: "Referral Id",
    filterable: true,
  },
  {
    key: "PatientName",
    name: "Name",
    filterable: true,
  },
  {
    key: "NhsNumber",
    name: "NHS Number",
    filterable: true,
  },
  {
    key: "CCGName",
    name: "CCG Name",
  },
  {
    key: "StartDate",
    name: "Start Date",
    filterRenderer: SingleSelectFilter,
    filterable: true,
    formatter: utility.DateTimeFormatter1
  },
  {
    key: "EndDate",
    name: "End Date",
    filterable: true,
    filterRenderer: SingleSelectFilter,
    formatter: utility.DateTimeFormatter1
  },
  {
    key: "UserType",
    name: "User Type",
    filterable: true,
    filterRenderer: SingleSelectFilter
  },
  {
    key: "modify",
    name: "View/Modify"
  }


].map(c => ({ ...c, ...utility.defaultColumnProperties }));

class MyDataGrid extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      columns: initialColumns,
      rows: utility.getpaginateData(this.props.rowData, constants.ROWSPERPAGE, 0),
      filters: {},
      selectedRows: [], //for row selection (column with checkboxes)
      pageNumber: 0,
      RowsPerPage: constants.ROWSPERPAGE,
      userlist: [],
      showModal: false,
      modalData: {}
    };
    // this.onPageChange=this.onPageChange.bind(this);
    this.getCellActions = this.getCellActions.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }
  componentDidMount() {
    utility.sendHeight();
    //this.props.fetchingDone();
  }
  UNSAFE_componentWillMount() {

  }
  
  onGridSort = (sortColumn, sortDirection) => {
    const tmpCol = this.state.columns.find(col => col.key === sortColumn);
    let numberSorting = false;
    if (tmpCol.type === "integer" || tmpCol.type === "real")
      numberSorting = true; //default sorting is comparing strings, here we need number-sorting

    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        if (numberSorting) {
          return a[sortColumn] - b[sortColumn];
        }
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        if (numberSorting) {
          return b[sortColumn] - a[sortColumn];
        }
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      } else {
        return a.id > b.id ? 1 : -1; //restore to sorting by id
      }
    };

    this.setState(prevState => ({
      rows: [...prevState.rows].sort(comparer)
    }));
  };

  getColumns() {
    return this.state.columns;
  }

  getRows(rows, filters) {
    return selectors.getRows({ rows, filters });
  }

  filteredRows = () => {
    if (Object.keys(this.state.filters).length === 0) {
      return this.getRows(this.state.rows, this.state.filters);
  }
  else {
      return this.getRows(this.props.rowData, this.state.filters);
  }
  };

  handleFilterChange = filter => {
    this.setState(prevState => {
      const newFilters = { ...prevState.filters };
      if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
      } else {
        delete newFilters[filter.column.key];
      }
      return {
        filters: newFilters,
        selectedRows: [] //clear selected (checkboxes column) rows when filtering
      };
    });
  };

  onClearFilters = () => {
    this.setState({
      filters: {},
      selectedRows: [] //if you have column with checkboxes then clear them all
    });
  };

  // Needed to have selected row even when sorting (select)
  onRowsSelected = rows => {
    this.setState(prevState => ({
      selectedRows: prevState.selectedRows.concat(rows.map(r => r.row.id)) //assuming id is the key of each row
    }));
  };
  // Needed to have selected row even when sorting (deselect)
  onRowsDeselected = rows => {
    let rowIndexes = rows.map(r => r.row.id); //assuming id is the key of each row

    this.setState(prevState => ({
      selectedRows: prevState.selectedRows.filter(
        i => rowIndexes.indexOf(i) === -1
      )
    }));
  };

  //Also onGridRowsUpdated should update only filtered rows:
  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    this.setState(prevState => {
      const rows = prevState.rows;
      const filtered = this.filteredRows();
      for (let i = fromRow; i <= toRow; i++) {
        const rows_index = rows.map(x => x.id).indexOf(filtered[i].id); //assuming "id" is the key of each row
        rows[rows_index] = { ...filtered[i], ...updated };
      }

      return {
        rows: rows
      };
    });
  };
  getValidFilterValues(rows, columnId) {
    return rows
      .map(r => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      });
  }


  hideModal = () => {
    this.setState({ showModal: false });
  };

  getCellActions(column, row) {
    if (column.key === 'modify' && row.UserType === "Registrar") {
      return [
        {
          icon: <FontAwesomeIcon color='#3766bd' className="modifyIcon" icon={faEdit} />,
          callback: () => {
            // this.props.getAvilableUserSlots({"StartDate":row.StartDate,"EndDate":row.EndDate})
            //this.setState({ showModal: true, modalData: row });
            Swal.fire({
              title: 'Do you wish to approve or reject this cancellation request?',
              html: modal.bindRegistrar(row),
              showCloseButton: true,
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-primary mr-2',
                cancelButton: 'btn btn-danger mr-2 '
              },
              buttonsStyling: false,
              onBeforeOpen: function () {
                Swal.getContainer().className = constants.CONTAINER_CLASSNAME
              },
              // reverseButtons: true,
              confirmButtonText: 'Accept',
              cancelButtonText: 'Reject',
            })
            .then((result) => {
              if (result.value || result.dismiss==='cancel') {
                let payload={
                  IsAccepted:result.dismiss==='cancel'?false:true,
                  PatientBookingID:row.PatientBookingID,
                  AvailBookingID: row.AvailBookingID,
                  ModifiedBy: this.props.userDetails.UserId,
                  RegistrarName : row.RegistrarFullName,
                  StartDate: row.StartDate,
                  ReferralId: row.ReferralId,
                  RegistrarEmail: row.RegistrarEmail
                };
                this.props.updateAppointmentList(payload);
              }
            });
          }
        }
      ];

    }
    else if (column.key === 'modify' && row.UserType === "Patient") {
      return [
        {
          icon: <FontAwesomeIcon color='#468671' className="modifyIcon" icon={faEye} />,
          callback: () => {
            Swal.fire({
              title: 'Patient Booking',
              html: modal.bindRegistrar(row),
              showCloseButton: true,
              customClass: {
                confirmButton: 'btn btn-primary mr-2',
                cancelButton: 'btn btn-danger mr-2 '
              },
              buttonsStyling: false,
              onBeforeOpen: function () {
                Swal.getContainer().className = constants.CONTAINER_CLASSNAME
              },
              confirmButtonText: 'Ok',
            })
          }
        }
      ];
    }
  }
  componentWillUnmount() {
    this.props.resetCancelledAppointments();
  }

  handleChangeRowsPerPage = (event) => {
    var perpage = parseInt(event.target.value, 10);
    var newData = utility.getpaginateData(this.props.rowData, perpage, 0);
    this.setState({ RowsPerPage: perpage, rows: newData, pageNumber: 0 });

  };
  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    var newData = utility.getpaginateData(this.props.rowData, this.state.RowsPerPage, newPage);
    this.setState({ pageNumber: newPage, rows: newData })
    // console.log(newPage);
  };
  render() {

    const RowRenderer = ({ renderBaseRow, ...props }) => {
      let color = '#3766bd';
      if (props.row && props.row.UserType === "Patient") {
        color = '#468671'
      }
      return <div className="createdstatus" style={{ color }}>{renderBaseRow(props)}</div>;
    };


    return (
      <React.Fragment>
        {/* {this.state.showModal &&
          <MyModal {...this.props} userlist={this.state.userlist} modalData={this.state.modalData} handleClose={this.hideModal}>
          </MyModal>
        } */}
        <div className="row">
          <div className="col-sm-12">
            <ReactDataGrid
              rowRenderer={RowRenderer}
              onGridRowsUpdated={this.onGridRowsUpdated}
              columns={this.getColumns()}
              rowGetter={i => this.filteredRows()[i]}
              rowsCount={this.filteredRows().length}
              onGridSort={this.onGridSort}
              onAddFilter={this.handleFilterChange}
              onClearFilters={this.onClearFilters}
              toolbar={<Toolbar enableFilter={true} />}
              enableCellSelect={true}
              emptyRowsView={utility.EmptyGridRowView}
              // onRowClick={event => this.onRowClick(event)}
              minHeight={550}
              headerRowHeight={utility.heightOfHeaderRow}
              getCellActions={(column, row) => this.getCellActions(column, row)}
              rowHeight={utility.heightOfRow}
              getValidFilterValues={columnKey => this.getValidFilterValues(this.props.rowData, columnKey)}
            />
            
          </div>
          <div className="text-center">
              <Pagination
                rowsPerPageOptions={constants.ROWPERPAGE_OPTIONS}
                component="div"
                count={this.props.rowData.length}
                rowsPerPage={this.state.RowsPerPage}
                page={this.state.pageNumber}
                colSpan={12}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
        </div>
    
      </React.Fragment>
    );
  }
}

export default MyDataGrid