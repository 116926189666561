import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Calendar, Views } from 'react-big-calendar'
import moment from 'moment'
import Swal from 'sweetalert2';

import MyWeek from '../../../components/MyCalendar/MyWeek/index';
import MyDay from '../../../components/MyCalendar/MyDay/index';
import MyMonth from '../../../components/MyCalendar/MyMonth/index';

import MyAgenda from '../../../components/MyCalendar/MyAgenda/index';

import CalendarToolbar from '../../../components/MyCalendar/ToolBar/index'
import { ColoredDateCellWrapper, localizer } from '../../../components/MyCalendar/Utils/util'

import { AppontmentService } from "../../../service/Appointment";
import constants from '../../../util/constants'
import utility from '../../../util/utility'
import appSetting from '../../../AppSettings.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

class MyCalendarPresentation extends Component {

    constructor(props) {
        super(props);
        this.checkSlotIsBookedAlready = this.checkSlotIsBookedAlready.bind(this);
        this.state = {
            toDashboard: false,
            isLoading: false,
            events: [],
            usertype: null,
            selectedDate: new Date(),
            slotCounts: 0,
            createdCounts: 0,
            patientCreatedCount: 0,
            UserId: this.props.userDetails.UserId,
        };
        this.bindCountOfBookings = this.bindCountOfBookings.bind(this);
        // this.handleView=this.handleView.bind(this);

    }

    bindCountOfBookings(date, viewType) {
        let regAvailability = this.props.registrarDetails.regAvailability;
        if (viewType === 'week') {
            let endDate = new Date(date);
            endDate.setDate(endDate.getDate() + 4);
            var availableInThisWeek=regAvailability.filter(d => {var time = new Date(d.start);
                return (new Date(date) < time && time < endDate);
            });
            if (availableInThisWeek && availableInThisWeek.length !== this.state.slotCounts) {
    
                this.setState({
                    createdCounts: availableInThisWeek.filter(x => x.title === "Available").length,
                    patientCreatedCount: availableInThisWeek.filter(x=>x.title === 'PatientBooked').length,
                    slotCounts: availableInThisWeek.length
                })
            }
        }
        else if (viewType === 'month') {
            let availableInThisMonth = regAvailability.filter(x =>
                moment.parseZone(x.start).format('MMMM YYYY') === moment(date).format("MMMM YYYY"));
            if (availableInThisMonth && availableInThisMonth.length !== this.state.slotCounts) {
    
                this.setState({
                    createdCounts: availableInThisMonth.filter(x => x.title === "Available").length,
                    patientCreatedCount: availableInThisMonth.filter(x => x.title === 'PatientBooked').length,
                    slotCounts: availableInThisMonth.length
                })
            }
        }
        else {
            var availableInThisDay=regAvailability.filter(d => {
                return (new Date().toDateString()==new Date(d.start).toDateString());
               });
            if (availableInThisDay && availableInThisDay.length !== this.state.slotCounts) {
    
                this.setState({
                    createdCounts: availableInThisDay.filter(x => x.title === "Available").length,
                    patientCreatedCount: availableInThisDay.filter(x => x.title === 'PatientBooked').length,
                    slotCounts: availableInThisDay.length
                })
            }
        }
    }


    UNSAFE_componentWillMount() {
        utility.sendHeight(3300);
        this.usertype = this.props.userDetails.UserType;
        //this.props.fetchingDone()
    }
    getContextForDelete = (event) => {
        let wrapperContent =
            `<div class="row text-left ">
            <div class="col-5 font-weight-bold">Date</div>
            <div class="col-1">:</div>
            <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
        </div>
        <div class="row text-left">
            <div class="col-5 font-weight-bold">Time</div>
            <div class="col-1">:</div>
            <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
        </div>`;
        return wrapperContent;
    }
    handleSelectEvent = event => {
        if (!event.IsDifferentPlatform) {
            if (event && new Date() <= event.start) {
                if (event.title === "Available") {
                    Swal.fire({
                        title: constants.CANCEL_POPUP_TITLE,
                        html: this.getContextForDelete(event),
                        showCloseButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-danger mr-2',
                            cancelButton: 'btn btn-secondary mr-2'
                        },
                        confirmButtonText: constants.CANCEL_AVAILABILITY_BTN_TEXT,
                        cancelButtonText: 'Close',
                        onBeforeOpen: function () {
                            Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                        },
                    })
                        .then((result) => {
                            let deleContent = document.createElement('div');
                            deleContent.innerHTML = `<input type="text" required/>`
                            if (result.value) {
                                Swal.fire({
                                    title: constants.CANCELLATION_COMMENT_TITLE,
                                    text: constants.CANCELLATION_COMMENT_TEXT,
                                    input: 'text',
                                    inputAttributes: {
                                        autocapitalize: 'off'
                                    },
                                    showCloseButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-primary mr-2',
                                        cancelButton: 'btn btn-secondary mr-2 '
                                    },
                                    reverseButtons: true,
                                    buttonsStyling: false,
                                    cancelButtonText: 'Close',
                                    showCancelButton: true,
                                    confirmButtonText: 'Ok',
                                    showLoaderOnConfirm: true,
                                    onBeforeOpen: function () {
                                        Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                                    },
                                    preConfirm: (result) => {
                                        if (result !== '' && result.length >= 10) {
                                            this.DeleteRegistrarAvailability(event.id, event.PatientBookingID, event.start, event.end, result, this.state.UserId,event.ReferralId, event.RegistrarFullName);
                                        }
                                        else {
                                            var message = constants.COMMENTS_REQUIRED
                                            if (result !== '' && result.length <= 9) {
                                                message = constants.COMMENTS_MINIMUM;
                                            }
                                            Swal.showValidationMessage(
                                                message
                                            )
                                        }
                                    },
                                    allowOutsideClick: () => !Swal.isLoading()
                                })
                            }
                        });
                }
                else if (event.title === "PatientBooked") {
                    let previousDay = new Date(event.start);
                    previousDay.setDate(previousDay.getDate() - 1);
                    let buttons = [];
                    let title = ""
                    let confirmButtonText = 'Cancel';
                    let showConfirmButton = false;
                    if (new Date() <= previousDay) {
                        title = constants.CANCLE_POPUP_PATIENT_TITLE;
                        confirmButtonText = constants.REGISTRAR_CONFIRM_APPOINTMENT_BTN_TEXT;
                        showConfirmButton = true;
                    }
                    else {
                        title = constants.CANCLE_POPUP_PATIENT_TITLE_24;
                    }
                    let patinetCreatedContent =
                        `<div class="row text-left">
                    <div class="col-5 font-weight-bold">Referral ID</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.ReferralId}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Patient Name</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.PatientName}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">NHS Number</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.NhsNumber}</div>
                </div>
                <div class="row text-left ">
                    <div class="col-5 font-weight-bold">Date</div>
                    <div class="col-1">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Time</div>
                    <div class="col-1">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                </div>
            `;
                    Swal.fire({
                        title: title,
                        html: patinetCreatedContent,
                        showCloseButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        buttonsStyling: false,
                        showConfirmButton: showConfirmButton,
                        customClass: {
                            confirmButton: 'btn btn-danger mr-2',
                            cancelButton: 'btn btn-secondary mr-2'
                        },

                        confirmButtonText: confirmButtonText,
                        cancelButtonText: constants.REGISTRAR_CLOSE_APPOINTMENT_BTN_TEXT,
                        buttons: buttons,
                        onBeforeOpen: function () {
                            Swal.getPopup().className = constants.POPUP_CLASSNAME
                            Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                        },
                    })
                        .then((resp) => {
                            if (resp.value) {
                                // TODO:integrate sendToAdmin Api
                                Swal.fire({
                                    title: constants.REGISTRAR_CANCELLATION_COMMENT_TITLE,
                                    text: constants.REGISTRAR_CANCELLATION_COMMENT_TEXT,
                                    input: 'text',
                                    inputAttributes: {
                                        autocapitalize: 'off'
                                    },
                                    showCloseButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-primary mr-2',
                                        cancelButton: 'btn btn-secondary mr-2 '
                                    },
                                    reverseButtons: true,
                                    buttonsStyling: false,
                                    cancelButtonText: constants.REGISTRAR_CLOSE_APPOINTMENT_BTN_TEXT,
                                    showCancelButton: true,
                                    confirmButtonText: constants.REGISTRAR_CONFIRM_APPOINTMENT_BTN_TEXT,
                                    showLoaderOnConfirm: true,
                                    onBeforeOpen: function () {
                                        Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                                    },
                                    preConfirm: (result) => {
                                        if (result !== '' && result.length >= 10) {
                                            this.DeleteRegistrarAvailability(event.id, event.PatientBookingID, event.start, event.end, result, this.state.UserId, event.ReferralId, event.RegistrarFullName);
                                        }
                                        else {
                                            var message = constants.COMMENTS_REQUIRED
                                            if (result !== '' && result.length <= 9) {
                                                message = constants.COMMENTS_MINIMUM;
                                            }
                                            Swal.showValidationMessage(
                                                message
                                            )
                                        }
                                    },
                                    allowOutsideClick: () => !Swal.isLoading()
                                })
                            }
                        });

                }
                else if (event.title === "UserCancelRequest") {
                    let patinetCreatedContent =
                        `<div class="row text-left">
                    <div class="col-5 font-weight-bold">Referral ID</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.ReferralId}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Patient Name</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.PatientName}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">NHS Number</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.NhsNumber}</div>
                </div>
                <div class="row text-left ">
                    <div class="col-5 font-weight-bold">Date</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Time</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                </div>
            `;
                    Swal.fire({
                        title: constants.SLOT_IS_IN_ADMIN,
                        html: patinetCreatedContent,
                        showCloseButton: true,
                        showCancelButton: false,
                        customClass: {
                            confirmButton: 'btn btn-primary mr-2',
                        },
                        buttonsStyling: false,
                        onBeforeOpen: function () {
                            Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                        },
                    });
                }
            }
            else {
                //TODO: changes for popup for previous data
                let prevContent = '';
                if (event.title === "PatientBooked" || event.title === "UserCancelRequest") {
                    prevContent =
                        `<div class="row text-left">
                    <div class="col-5 font-weight-bold">Referral ID</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.ReferralId}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Patient Name</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.PatientName}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Date</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Time</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                </div>`;
                }
                else {
                    prevContent =
                        `<div class="row text-left">
                    <div class="col-5 font-weight-bold">Date</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 ">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Time</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                </div>
                ` }

                Swal.fire({
                    title: "Booked appointment details",
                    html: prevContent,
                    showCloseButton: true,
                    customClass: {
                        confirmButton: 'btn btn-primary mr-2',
                    },
                    buttonsStyling: false,
                    onBeforeOpen: function () {
                        Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                    },
                })

            }
        }
    };

    GetRegistrarAvailability(UserId) {
        var eventslist = [];

        AppontmentService.GetUserAvailability(UserId)
            .then(
                result => {
                    if (result) {
                        result.forEach((el, index) => {
                            let start = moment(el.StartDate).format()
                            let end = moment(el.EndDate).format()
                            eventslist.push({
                                id: el.AvailBookingID,
                                title: el.StatusDesc,
                                start: new Date(start),
                                end: new Date(end),
                                desc: 'Referral ID : ' + el.ReferralId + ', Patient Name : ' + el.PatientName
                            });
                        })
                        this.setState({ events: eventslist });
                    }
                },
                error => {
                    this.setState({ toDashboard: true });
                    
                }
            );
    };
    checkSlotIsBookedAlready(start) {
        let bookedSlots = this.props.registrarDetails.regAvailability;
        let isNotBookedSlots = true;
        let startTime = start.toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit"
        });
        if (bookedSlots && bookedSlots.length > 0) {
            bookedSlots.forEach(slot => {
                let startDate = slot.start;
                const startDateTime = startDate.toLocaleString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "2-digit"
                });
                if (startTime === startDateTime) {
                    isNotBookedSlots = false;
                }
            });
        }
        return isNotBookedSlots;

    }

    handleSelectSlot = ({ start, end }) => {
        if (new Date() <= start && this.checkSlotIsBookedAlready(start)) {
            const slotContent =
                `<div class="row text-left">
                <div class="col-5 font-weight-bold">Date</div>
                <div class="col-1 text-center">:</div>
                <div class="col-6 text-left">${moment.parseZone(start).format('DD-MM-YYYY')}</div>
            </div>
            <div class="row text-left">
                <div class="col-5 font-weight-bold">Time</div>
                <div class="col-1 text-center">:</div>
                <div class="col-6 text-left">${moment.parseZone(start).format('HH:mm')} - ${moment.parseZone(end).format('HH:mm')}</div>
            </div>`;
            Swal.fire({
                title: constants.REG_AVAILABILITY,
                html: slotContent,
                showCloseButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary mr-2',
                    cancelButton: 'btn btn-secondary mr-2 '
                },
                reverseButtons: true,
                buttonsStyling: false,
                cancelButtonText: 'Close',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                onBeforeOpen: function () {
                    Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                },
            })
                .then((willDelete) => {
                    if (willDelete.value) {
                        this.InsertRegistrarAvailability(new Date(start), new Date(end), this.state.UserId, this.state.UserId)
                    }
                });
        }
        else {
            return false;
        }
    };


    DeleteRegistrarAvailability(RegistrarBookingID, PatientBookingID, StartDate, EndDate, Remarks, CreatedBy, ReferralId, RegistrarFullName) {
        AppontmentService.UpdateUserAvailability(RegistrarBookingID, PatientBookingID, StartDate, EndDate, Remarks, CreatedBy, ReferralId,RegistrarFullName)
            .then(
                result => {
                    let message=constants.REGISTRAR_AUTOASSIGN
                    if (!result) {
                        message=constants.REGISTRAR_NOT_AUTOASSIGN
                    }
                    Swal.fire(message);
                    this.props.resetData();
                    this.props.getRegistrarAvailability({ "UserId": CreatedBy });
                },
                error => {
                    this.setState({ toDashboard: true });
                   
                }
            );
    };


    InsertRegistrarAvailability(StartDate, EndDate, UserId, CreatedBy) {
        AppontmentService.InsertUserAvailability(StartDate, EndDate, UserId, CreatedBy)
            .then(
                result => {
                    if (result) {
                        this.props.resetData();
                        this.props.getRegistrarAvailability({ "UserId": UserId });
                    }
                },
                error => {
                    this.setState({ toDashboard: true });
                   
                }
            );
    };
    eventPropGetter = (event, start, end, isSelected) => {
        let newStyle = '';

        if (event.title === "Available") {
            newStyle = 'inprogressstatus';
        }
        else if (event.title === 'UserCancelRequest') {
            newStyle = 'cancelstatus'
        }
        else if (event.title === 'AvailableCompleted') {
            newStyle = 'completedstatus'
        }
        else if(event.title ==='PatientBooked'){
            newStyle='createdstatus'
        }
        else if (event.title === 'OtherPlatform' || event.IsDifferentPlatform) {
            newStyle = 'otherPlatform'
        }

        return {
            className: newStyle,
            // style: ""
        };
    };


    Event = ({ event }) => {
        return event.title;
    }
    updateTooltip = (event) => {
        return event.title;
    }

    render() {
        if (this.usertype === null) {
            return <Redirect to='/Login' />
        }
        else if (this.usertype === "MedeferAdmin") {
            return <Redirect to='/Admin' />
        }
        else if
            (this.usertype === "PAT") {

            var isvalid = localStorage.getItem('isvalidpatinet');
            if (isvalid === "true") {
                return <Redirect to='/Appointment' />
            }
            else {
                return <Redirect to='/PatientLogin' />
            }
        }
        else {let formats={dateFormat:'dd',
            timeGutterFormat:'HH:mm'
            }
            return (
                <React.Fragment>
                    <div id="wrapper">
                        <div id="content-wrapper">
                            <div className="container-fluid">
                                {this.props.registrarDetails.regAvailability &&
                                    <React.Fragment>
                                        <div className="mb-1">
                                            <div className="d-sm-flex">
                                                <div className="">
                                                    <div class="card text-center mb-1" >
                                                        <div class="card-header text-white patient-mapped-bg">Patient Booked</div>
                                                        <div class="card-body-card">
                                                            <h3><p class="card-text">{this.state.patientCreatedCount}</p></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ml-1">
                                                    <div class="card text-center mb-1" >
                                                        <div class="card-header text-white registrar-created-bg">Registrar Available</div>
                                                        <div class="card-body-card">
                                                            <h3> <p class="card-text">{this.state.createdCounts}</p></h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <p className="ml-2">
                                                    <FontAwesomeIcon color='#A9A9A9' className="modifyIcon" icon={faInfoCircle} />
                                                     {constants.OTHER_PLATFORM_SLOTS}</p>
                                            </div>
                                        </div>

                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <Calendar
                                                    selectable
                                                    events={this.props.registrarDetails.regAvailability}
                                                    views={{ week: MyWeek, day: MyDay, month: MyMonth, agenda:MyAgenda }}
                                                    step={20}
                                                    titleAccessor="patient"
                                                    localizer={localizer}
                                                    timeslots={1}
                                                    defaultView={Views.WEEK}
                                                    startAccessor="start"
                                                    endAccessor="end"
                                                    showMultiDayTimes={true}
                                                    defaultDate={this.state.selectedDate}
                                                    onSelectEvent={event => this.handleSelectEvent(event)}
                                                    onSelectSlot={this.handleSelectSlot}
                                                    dayLayoutAlgorithm={"no-overlap"}
                                                    min={new Date(2000, 0, 1, appSetting.startTime, 0)}
                                                    max={new Date(2100, 0, 1, appSetting.endTime, 59)}
                                                    formats={formats}
                                                    components={{
                                                        event: this.Event,
                                                        timeSlotWrapper: ColoredDateCellWrapper,
                                                        toolbar: CalendarToolbar
                                                    }}
                                                    toolbar={true}
                                                    eventPropGetter={(this.eventPropGetter)}
                                                    tooltipAccessor={this.updateTooltip}
                                                    {...this}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            {/* <Footer /> */}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default MyCalendarPresentation