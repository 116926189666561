// import { mapTo } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { mapTo, delay } from 'rxjs/operators'
import actions from '../actions/constants'
const loaderEndEpic = action$ =>
    action$.pipe(
        ofType(actions.FETCH_REGAVAILAIBILITY_SUCCESS,
            actions.AUTH_USER_SUCCESS,
            actions.GET_PATIENT_USER_SLOTS_SUCCESS,
            actions.GET_CANCELLED_APPOINTMENTS_SUCCESS,
            actions.GET_AVAILABLESLOT_USERS_SUCCESS,
            actions.GET_ALL_REGISTRAR_PATIENT_BOOKING_SUCCESS,

            actions.FETCH_REGAVAILAIBILITY_SUCCESS,
            actions.UPDATE_REGAVAILABILITY,
            actions.AUTH_USER_SUCCESS,
            actions.GET_PATIENT_USER_SLOTS_SUCCESS,
            actions.GET_CANCELLED_APPOINTMENTS_SUCCESS,
            actions.GET_AVAILABLESLOT_USERS_SUCCESS,
            actions.UPDATE_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS,
            actions.GET_ALL_PENDING_PATINET_BOOKINGS_SUCCESS,
            actions.GET_ALL_BOOKED_PATIENTS_SUCCESS,
            actions.GET_ALL_CLERKED_PATIENTS_SUCCESS,
            actions.GET_ALL_REGISTRAT_DNA_SUCCESS,
            actions.GET_ALL_PATIENT_DNA_SUCCESS,
            actions.GET_ALL_REGISTRAR_PATIENT_BOOKING_SUCCESS,
            actions.GET_ALL_AWAITING_BOOKED_PATIENTS_SUCCESS,
            //  actions.IS_FETCHING,
        ),
        delay(100),
        mapTo({
            type: actions.FETCHING_DONE
        })
    );

export default loaderEndEpic