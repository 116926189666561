import { combineEpics } from 'redux-observable'
import regAvailabilityEpic from '../../pages/RegAvailability/regAvailability.epic'
import patientEpic from '../../pages/Appointment/appointment.epic'
import adminEpic from '../../pages/Admin/admin.epic'
import loaderEndEpic from './loaderEndEpic'
import loaderStartEpic from './loaderStartEpic'

const rootEpic = combineEpics(
    loaderStartEpic,
    loaderEndEpic,
    regAvailabilityEpic,
    patientEpic,
    adminEpic,

)

export default rootEpic