import React from "react";
import BookedPresentation from './presentation'
// import Presentation from './presentation'

class BookedPatients extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

  }
  componentDidMount() {
    this.props.getAllBookedPatinets({UserId: this.props.userDetails.UserId});
  }
  componentWillUnmount() {
    this.props.resetAllBookedPatinets();
  }
  componentDidCatch(){
    //this.props.fetchingDone();
  }
  render() {

    return (
      <React.Fragment>
        {this.props.registrarDetails && this.props.registrarDetails.registrarBookings &&
          <div className="card">
            <div className="card-body">
              <BookedPresentation {...this.props} showCalendar={false} 
              rowData={this.props.registrarDetails.registrarBookings}></BookedPresentation>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default BookedPatients