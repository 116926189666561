import React from 'react'
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import DatePicker from 'react-datepicker';
import utility from '../../../../util/utility';
import constants from '../../../../util/constants';

class PatientView extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.onTagsChange = this.onTagsChange.bind(this);
        this.savePatient = this.savePatient.bind(this);
        this.userlist = [
            {
                value: "1", title: "No answer – No Voicemail box"
            }, {
                value: "2", title: "Patient answered"
            }, {
                value: "3", title: "No answer-left message"
            }]
        this.state = {
            preferedEmail: props.viewData && props.viewData.PreferedEmail ? props.viewData.PreferedEmail : '',
            isSelected: null,
            AdminNonMedicalNote: props.viewData && props.viewData.AdminNonMedicalNote ? this.props.viewData.AdminNonMedicalNote : ''
            , SecurityCheck: props.viewData && props.viewData.SecurityCheck ? props.viewData.SecurityCheck : false
            , showContact: props.viewData && props.viewData.SecurityCheck ? props.viewData.SecurityCheck : false,
            telephoneNumber: props.viewData && props.viewData.PreferedMobile ? props.viewData.PreferedMobile : '',
            isValidPhoneNumber: false,
            isValidEmail: false,
        }
    }
    componentDidMount() {
        //this.props.getAllPendingPatinetBookings();
        //this.props.fetchingDone();
        utility.sendHeight(1145);
    }
    UNSAFE_componentWillMount() {
        var selcAnswer = this.props.viewData && this.props.viewData.PatientAnswer;
        var optionList = this.userlist;
        if (selcAnswer != null && selcAnswer !== "0") {
            this.setState({
                isSelected: optionList.filter(x => x.value === selcAnswer)[0]
            })
        }

    }
    onTagsChange = (event, values) => {
        if (values) {
            this.setState({
                isSelected: values
            })
        }
        else {
            this.setState({
                isSelected: null
            })
        }
    }
    savePatient = (type) => {

        var obj = {
            referralId: this.props.viewData.ReferralId,
            Notes: this.state.AdminNonMedicalNote,
            PatientAnswer: (this.state.isSelected && this.state.isSelected.value) ? this.state.isSelected.value : "0",
            SecurityCheck: this.state.showContact ?? false,
            isAdmin: true,
            userId: this.props.userDetails.UserId,
            PreferedEmail: this.state.preferedEmail,
            PreferedTelephone: this.state.telephoneNumber

        }
        this.props.SaveClerkingNotes(obj, type, this.props.viewData);
    }

    handleChange = (date) => {
        this.setState({ startDate: date });
    }
    textAreaOnChange = (event) => {
        this.setState({ AdminNonMedicalNote: event.target.value });
    };
    handleEmailChange = (e) => {
        var isValidEmail=false;
        var value = e.target.value;
        if (value=="" ||constants.EMAIL_REGEX.test(value)) {
           isValidEmail=false;
        }
        else {
            isValidEmail=true;
        }
        this.setState({
            preferedEmail: e.target.value,
            isValidEmail: isValidEmail
        })

    }
    handleNumberChange = (e) => {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
            return false;
        }
        var isValidPhoneNumber=false;
        var value = e.target.value;
        if (value=="" ||constants.UK_TELEPHONE_REGEX.test(value)) {
           isValidPhoneNumber=false;
        }
        else {
           isValidPhoneNumber=true;
        }
        this.setState({
            telephoneNumber: e.target.value,
            isValidPhoneNumber: isValidPhoneNumber
        })
    }
    render() {
        const dateToLocale = (dob) => {
            var mydate = moment(dob, 'DD/MM/YYYY');
            var convertedDate = moment(mydate).format("MM/DD/YYYY");
            return new Date(convertedDate);
        }

        const { PatientName, Gender, MobileTelephone, HomeTelephone, OtherPhoneTelephone, PreferedMobile, NhsNumber, DateOfBirth
        , ReferralId} = this.props.viewData;
        return (
            <React.Fragment>
                <div className="mt-3">
                    <div className="p-3 bg-light">
                        <section >
                            <div className="row mb-2 ">
                                <div className="col-sm-9 font-weight-bold ">
                                    <h1 className="text-center">Appointment Booking Confirmation</h1>
                                </div>
                                <div className="col-sm-3">
                                    <button className="btn btn-danger text-right" onClick={() => this.props.showGrid()}> <FontAwesomeIcon icon={faListAlt} />{this.props.backText ? this.props.backText : "Back To Pending Patient"}</button>
                                </div>
                            </div>
                        </section>
                        <section>
                            <h4 className="text-primary">Patient Information</h4>
                            <div className="m-2">
                            <div className="row mb-2">
                                        <div className="col-sm-3 font-weight-bold">
                                            {" Referral Id"}
                                        </div>
                                        <div className="col-sm-6">
                                            {ReferralId}
                                        </div>
                                    </div>
                                {PreferedMobile &&
                                    <div className="row mb-2">
                                        <div className="col-sm-3 font-weight-bold">
                                            {" Prefered Number"}
                                        </div>
                                        <div className="col-sm-6">
                                            {PreferedMobile}
                                        </div>
                                    </div>
                                }
                                {MobileTelephone &&
                                    <div className="row mb-2">
                                        <div className="col-sm-3 font-weight-bold">
                                            {" Mobile Telephone"}
                                        </div>
                                        <div className="col-sm-6">
                                            {MobileTelephone}
                                        </div>
                                    </div>
                                }
                                {HomeTelephone &&
                                    <div className="row mb-2">
                                        <div className="col-sm-3 font-weight-bold">
                                            {" Home Telephone"}
                                        </div>
                                        <div className="col-sm-6">
                                            {HomeTelephone}
                                        </div>
                                    </div>
                                }
                                {OtherPhoneTelephone &&
                                    <div className="row mb-2">
                                        <div className="col-sm-3 font-weight-bold">
                                            {"Other Telephone"}
                                        </div>
                                        <div className="col-sm-6">
                                            {OtherPhoneTelephone}
                                        </div>
                                    </div>
                                }

                                <div className="row mb-2">
                                    <div className="col-sm-3 font-weight-bold ">
                                        {"Name"}
                                    </div>
                                    <div className="col-sm-6">
                                        {PatientName}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-3 font-weight-bold">
                                        {"Gender"}
                                    </div>
                                    <div className="col-sm-6">
                                        {Gender}
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-sm-3 font-weight-bold">
                                        {"NHS Number"}
                                    </div>
                                    <div className="col-sm-6">
                                        {NhsNumber}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-3 font-weight-bold">
                                        {"Notes non-medical"}
                                    </div>
                                    <div className="col-sm-6">
                                        <TextareaAutosize
                                            rows={10}
                                            cols={48}
                                            rowsMax={20}
                                            aria-label="maximum height"
                                            placeholder="Maximum 20 rows"
                                            defaultValue={this.state.AdminNonMedicalNote}
                                            onChange={(event) => this.textAreaOnChange(event)}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-3 font-weight-bold">
                                        {"Contacted Patient"}
                                    </div>
                                    <div className="col-sm-4">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.userlist}
                                            getOptionLabel={(option) => option.title ?? ''}
                                            size="small"
                                            value={this.state.isSelected ?? []}
                                            onChange={this.onTagsChange}
                                            renderInput={(params) => <TextField {...params} label="Please select" variant="outlined" />}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            {this.state.isSelected && this.state.isSelected.value === "2" &&
                                <React.Fragment>
                                    <h4 className="text-primary">Security Check</h4>
                                    <div className="m-2">
                                        <div className="row">
                                            <div className="col-3 font-weight-bold">
                                                {" Date Of Birth"}
                                            </div>
                                            <div className="col-3 font-weight-bold">
                                                <DatePicker
                                                    selected={dateToLocale(DateOfBirth) ?? ''}
                                                    onChange={this.handleChange}
                                                    dateFormat="dd-MMM-yyyy"
                                                    className="form-control"
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    maxDate={new Date()}
                                                    placeholderText="Date of Birth"
                                                />
                                            </div>
                                            <div className="col-2">
                                                <input type="checkbox" checked={this.state.SecurityCheck}
                                                    onClick={() => {
                                                        this.setState({
                                                            showContact: !this.state.showContact ?? true,
                                                            SecurityCheck: !this.state.SecurityCheck
                                                        })
                                                    }}></input>
                                            </div>
                                        </div>
                                        {this.state.showContact &&
                                            <React.Fragment>
                                                <div className="row">
                                                    <p>Which is the patients prefered way of contact?</p>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-3 font-weight-bold">
                                                        {"Email"}
                                                    </div>
                                                    <div className="col-sm-3  ">
                                                        <input type="email" value={this.state.preferedEmail} placeholder="Email" onChange={this.handleEmailChange} className="form-control" id="preferedEmail"></input>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        {this.state.isValidEmail &&
                                                            <label className="text-danger">Please enter valid Email address</label>

                                                        }
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-3 font-weight-bold">
                                                        {"PreferredTelephone"}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <input className="form-control" placeholder="Mobile number" value={this.state.telephoneNumber} onChange={this.handleNumberChange} type="text" id="preferedNumber"></input>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        {this.state.isValidPhoneNumber &&
                                                            <label className="text-danger">Please enter valid UK telephone number</label>

                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                </React.Fragment>
                            }
                        </section>
                        <div className="row mt-5 text-center">
                            <div className="col-sm-10 mb-5">
                                <input className="btn-sm med-btn-default mr-2" onClick={() => this.props.showGrid()} type="button" name="SubmitButton" value="Close" id="btnClose" />
                                {this.state.isSelected && this.state.isSelected.value !== "2" &&
                                    <input className="btn-sm med-btn-primary mr-2" type="button" name="SubmitButton" onClick={() => this.savePatient('save')} value="Save" id="btnSave" />
                                }
                                {this.state.isSelected && this.state.isSelected.value === "2" && this.state.showContact &&
                                    <input disabled={(this.state.isValidPhoneNumber || this.state.isValidEmail)} className="btn-sm med-btn-primary mr-2" type="button" name="SubmitButton" onClick={() => this.savePatient("booking")} value="Create Booking" id="btnCreateBooking" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default PatientView
//export default  connect(mapStateToProps,mapDispatchToProps)(PatientView)