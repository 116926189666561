import React from "react";
import ReactDataGrid from "react-data-grid";
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSlash, faPhoneAlt, faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import PatientView from '../patientView'
import fetch from '../../../../service/Fetch/fetchApi'
import urls from '../../../../util/urls'
import actions from '../../../../store/actions/constants'
// import MyModal from './modal'
import PatientCalendar from '../calendar'
import utility from '../../../../util/utility'
import Pagination from '@material-ui/core/TablePagination';

import constants from '../../../../util/constants'
const selectors = Data.Selectors;

const ProgressBarFormatter = ({ value }) => {
    var color = "green"
    var icon = faPhoneAlt;
    var title = 'Need to call'
    if (value === "2") {
        icon = faPhoneVolume;
        title = 'Answered/Ready to book'
    }
    else if (value == null) {
        icon = faPhoneAlt;
        title = 'Need to call';
    }
    else {
        color = "red";
        icon = faPhoneSlash;
        title = 'No answer';
    }
    return <span style={{ textAlign: "center", fontSize: "larger", color: color }}>
        <FontAwesomeIcon icon={icon} title={title} />
    </span>;
};
const PathFormatter = ({ value, row }) => {
    if (row.IsCrossLimit === true) {
        return <span style={{ textAlign: "center", fontSize: "large", color: 'red' }}>{value}</span>
    }
    else {
        return value;
    }
};

const {
    SingleSelectFilter
} = Filters;
const initialColumns = [
    {
        key: "ReferralId",
        name: "Referral Id",
        locked: true,
        filterable: true,
        width: 90
    },
    // {
    //     key: "PatientReceived",
    //     name: "Patient Received",
    //     formatter: utility.DateFormatter
    // },
    {
        key: "PatientName",
        name: "Patient Name",
        filterable: true,
    },
    {
        key: "NhsNumber",
        name: "NHS Number",
        filterable: true,
        width: 120
    },
    {
        key: "PatientSpeciality",
        name: "Speciality",
        filterable: true,
        filterRenderer: SingleSelectFilter
    },
    {
        key: "TeamAssigned",
        name: "Action By",
        filterable: true,
        filterRenderer: SingleSelectFilter,
        width: 90    
    },
    {
        key: "CCGName",
        name: "CCG Name",
        filterable: true,
        filterRenderer: SingleSelectFilter
    },
    {
        key: "TimeLeftOnPathWay",
        name: "Weeks since request",
        filterable: true,
        filterRenderer: SingleSelectFilter,
        formatter: PathFormatter,
    },
    {
        key: "AdminNonMedicalNote",
        name: "Admin Note"
    },
    {
        key: "RegistrarNonMedicalNote",
        name: "Registrar Notes"
    },
    {
        key: "PatientAnswer",
        name: "Status",
        formatter: ProgressBarFormatter,
        width: 80

    },


].map(c => ({ ...c, ...utility.defaultColumnProperties }));



class PendingPatientPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            columns: initialColumns,
            rows: utility.getpaginateData(this.props.rowData, constants.ROWSPERPAGE, 0),
            filters: {},
            selectedRows: [], //for row selection (column with checkboxes)
            userlist: [],
            showModal: false,
            modalData: {},
            showDataGrid: true,
            pageNumber: 0,
            RowsPerPage: constants.ROWSPERPAGE,
        };
        this.onRowClick = this.onRowClick.bind(this);
        this.showGrid = this.showGrid.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }
    componentDidMount() {
        //this.props.getAllPendingPatinetBookings();
        //this.props.fetchingDone();
        utility.sendHeight();
    }
    UNSAFE_componentWillMount() {

    }

    SaveClerkingNotes = (dataObj, type, rowData) => {
        this.props.isFetching();
        fetch(`${urls.SaveClerkingNotes}`,
            dataObj,
            actions.AUTH_USER)
            .then(response => {
                if (response) {
                    if (type === "booking") {
                        this.setState({
                            showView: false,
                            showCalendar: true,
                            showDataGrid: false,
                            selectedRowData: rowData
                        })
                    }
                    else {
                        this.showGrid();
                        this.props.resetPendingPatinetBookings();
                        this.props.getAllPendingPatinetBookings();
                    }
                }
            }).catch((error) => {

                console.log(error);


            }).then(() => {
                // this.props.fetchingDone();
            })
    }

    onGridSort = (sortColumn, sortDirection) => {
        const tmpCol = this.state.columns.find(col => col.key === sortColumn);
        let numberSorting = false;
        if (tmpCol.type === "integer" || tmpCol.type === "real")
            numberSorting = true; //default sorting is comparing strings, here we need number-sorting

        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                if (numberSorting) {
                    return a[sortColumn] - b[sortColumn];
                }
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                if (numberSorting) {
                    return b[sortColumn] - a[sortColumn];
                }
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            } else {
                return a.id > b.id ? 1 : -1; //restore to sorting by id
            }
        };

        var sortData = [...this.props.rowData].sort(comparer);
        var filteredData = utility.getpaginateData(sortData, this.state.RowsPerPage, 0);
        // this.setState(prevState => ({
        //     rows: [...prevState.rows].sort(comparer)
        // }));
        this.setState(prevState => ({
            rows: filteredData
        }));
    };

    getColumns() {
        return this.state.columns;
    }

    getRows(rows, filters) {
        return selectors.getRows({ rows, filters });
    }

    filteredRows = () => {
        if (Object.keys(this.state.filters).length === 0) {
            return this.getRows(this.state.rows, this.state.filters);
        }
        else {
            return this.getRows(this.props.rowData, this.state.filters);
        }
    };

    handleFilterChange = filter => {
        this.setState(prevState => {
            const newFilters = { ...prevState.filters };
            if (filter.filterTerm) {
                newFilters[filter.column.key] = filter;
            } else {
                delete newFilters[filter.column.key];
            }
            return {
                filters: newFilters,
                selectedRows: [] //clear selected (checkboxes column) rows when filtering
            };
        });
    };

    onClearFilters = () => {
        this.setState({
            filters: {},
            selectedRows: [] //if you have column with checkboxes then clear them all
        });
    };

    // Needed to have selected row even when sorting (select)
    onRowsSelected = rows => {
        this.setState(prevState => ({
            selectedRows: prevState.selectedRows.concat(rows.map(r => r.row.id)) //assuming id is the key of each row
        }));
    };
    // Needed to have selected row even when sorting (deselect)
    onRowsDeselected = rows => {
        let rowIndexes = rows.map(r => r.row.id); //assuming id is the key of each row

        this.setState(prevState => ({
            selectedRows: prevState.selectedRows.filter(
                i => rowIndexes.indexOf(i) === -1
            )
        }));
    };

    //Also onGridRowsUpdated should update only filtered rows:
    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        this.setState(prevState => {
            const rows = prevState.rows;
            const filtered = this.filteredRows();
            for (let i = fromRow; i <= toRow; i++) {
                const rows_index = rows.map(x => x.id).indexOf(filtered[i].id); //assuming "id" is the key of each row
                rows[rows_index] = { ...filtered[i], ...updated };
            }

            return {
                rows: rows
            };
        });
    };
    getValidFilterValues(rows, columnId) {
        return rows
            .map(r => r[columnId])
            .filter((item, i, a) => {
                return i === a.indexOf(item);
            });
    }


    hideModal = () => {
        this.setState({ showModal: false });
    };

    getCellActions(column, row) {
        if (column.key === 'TimeLeftOnPathWay' && row.IsCrossLimit === true) {
            return <span style={{ textAlign: "center", fontSize: "larger", color: 'red' }}>{row.TimeLeftOnPathWay}</span>

        }

    }
    //   componentWillUnmount(){
    //    this.props.resetCancelledAppointments();
    //   }
    onRowClick = ({ rowIdx, row }) => {
        if (rowIdx !== -1) {
            // if (row && row.PatientAnswer === "2") {
            //     this.setState({
            //         showView: false,
            //         showCalendar: true,
            //         showDataGrid: false,
            //         selectedRowData: row
            //     })
            // }
            // else {
            this.setState({
                showView: true,
                showCalendar: false,
                showDataGrid: false,
                selectedRowData: row
            })
            //}
        }
        //this.props.setPatientViewData(row);
        // this.props.push('/')
    }
    showGrid = () => {
        this.props.resetPendingPatinetBookings();
        this.props.getAllPendingPatinetBookings();
        this.setState({
            showView: false,
            showDataGrid: true,
            showCalendar: false,
        })
    }

    handleChangeRowsPerPage = (event) => {
        var perpage = parseInt(event.target.value, 10);
        var newData = utility.getpaginateData(this.props.rowData, perpage, 0);
        this.setState({ RowsPerPage: perpage, rows: newData, pageNumber: 0 });

    };
    handleChangePage = (event, newPage) => {
        // setPage(newPage);
        var newData = utility.getpaginateData(this.props.rowData, this.state.RowsPerPage, newPage);
        this.setState({ pageNumber: newPage, rows: newData })
        // console.log(newPage);
    };
    render() {
        return (
            <React.Fragment>
                {this.state.showCalendar &&
                    // <p>show calendar controler here</p>
                    <PatientCalendar backText=" Back to Pending Patient" showGrid={this.showGrid} patientId={this.state.selectedRowData.PatientId} selectedRowData={this.state.selectedRowData} {...this.props}></PatientCalendar>

                }
                {this.state.showView &&
                    <PatientView backText=" Back to Pending Patient" SaveClerkingNotes={this.SaveClerkingNotes} {...this.props} showGrid={this.showGrid} viewData={this.state.selectedRowData}></PatientView>
                }
                {this.state.showDataGrid &&
                    <div className="row">
                        <div className="col-sm-12">
                            <ReactDataGrid
                                // rowRenderer={RowRenderer}
                                onGridRowsUpdated={this.onGridRowsUpdated}
                                columns={this.getColumns()}
                                rowGetter={i => this.filteredRows()[i]}
                                rowsCount={this.filteredRows().length}
                                onGridSort={this.onGridSort}
                                onAddFilter={this.handleFilterChange}
                                //rowRenderer={this.RowRenderer}
                                onClearFilters={this.onClearFilters}
                                toolbar={<Toolbar enableFilter={true} />}
                                enableCellSelect={true}
                                emptyRowsView={utility.EmptyGridRowView}
                                onRowClick={(rowId, row) => this.onRowClick({ row: row, rowIdx: rowId })}
                                minHeight={550}
                                headerRowHeight={utility.heightOfHeaderRow}
                                // getCellActions={(column, row) => this.getCellActions(column, row)}
                                rowHeight={utility.heightOfRow}
                                getValidFilterValues={columnKey => this.getValidFilterValues(this.props.rowData, columnKey)}
                            />
                        </div>
                        <div className="text-center">
                            <Pagination
                                variant="outlined" shape="rounded"
                                rowsPerPageOptions={constants.ROWPERPAGE_OPTIONS}
                                component="div"
                                nextIconButtonText='Next'
                                count={this.props.rowData.length}
                                rowsPerPage={this.state.RowsPerPage}
                                page={this.state.pageNumber}
                                colSpan={12}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default PendingPatientPresentation