import React from 'react';
import moment from 'moment'
import Agenda from 'react-big-calendar/lib/Agenda';


export default class MyAgenda extends React.Component {
    render() {       
        return <Agenda {...this.props}  />
    }

}
MyAgenda.title = date => {
    return moment(date).format("MMMM YYYY");
}