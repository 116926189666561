import React from 'react';
import {  momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
moment.locale('en')
const ColoredDateCellWrapper = ({ children, value }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: value >=new Date()?'#ffffff':'#e6e6e6',
        },
    })
const localizer = momentLocalizer(moment)

export  { ColoredDateCellWrapper, localizer }