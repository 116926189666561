import settings from '../AppSettings.json'
import { apiCall } from './Fetch/fetch';
import utility from '../util/utility'

var domainURL=utility.getUrlConfig(process.env.REACT_APP_DOMAIN);
function GetAuthToken(token) {
    var urlvalue = domainURL + "/api/User/UserAuthToken/";
    return apiCall.fetchApiCall(urlvalue, {"Token": token});
}

function GetAuthUser(username, password) {
    var urlvalue = domainURL + "/api/User/UserAuth/";
    return apiCall.fetchApiCall(urlvalue, {
        "UserName": username,
        "Password": password
    });
}
function UpdatePatientMobileNumber(UserId,referralId, MobileNumber) {
    var urlvalue = domainURL + "/api/User/UpdatePatientContactInfo/";
    return apiCall.fetchApiCall(urlvalue, {
        "UserId": UserId,
        "MobileNumber": MobileNumber,
        "ReferralId":referralId
    });
}
function UpdatePatientEmailId(userId,referralId, emailId) {
    var urlvalue = domainURL + "/api/User/UpdatePatientEmailId/";
    return apiCall.fetchApiCall(urlvalue, {
        "UserId": userId,
        "EmailId": emailId,
        "ReferralId":referralId
    });
}
function GetUserProfile(UserId) {
    var urlvalue = domainURL + "/api/User/UserProfile/";
    return apiCall.fetchApiCall(urlvalue, {
        "UserId": UserId,
    });
}
function GetPatientAppointmentURL(UserId, UserType, ReferralId, CurrentUser) {
    var urlvalue = domainURL + "/api/User/GetPatientAppointmentURL/";
    return apiCall.fetchApiCall(urlvalue, {
        "UserId": UserId,
        "UserType": UserType,
        "ReferralId": ReferralId,
        "CurrentUser": CurrentUser,
    });
}
function GetUserList() {
    var urlvalue = domainURL + "/api/User/GetUserList/";
    return apiCall.fetchApiCall(urlvalue, {});
}
function DecryptData(parm) {

    var urlvalue = domainURL + "/api/User/GetDecryptData/";
    //var encodedData = Buffer.from(settings.botserviceusername + ':' + settings.botservicepasskey).toString('base64');
    //var authorizationHeaderString = 'Basic ' + encodedData;
    return apiCall.fetchApiCall(urlvalue, {
        "data": parm
    });
}
function logout() {
    // remove user from local storage to log user out
      Window.localStorage.removeItem('user');
}
function clearall() {
    // remove user from local storage to log user out
     localStorage.clear();
}

export const userService = {
    logout,
    clearall,
    GetAuthToken,
    GetUserProfile,
    DecryptData,
    GetAuthUser,
    GetUserList,
    UpdatePatientMobileNumber,
    UpdatePatientEmailId,
    GetPatientAppointmentURL
};
