import React, { Component } from 'react';
import { Calendar, Views } from 'react-big-calendar'
import moment from 'moment'
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux'

import MyAgenda from '../../components/MyCalendar/MyAgenda/index';
import { AppontmentService } from '../../service/Appointment';
import { userService } from '../../service/UserService';
import CalendarToolbar from '../../components/MyCalendar/ToolBar/index'
import MyWeek from '../../components/MyCalendar/MyWeek'
import actions from '../../store/actions/constants'
import { ColoredDateCellWrapper, localizer } from '../../components/MyCalendar/Utils/util'
import constants from '../../util/constants'
import appSetting from '../../AppSettings.json'
import utility from '../../util/utility'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const mapStateToProps = state => ({
    regAvailability: state.regAvailability.data,
    userDetails: state.userDetails.data,
    adminDetails: state.admin
})

const mapDispatchToProps = dispatch => ({
    getRegistrarAvailability: (payload) => {
        dispatch({
            type: actions.FETCH_REGAVAILAIBILITY,
            payload
        });
    },
    resetData: () => {
        dispatch({
            type: actions.RESET_REGAVAILAIBILITY
        })
    },
    dispatch: dispatch,

})

class Registrar extends Component {

    state = {
        toDashboard: false,
        isLoading: false,
        events: [],
        UserId: localStorage.getItem('userid'),
        usertype: null,
        selectedDate: new Date(),
        userlist: [],
        ispat: true,
        isreg: false,
        iscon: false,
        slotuserid: null,
        inputValue: null,
        selectUser: []
    };


    constructor(props) {
        super(props);
        this.usertype = localStorage.getItem('usertype');
        this.selectedDate = new Date();
        this.onTagsChange = this.onTagsChange.bind(this);
    }


   
    UNSAFE_componentWillMount() {
        utility.sendHeight(3300);
        var userlist = [];
        let usrDetails = this.props.adminDetails.userLists;
        if (usrDetails && usrDetails.length > 0) {
            this.setState({ userlist: usrDetails, selectUser: usrDetails[0], slotuserid: usrDetails[0].value });
            this.GetRegistrarAvailability(usrDetails[0].value);
        } else {
            userService.GetUserList()
                .then(
                    result => {
                        if (result) {
                            result.forEach((el, index) => {
                                userlist.push({
                                    value: el.UserId,
                                    title: el.UserId + " - " + el.UserName,
                                    UserName: el.UserName,
                                });
                            })
                            this.setState({ userlist: userlist, selectUser: userlist[0], slotuserid: userlist[0].value });
                            this.GetRegistrarAvailability(userlist[0].value);
                        }
                    },
                    error => {
                        this.setState({ toDashboard: true });
                        console.log(error);
                    }
                );
        }

    }

    onTagsChange = (event, values) => {
        if (values) {

            this.setState({ slotuserid: values.value, selectUser: values });
            this.GetRegistrarAvailability(values.value);
        }
        else {
            this.setState({ slotuserid: null, selectUser: values });
        }
    }
    getContextForDelete = (event) => {
        //let wrapperContent = document.createElement('div');
        //wrapperContent.innerHTML =
        let wrapperContent =
            `<div class="row text-left ">
            <div class="col-5 font-weight-bold">Date</div>
            <div class="col-1">:</div>
            <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
        </div>
        <div class="row text-left">
            <div class="col-5 font-weight-bold">Time</div>
            <div class="col-1">:</div>
            <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
        </div>`;
        return wrapperContent;
    }

    handleSelectEvent = event => {
        if (!event.IsDifferentPlatform) {
            if (event && new Date() <= event.start) {
                if (event.title === "Available") {
                    Swal.fire({
                        title: constants.CANCEL_POPUP_TITLE,
                        html: this.getContextForDelete(event),
                        showCloseButton: true,
                        showCancelButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger mr-2',
                            cancelButton: 'btn btn-secondary mr-2 '
                        },
                        buttonsStyling: false,
                        onBeforeOpen: function () {
                            Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                        },
                        reverseButtons: true,
                        confirmButtonText: constants.CANCEL_AVAILABILITY_BTN_TEXT,
                        cancelButtonText: 'Close',
                    })
                        .then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: constants.CANCELLATION_COMMENT_TITLE,
                                    text: constants.CANCELLATION_COMMENT_TEXT,
                                    input: 'text',
                                    inputAttributes: {
                                        autocapitalize: 'off'
                                    },
                                    showCloseButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-danger mr-2',
                                        cancelButton: 'btn btn-secondary mr-2 '
                                    },
                                    reverseButtons: true,
                                    buttonsStyling: false,
                                    cancelButtonText: 'Close',
                                    showCancelButton: true,
                                    confirmButtonText: 'Ok',
                                    showLoaderOnConfirm: true,
                                    onBeforeOpen: function () {
                                        Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                                    },
                                    preConfirm: (result) => {
                                        if (result !== '' && result.length >= 10) {
                                            this.DeleteRegistrarAvailability(event.id, event.PatientBookingID, event.start, event.end, result, this.state.UserId, event.ReferralId)
                                        }
                                        else {
                                            var message = constants.COMMENTS_REQUIRED
                                            if (result !== '' && result.length <= 9) {
                                                message = constants.COMMENTS_MINIMUM;
                                            }
                                            Swal.showValidationMessage(
                                                message
                                            )
                                        }
                                    },
                                    allowOutsideClick: () => !Swal.isLoading()
                                })
                            }
                        });
                }
                else if (event.title === "PatientBooked") {
                    let previousDay = new Date(event.start);
                    previousDay.setDate(previousDay.getDate() - 1);
                    let title = "";
                    let confirmButtonText = ''
                    let showConfirmButton = false;
                    if (new Date() <= previousDay) {
                        title = constants.CANCLE_POPUP_PATIENT_TITLE;
                        confirmButtonText = 'Confirm';
                        showConfirmButton = true
                    }
                    else {
                        title = constants.CANCLE_POPUP_PATIENT_TITLE_24;
                        confirmButtonText = 'Cancel';
                    }
                    let patinetCreatedContent =
                        `<div class="row text-left">
                    <div class="col-5 font-weight-bold">Referral ID</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.ReferralId}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Patient Name</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.PatientName}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">NHS Number</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.NhsNumber}</div>
                </div>
                <div class="row text-left ">
                    <div class="col-5 font-weight-bold">Date</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Time</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                </div>`;

                    Swal.fire({
                        title: title,
                        html: patinetCreatedContent,
                        reverseButtons: true,
                        buttonsStyling: false,
                        showCloseButton: true,
                        showCancelButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger mr-2',
                            cancelButton: 'btn btn-secondary mr-2 '
                        },
                        showConfirmButton: showConfirmButton,
                        confirmButtonText: confirmButtonText,
                        cancelButtonText: 'Close',
                        onBeforeOpen: function () {
                            Swal.getPopup().className = constants.POPUP_CLASSNAME
                            Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                        },
                    })
                        .then((resp) => {
                            if (resp.value) {
                                Swal.fire({
                                    title: 'Are you sure you wish to request cancellation?',
                                    text: 'Please explain why you need to cancel this appointment:',
                                    // title: constants.CANCELLATION_COMMENT_TITLE,
                                    // text: constants.CANCELLATION_COMMENT_TEXT,
                                    input: 'text',
                                    inputAttributes: {
                                        autocapitalize: 'off'
                                    },
                                    showCloseButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-danger mr-2',
                                        cancelButton: 'btn btn-secondary mr-2 '
                                    },
                                    reverseButtons: true,
                                    buttonsStyling: false,
                                    cancelButtonText: 'Close',
                                    showCancelButton: true,
                                    confirmButtonText: 'Confirm',
                                    showLoaderOnConfirm: true,
                                    onBeforeOpen: function () {
                                        Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                                    },
                                    preConfirm: (result) => {
                                        if (result !== '' && result.length >= 10) {
                                            this.DeleteRegistrarAvailability(event.id, event.PatientBookingID, event.start, event.end, result, this.state.UserId, event.ReferralId)
                                        }
                                        else {
                                            var message = constants.COMMENTS_REQUIRED
                                            if (result !== '' && result.length <= 9) {
                                                message = constants.COMMENTS_MINIMUM;
                                            }
                                            Swal.showValidationMessage(
                                                message
                                            )
                                        }
                                    },
                                    allowOutsideClick: () => !Swal.isLoading()
                                })
                            }
                        });
                }
                else if (event.title === "UserCancelRequest") {
                    let patinetCreatedContent =
                        `<div class="row text-left">
                    <div class="col-5 font-weight-bold">Referral ID</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.ReferralId}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Patient Name</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.PatientName}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">NHS Number</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.NhsNumber}</div>
                </div>
                <div class="row text-left ">
                    <div class="col-5 font-weight-bold">Date</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Time</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                </div>
            `;
                    Swal.fire({
                        title: constants.SLOT_IS_IN_ADMIN,
                        html: patinetCreatedContent,
                        showCloseButton: true,
                        showCancelButton: false,
                        customClass: {
                            confirmButton: 'btn btn-primary mr-2',
                        },
                        buttonsStyling: false,
                        onBeforeOpen: function () {
                            Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                        }
                    });
                }
            }
            else {
                //TODO: changes for popup for previous data
                let prevContent = '';
                if (event.title === "PatientBooked" || event.title === "UserCancelRequest") {
                    prevContent =
                        `<div class="row text-left">
                    <div class="col-5 font-weight-bold">Referral ID</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.ReferralId}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Patient Name</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.PatientName}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Date</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                </div>
                <div class="row text-left">
                    <div class="col-5 font-weight-bold">Time</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                </div>`;
                }
                else {
                    prevContent = `<div class="row text-left">
                <div class="col-5 font-weight-bold">Date</div>
                <div class="col-1 text-center">:</div>
                <div class="col-6 ">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                </div>
                <div class="row text-left">
                <div class="col-5 font-weight-bold">Time</div>
                <div class="col-1 text-center">:</div>
                <div class="col-6">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                </div>`
                }

                Swal.fire({
                    title: "Booked appointment details",
                    html: prevContent,
                    showCloseButton: true,
                    customClass: {
                        confirmButton: 'btn btn-primary mr-2',
                    },
                    buttonsStyling: false,
                    onBeforeOpen: function () {
                        Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                    },
                })

            }
        }
    };

    GetRegistrarAvailability(UserId) {
        var eventslist = [];
        AppontmentService.GetUserAvailability(UserId)
            .then(
                result => {
                    if (result) {
                        result.forEach((el, index) => {
                            let start = moment(el.StartDate).format()
                            let end = moment(el.EndDate).format()
                            eventslist.push({
                                id: el.AvailBookingID,
                                title: el.IsDifferentPlatform ? el.StatusDesc + (el.BookedAppType==1?' (Secure)':' (IOW)'):  el.StatusDesc,
                                start: new Date(start),
                                end: new Date(end),
                                desc: 'Referral ID : ' + el.ReferralId + ', Patient Name : ' + el.PatientName,
                                ReferralId: el.ReferralId,
                                PatientName: el.PatientName ?? '',
                                PatientBookingID: el.PatientBookingID ?? null,
                                NhsNumber: el.NhsNumber ?? '',
                                IsDifferentPlatform: el.IsDifferentPlatform
                            });
                        })
                        this.setState({ events: eventslist });
                    }
                },
                error => {
                    this.setState({ toDashboard: true });
                    console.log(error);
                }
            );
    };

    checkSlotIsBookedAlready(start) {
        let bookedSlots = this.state.events;
        let isNotBookedSlots = true;
        let startTime = start.toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit"
        });
        if (bookedSlots && bookedSlots.length > 0) {
            bookedSlots.forEach(slot => {
                let startDate = slot.start;
                const startDateTime = startDate.toLocaleString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "2-digit"
                });
                if (startTime === startDateTime) {
                    isNotBookedSlots = false;
                }
            });
        }
        return isNotBookedSlots;

    }

    handleSelectSlot = ({ start, end }) => {
        if (new Date() <= start && this.checkSlotIsBookedAlready(start)) {
            const slotContent =
                `<div class="row text-left">
                <div class="col-5 font-weight-bold">Date</div>
                <div class="col-1 text-center">:</div>
                <div class="col-6 text-left">${moment.parseZone(start).format('DD-MM-YYYY')}</div>
            </div>
            <div class="row text-left">
                <div class="col-5 font-weight-bold">Time</div>
                <div class="col-1 text-center">:</div>
                <div class="col-6 text-left">${moment.parseZone(start).format('HH:mm')} - ${moment.parseZone(end).format('HH:mm')}</div>
            </div>`;
            Swal.fire({
                title: constants.REG_AVAILABILITY,
                html: slotContent,
                showCloseButton: true,
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary mr-2',
                    cancelButton: 'btn btn-secondary mr-2'
                },
                reverseButtons: true,
                buttonsStyling: false,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Close',
                onBeforeOpen: function () {
                    Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                },
            })
                .then((result) => {
                    if (result.value) {
                        this.InsertRegistrarAvailability(new Date(start), new Date(end), this.state.slotuserid, this.state.UserId)
                    }
                });
        }
        else {
            return false;
        }
    };


    DeleteRegistrarAvailability(RegistrarBookingID, PatientBookingID, StartDate, EndDate, Remarks, CreatedBy, ReferralId) {
        var selectedRegistrar=this.state.selectUser;
        AppontmentService.UpdateUserAvailability(RegistrarBookingID, PatientBookingID, StartDate, EndDate, Remarks, CreatedBy, ReferralId, selectedRegistrar.UserName)
            .then(
                result => {
                    // if (result) {
                    // }
                    this.GetRegistrarAvailability(this.state.slotuserid);
                },
                error => {
                    this.setState({ toDashboard: true });
                    console.log(error);
                }
            );
    };


    InsertRegistrarAvailability(StartDate, EndDate, UserId, CreatedBy) {
        AppontmentService.InsertUserAvailability(StartDate, EndDate, UserId, CreatedBy)
            .then(
                result => {
                    if (result) {
                        this.GetRegistrarAvailability(this.state.slotuserid);
                    }
                },
                error => {
                    this.setState({ toDashboard: true });
                    console.log(error);
                }
            );
    };
    eventPropGetter = (event, start, end, isSelected) => {
        let newStyle = '';

        if (event.title === "Available") {
            newStyle = 'inprogressstatus';
        }
        else if (event.title === 'UserCancelRequest') {
            newStyle = 'cancelstatus'
        }
        else if (event.title === 'AvailableCompleted') {
            newStyle = 'completedstatus'
        }
        else if(event.title ==='PatientBooked'){
            newStyle='createdstatus'
        }
        else if (event.title === 'OtherPlatform' || event.IsDifferentPlatform) {
            newStyle = 'otherPlatform'
        }

        return {
            className: newStyle,
            // style: ""
        };
    };


    Event = ({ event }) => {
        return event.title;
    }
    updateTooltip = (event) => {
        return event.title;
    }
    render() {
         {
            let formats={dateFormat:'dd',
        timeGutterFormat:'HH:mm'
            }
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-4">
                            <Autocomplete
                                id="combo-box-demo"
                                size="medium"
                                noOptionsText="No Registrar"
                                options={this.state.userlist}
                                getOptionLabel={(option) => option.title}
                                // style={{ width: 300, height: "40!important" }}
                                value={this.state.selectUser ?? []}
                                onChange={this.onTagsChange}
                                renderInput={(params) => <TextField {...params} label="Please select Registrar" variant="outlined" />}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10">
                        <p className="ml-2">
                            <FontAwesomeIcon color='#A9A9A9' className="modifyIcon" icon={faInfoCircle} />
                             {constants.OTHER_PLATFORM_SLOTS}</p>
                    </div>
                </div>
                <div className="card-body">
                    <Calendar
                        selectable
                        events={this.state.events}
                        views={{ week: MyWeek, day: true, month: true, agenda:MyAgenda }}
                        step={20}
                        titleAccessor="patient"
                        localizer={localizer}
                        timeslots={1}
                        defaultView={Views.WEEK}
                        startAccessor="start"
                        endAccessor="end"
                        showMultiDayTimes={true}
                        defaultDate={this.state.selectedDate}
                        onSelectEvent={event => this.handleSelectEvent(event)}
                        onSelectSlot={this.handleSelectSlot}
                        dayLayoutAlgorithm={"no-overlap"}
                        min={new Date(2000, 0, 1, appSetting.startTime, 0)}
                        max={new Date(2100, 0, 1, appSetting.endTime, 59)}
                        formats={formats}
                        components={{
                            event: this.Event,
                            timeSlotWrapper: ColoredDateCellWrapper,
                             toolbar: CalendarToolbar
                        }}
                        toolbar={true}
                        eventPropGetter={(this.eventPropGetter)}
                        tooltipAccessor={this.updateTooltip}
                    />
                </div>
            </div>
        );
    }
}
}
export default connect(mapStateToProps, mapDispatchToProps)(Registrar)