
const authString = 'Basic ' + Buffer.from('medapiuser:ap%4dgajh09876Shjg1$#10j*gtf132', 'ascii').toString('base64');

const constructRequestOption=(data)=> {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authString
        },
        body: JSON.stringify(data)
    };
    return requestOptions
}

const fetchApiCall=(urlvalue, data)=> {
    try {
        return fetch(urlvalue, constructRequestOption(data))
            .then(handleResponse)
            .then(response => {
                return response;
            });

    } catch (error) {

    }
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // clearall()
                // eslint-disable-next-line no-restricted-globals
                //location.reload(true);
            }

            var error = (data && data.message) || response.statusText;
            if (error === "Data Retrieval is Failed") {
                error = "Invalid credential."
            }
            return Promise.reject(error);
        }

        return data;
    });
}

export const apiCall = {
    fetchApiCall,
};
