import React from 'react'
import Header from '../../elements/header'
import Footer from '../../elements/footer'
import constants from '../../util/constants'

const Cancel = () => {

    return (
        <React.Fragment>
            <Header />
            <div className="container">
                <div className="patientContainer ">
                    <h1 className="text-center">Your appointment has been cancelled</h1>
                    <div className="med-card mx-auto mt-5">
                        <h2>What happens next?</h2>
                        <p className="pt-2">{constants.PATIENT_CANCEL_MESSAGE}</p>
                    </div>
                </div></div>
            <Footer />
        </React.Fragment>
    )
}


export default Cancel