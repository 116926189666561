import React from "react";
import Presentation from './presentation'
import utility from '../../../../util/utility'
import Switch from '@material-ui/core/Switch';
import {Filters } from "react-data-grid-addons";
import constants from "../../../../util/constants";
const {
  SingleSelectFilter
} = Filters;
const initialColumns = [
  {
    key: "ReferralId",
    name: "Referral Id",
    locked: true,
    filterable: true,
    width: 90
  },
  {
    key: "StartDate",
    name: "Appointment Date",
    formatter: utility.DateTimeFormatter
  },
  {
    key: "Name",
    name: "Name",
    filterable: true,
  },
  {
    key: "NhsNumber",
    name: "NHS Number",
    filterable: true,
  },
  {
    key: "CCGName",
    name: "CCG Name",
    filterable: true,
    filterRenderer: SingleSelectFilter
  },
  {
    key: "AdminNonMedicalNote",
    name: "Admin Notes"
  },
  {
    key: "RegistrarNonMedicalNote",
    name: "Registrar Notes"
  },
  {
    key: "RegistrarFullName",
    name: "Registrar Name"

  }
  // , {
  //     key: "Status",
  //     name: "Status",
  //     formatter: ProgressBarFormatter,
  //     width: 80
  // }
].map(c => ({ ...c, ...utility.defaultColumnProperties }));

class BookedPatients extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { active: false }
  }
  componentDidMount() {
    this.props.isFetching();
    this.props.getAllBookedPatinets();
    //this.props.fetchingDone();
  }
  UNSAFE_componentWillMount() {
    this.props.resetAllBookedPatinets();
    this.props.resetAllAwaitingPatinets();
  }
  componentWillUnmount() {

  }
  handleToggle = (toogle) => {
    this.props.isFetching();
    if (!toogle) {
      this.props.resetAllBookedPatinets();
      this.props.resetAllAwaitingPatinets();
      this.props.getAllBookedPatinets();
    }
    else {
        this.props.resetAllBookedPatinets();
        this.props.resetAllAwaitingPatinets();
        this.props.getAllAwaitingPatinets();
    }
    this.setState({ active: !this.state.active });
};
  render() {

    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <div>
              <label>Booked
                            <Switch checked={this.state.active}
                  onClick={() => this.handleToggle(!this.state.active)} color="primary"></Switch>
                            Awaiting Registrars</label>
    <small className="text-info font-weight-bold">{constants.BOOKED_TOOGLE}</small>
            </div>
            {this.props.adminDetails && (this.props.adminDetails.bookedPatients || this.props.adminDetails.awaitingPatients)&&
              <Presentation isBooked={!this.state.active} initialColumns={initialColumns}  {...this.props} showCalendar={true} 
              rowData={this.state.active?this.props.adminDetails.awaitingPatients :this.props.adminDetails.bookedPatients}></Presentation>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BookedPatients