import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import moment from 'moment'
import DatePicker from 'react-datepicker';
import Footer from "../../elements/footer";
import Header from "../../elements/header";
import HelpIcon from "../../assets/Images/svg/Help_icon.svg"
import HelpSection from '../../components/Help/index'
import { Button, Popover, PopoverHeader, PopoverBody, OverlayTrigger } from "react-bootstrap";
import appConstants from '../../util/constants'
const mapStateToProps = state => ({
    userDetails: state.userDetails.data
})
const mapDispatchToProps = dispatch => ({
    dispatch: dispatch,

})
class PatientLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nhsnumber: '',
            dob: '',
            redirect: false,
            authError: false,
            startDate: '',
            HasUrlToken: false,
            isDevice: window.matchMedia("(max-width: 768px)").matches
        };
    }
    componentWillMount() {
        var urltoken = sessionStorage.getItem('token');
        if (urltoken) {
            this.setState({ HasUrlToken: true });
        }
        const handler = e => this.setState({ isDevice: e.matches });
        window.matchMedia("(max-width: 768px)").addListener(handler);
    }

    handleKeyPress = e => {
        //  var curval = e.key;
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
            return false;
        }
        var value = e.target.value;
        var curchr = value.length;
        if (curchr === 3 || curchr === 7) {
            e.target.value += "-";
        }
    }

    handleEmailChange = event => {
        this.setState({ nhsnumber: event.target.value });
    };

    handleChange = (date) => {
        this.setState({ startDate: date });
    }

    userValidation(nhsnumber, dob) {
        var nhsnum = this.props.userDetails.NHSNum;
        var dateofbirth = this.props.userDetails.DOB;

        if (nhsnumber === nhsnum && dateofbirth === moment.parseZone(dob).format('DD/MM/YYYY')) {
            this.setState({ redirect: true });
            localStorage.setItem('isvalidpatinet', true);
            localStorage.setItem('isLoggedIn', true);
            this.renderRedirect();
        }
        else {
            window.localStorage.setItem('isvalidpatinet', false);
            this.setState({ authError: true });
        }

    }
    handleSubmit = event => {
        event.preventDefault();
        const nhsnumber = event.target["inputNhsNumber"].value;
        const dob = this.state.startDate;
        if ((!nhsnumber && nhsnumber === "") || (!dob && dob === "")) {
            this.setState({ authError: true });
            return false;
        }
        else {
            this.userValidation(nhsnumber, dob)
        }
    };

    renderRedirect = () => {
        if (this.props.userDetails && this.props.userDetails.UserType === "PAT") {
            this.props.history.push('/Appointment');
        }
    };

    render() {
        const popover = (
            <Popover id="popover-basic" className="popupBody">
                <Popover.Title as="h1" className="popupHeading">Your NHS Number</Popover.Title>
                <Popover.Content>
                    You can find your NHS number in most documents or letters sent to you by the NHS, such as:
                    <ul>
                        <li>Prescriptions</li>
                        <li>Test results</li>
                        <li>Hospital referral letters</li>
                        <li>Appointment letters</li>
                    </ul>
                    <hr />
                    You can find your NHS number online by logging in to:
                    <ul>
                        <li>The NHS App (you'll need to register if you have not used it before)</li>
                        <li>Some GP online services</li>
                        <li>Your GP surgery</li>
                    </ul>
                    <hr />
                    <p> You can call your GP surgery and ask them what your NHS number</p>
                    <p>You may need to prove your identity over the phone or visit your GP surgery with proof of identity. </p>
                    {/* <input type="button" value="close" className="med-btn-primary btn-block"></input> */}
                </Popover.Content>
            </Popover>
        );
        return (
            <React.Fragment>
                <Header></Header>
                <div id="wrapper"></div>
                <div className="container patientContainer ">

                    <div className=" med-login mx-auto mt-5">
                        {this.state.HasUrlToken ?
                            <React.Fragment>
                                <div className="row"><h1 className="col-lg-12">Log in</h1></div>
                                <div className="row py-3"><div className="col-lg-12">Welcome to Medefer, {this.props.userDetails.UserName}.<p> Please enter your details to log in and manage your appointments.</p></div></div>
                                {this.state.authError &&
                                    <div className="row">
                                        <div className="col-lg-12 med-text-danger">
                                            <p>Sorry, but we do not recognise the details you entered, please check and try again.</p>
                                            <p>If you are still need help logging in today speak to our helpdesk on 08000 112 113.</p>
                                        </div>
                                    </div>
                                }
                                <form method="post" onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12"> <label >Your NHS Number</label></div>
                                        <div className="col-lg-12"> <input autocomplete="off" placeholder="___-___-____" onKeyPress={this.handleKeyPress} maxLength="12" data-cs-mask="" data-hj-suppress="" type="text"
                                            className={"form-control "} id="inputNhsNumber"
                                            autoFocus required /></div>
                                        <div className="col-lg-12 text-center my-2">
                                            <OverlayTrigger rootClose="true" trigger="click" placement={this.state.isDevice?"bottom":"right"} overlay={popover}>
                                                <a href="#">
                                                    <img src={HelpIcon} />  Need help finding this?</a>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12"><label>Your date of birth</label></div>
                                        <div className="col-lg-12">
                                            <DatePicker autocomplete="off"
                                                selected={this.state.startDate}
                                                onChange={this.handleChange}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                maxDate={new Date()}
                                                placeholderText="dd / mm / yyyy"
                                            /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 my-3">

                                            <input className="med-btn-primary btn-block" type="submit" value="Log In"></input>
                                        </div>
                                    </div>
                                </form>
                            </React.Fragment>
                            :
                            <div>
                                <h1>Sorry</h1>
                                <div className="mt-5">
                                    <p>{appConstants.DIRECT_URL_ACCESS}</p>
                                    <HelpSection></HelpSection>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Footer></Footer>
            </React.Fragment >
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PatientLogin)