import React from 'react';
import moment from 'moment'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import * as dates from 'date-arithmetic'

import { Navigate } from 'react-big-calendar'
import appSettings from '../../../AppSettings.json'
const noOfDays = appSettings.noOfDays;
const navigationDays = noOfDays + 1;

export default class MyWeek extends React.Component {
    render() {
        let { date } = this.props;
        let range = MyWeek.range(date);
        if (this.props.bindCountOfBookings) {
            this.props.bindCountOfBookings(date, 'week');
        }
        return <TimeGrid {...this.props} range={range} eventOffset={20} />
    }
}
MyWeek.range = date => {
     const now = date ? new Date(date) : new Date().setHours(0, 0, 0, 0);
    const monday = new Date(now);
    let startdate=monday.setDate(monday.getDate() - monday.getDay() + 1);
    var convertedDate=moment(startdate).format("MM/DD/YYYY"); 
    //var convertedDate=moment(date).format("MM/DD/YYYY");
    let start =  new Date(convertedDate);
    let end = dates.add(start, noOfDays, 'day');
    let current = start
    let range = [];
    while (dates.lte(current, end, 'day')) {
        range.push(current)
        current = dates.add(current, 1, 'day')
    }
    return range
}

MyWeek.navigate = (date, action) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return dates.add(date, -navigationDays, 'day')
        case Navigate.NEXT:
            return dates.add(date, navigationDays, 'day')
        default:
            return date
    }
}

MyWeek.title = date => {
    // return `My awesome week: ${date.toLocaleDateString()}`
    const now = date ? new Date(date) : new Date().setHours(0, 0, 0, 0);
    const monday = new Date(now);
    let start=monday.setDate(monday.getDate() - monday.getDay() + 1);
    var convertedDate=moment(start).format("MM/DD/YYYY"); 
       return moment.parseZone(convertedDate).format('DD-MMM-YY') + ' to ' + moment.parseZone(dates.add(convertedDate, 6, 'day')).format('DD-MMM-YY');
}