import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  loader: state.loader
})

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch
})

class Loading extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.shouldShowSpinner = this.shouldShowSpinner.bind(this)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps
  }
  shouldShowSpinner() {
    var IsPatientSelfService = false;
    if (window.location.pathname.indexOf('Appointment') >= 0 || window.location.pathname.indexOf('PatientLogin') >= 0) {
      IsPatientSelfService = true;
    }
    const data = this.props.loader;
    if (data && data.isFetching) {
      window.parent.postMessage(true, '*');
      return IsPatientSelfService;
    }
    window.parent.postMessage(false, '*');
    return false;
  }
  render() {
    return this.shouldShowSpinner() ? (
      <React.Fragment>
        
          <React.Fragment>
            <div className="popup-overlay"></div>
            <div className="loaderGif"></div>
          </React.Fragment>
        
        {/* <div className="popup-overlay"></div>
        <div className="loaderGif"></div> */}
      </React.Fragment>
    ) : ''
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading)
