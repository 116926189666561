import actions from '../../store/actions/constants'

export default function regAvailabilityReducer(state = {}, action) {
  switch (action.type) {
    case actions.FETCH_REGAVAILAIBILITY_SUCCESS:
      return {
        ...state,
        regAvailability: action.payload,
      }
    case actions.RESET_REGAVAILAIBILITY:
      return {
        ...state,
        regAvailability: null,
      }
    case actions.GET_ALL_REGISTRAR_PATIENT_BOOKING_SUCCESS:
      return {
        ...state,
        registrarBookings: action.payload
      }
    case actions.RESET_REGISTRAR_PATIENT_BOOKING:
      return {
        ...state,
        registrarBookings: action.payload
      }
    default:
      return state;
  }
}
