import { combineReducers } from 'redux';
import regAvailabilityReducer from '../../pages/RegAvailability/regAvailability.reducer'
import loginReducer from '../../pages/Login/login.reducer'
import patientReducer from '../../pages/Appointment/appointment.reducer'
import loadingReducer from '../../components/Container/Loading/loding.reducer'
import adminReducer from '../../pages/Admin/admin.reducer'

const rootreducer = combineReducers({
    regAvailability: regAvailabilityReducer,
    userDetails: loginReducer,
    patinetSlots: patientReducer,
    loader: loadingReducer,
    admin: adminReducer
})

export default rootreducer