//EMAILSIGNUP
const FETCH_REGAVAILAIBILITY = 'FETCH_REGAVAILAIBILITY'
const FETCH_REGAVAILAIBILITY_SUCCESS = 'FETCH_REGAVAILAIBILITY_SUCCESS'
const UPDATE_REGAVAILABILITY = 'UPDATE_REGAVAILABILITY'
const RESET_REGAVAILAIBILITY = 'RESET_REGAVAILAIBILITY'
const AUTH_USER = 'AUTH_USER'
const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS'
const GET_PATIENT_USER_SLOTS = 'GET_PATIENT_USER_SLOTS'
const GET_PATIENT_USER_SLOTS_SUCCESS = 'GET_PATIENT_USER_SLOTS_SUCCESS'
const IS_FETCHING = 'IS_FETCHING'
const FETCHING_DONE = 'FETCHING_DONE'
const GET_CANCELLED_APPOINTMENTS = 'GET_CANCELLED_APPOINTMENTS'
const GET_CANCELLED_APPOINTMENTS_SUCCESS = 'GET_CANCELLED_APPOINTMENTS_SUCCESS'
const GET_AVAILABLESLOT_USERS = 'GET_AVAILABLESLOT_USERS'
const GET_AVAILABLESLOT_USERS_SUCCESS = 'GET_AVAILABLESLOT_USERS_SUCCESS'
const UPDATE_ADMIN_AUTO_MAP_REGISTRAR = 'UPDATE_ADMIN_AUTO_MAP_REGISTRAR'
const UPDATE_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS = 'UPDATE_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS'
const RESET_CANCELLED_APPOINTMENTS = 'RESET_CANCELLED_APPOINTMENTS'
const RESET_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS = 'RESET_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS'
const PATIENT_VIEW = 'PATIENT_VIEW'

const GET_ALL_PENDING_PATINET_BOOKINGS = 'GET_ALL_PENDING_PATINET_BOOKINGS'
const GET_ALL_PENDING_PATINET_BOOKINGS_SUCCESS = 'GET_ALL_PENDING_PATINET_BOOKINGS_SUCCESS'
const RESET_ALL_BOOKED_PATIENTS = 'RESET_ALL_BOOKED_PATIENTS'

const GET_ALL_BOOKED_PATIENTS = 'GET_ALL_BOOKED_PATIENTS'
const GET_ALL_BOOKED_PATIENTS_SUCCESS = 'GET_ALL_BOOKED_PATIENTS_SUCCESS'
const RESET_ALL_PENDING_PATINET_BOOKINGS = 'RESET_ALL_PENDING_PATINET_BOOKINGS'

const GET_ALL_AWAITING_BOOKED_PATIENTS = 'GET_ALL_AWAITING_BOOKED_PATIENTS'
const GET_ALL_AWAITING_BOOKED_PATIENTS_SUCCESS = 'GET_ALL_AWAITING_BOOKED_PATIENTS_SUCCESS'
const RESET_ALL_AWAITING_BOOKED_PATIENTS = 'RESET_ALL_AWAITING_BOOKED_PATIENTS'

const GET_ALL_CLERKED_PATIENTS = 'GET_ALL_CLERKED_PATIENTS'
const GET_ALL_CLERKED_PATIENTS_SUCCESS = 'GET_ALL_CLERKED_PATIENTS_SUCCESS'
const RESET_ALL_CLERKED_PATIENTS = 'RESET_ALL_CLERKED_PATIENTS'

const GET_ALL_REGISTRAT_DNA = 'GET_ALL_REGISTRAT_DNA'
const GET_ALL_REGISTRAT_DNA_SUCCESS = 'GET_ALL_REGISTRAT_DNA_SUCCESS'
const RESET_ALL_REGISTRAT_DNA = 'RESET_ALL_REGISTRAT_DNA'

const GET_ALL_PATIENT_DNA = 'GET_ALL_PATIENT_DNA'
const GET_ALL_PATIENT_DNA_SUCCESS = 'GET_ALL_PATIENT_DNA_SUCCESS'
const RESET_ALL_PATIENT_DNA = 'RESET_ALL_PATIENT_DNA'

const GET_ALL_REGISTRAR_PATIENT_BOOKING = "GET_ALL_REGISTRAR_PATIENT_BOOKING"
const GET_ALL_REGISTRAR_PATIENT_BOOKING_SUCCESS = "GET_ALL_REGISTRAR_PATIENT_BOOKING_SUCCESS"
const RESET_REGISTRAR_PATIENT_BOOKING = "RESET_REGISTRAR_PATIENT_BOOKING"

const GET_USER_LIST = "GET_USER_LIST"
const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS"

const UPDATE_CANCELED_APPOINTMENT = "UPDATE_CANCELED_APPOINTMENT"
const RESET_UPDATE_CANCELED_APPOINTMENT = "RESET_UPDATE_CANCELED_APPOINTMENT"
const UPDATE_CANCELED_APPOINTMENT_SUCCESS = "UPDATE_CANCELED_APPOINTMENT_SUCCESS"

export default {

  IS_FETCHING,
  FETCHING_DONE,

  FETCH_REGAVAILAIBILITY,
  FETCH_REGAVAILAIBILITY_SUCCESS,
  UPDATE_REGAVAILABILITY,
  RESET_REGAVAILAIBILITY,
  AUTH_USER,
  AUTH_USER_SUCCESS,
  GET_PATIENT_USER_SLOTS,
  GET_PATIENT_USER_SLOTS_SUCCESS,
  GET_CANCELLED_APPOINTMENTS,
  GET_CANCELLED_APPOINTMENTS_SUCCESS,
  GET_AVAILABLESLOT_USERS,
  GET_AVAILABLESLOT_USERS_SUCCESS,
  UPDATE_ADMIN_AUTO_MAP_REGISTRAR,
  UPDATE_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS,
  RESET_CANCELLED_APPOINTMENTS,
  RESET_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS,
  PATIENT_VIEW,

  GET_ALL_PENDING_PATINET_BOOKINGS,
  GET_ALL_PENDING_PATINET_BOOKINGS_SUCCESS,
  RESET_ALL_PENDING_PATINET_BOOKINGS,

  GET_ALL_BOOKED_PATIENTS,
  GET_ALL_BOOKED_PATIENTS_SUCCESS,
  RESET_ALL_BOOKED_PATIENTS,

  GET_ALL_AWAITING_BOOKED_PATIENTS,
  GET_ALL_AWAITING_BOOKED_PATIENTS_SUCCESS,
  RESET_ALL_AWAITING_BOOKED_PATIENTS,

  GET_ALL_CLERKED_PATIENTS,
  GET_ALL_CLERKED_PATIENTS_SUCCESS,
  RESET_ALL_CLERKED_PATIENTS,

  GET_ALL_REGISTRAT_DNA,
  GET_ALL_REGISTRAT_DNA_SUCCESS,
  RESET_ALL_REGISTRAT_DNA,

  GET_ALL_PATIENT_DNA,
  GET_ALL_PATIENT_DNA_SUCCESS,
  RESET_ALL_PATIENT_DNA,

  GET_ALL_REGISTRAR_PATIENT_BOOKING,
  GET_ALL_REGISTRAR_PATIENT_BOOKING_SUCCESS,
  RESET_REGISTRAR_PATIENT_BOOKING,

  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,

  UPDATE_CANCELED_APPOINTMENT,
  RESET_UPDATE_CANCELED_APPOINTMENT,
  UPDATE_CANCELED_APPOINTMENT_SUCCESS
}