import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';    
// import Header from '../Header/Header';
import { userService } from '../../service/UserService';
// import settings from '../AppSettings.json'

import actions from '../../store/actions/constants'
import { connect } from 'react-redux'
import fetch from '../../service/Fetch/fetchApi'
import urls from '../../util/urls'

const mapStateToProps = state => ({
    regAvailability: state.regAvailability.data,
    userDetails: state.userDetails.data
})

const mapDispatchToProps = dispatch => ({
    UserValidation: (payload) => {
        dispatch({
            type: actions.AUTH_USER,
            payload
        });
    },
    dispatch: dispatch,

})

class Login extends Component {
    constructor(props) {
        super(props);
        localStorage.clear();
        this.state = {
            usertype: null,
            loading: false,
            error: '',
            redirect: false,
            authError: false,
            isLoading: false
        };
    }

    userValidation(username, password) {
        // this.props.dispatch({
        //     type:actions.IS_FETCHING
        // })
        fetch(`${urls.AuthUser}`,
            {
                "UserName": username,
                "Password": password
            },
            actions.AUTH_USER)
            .then(response => {
                if (response.Token) {
                    this.props.dispatch({
                        type: actions.AUTH_USER_SUCCESS,
                        payload: response
                    });
                    this.setState({ redirect: true, isLoading: false, usertype: response.UserType });

                    localStorage.setItem('token', response.Token);
                    localStorage.setItem('userid', response.UserId);
                    localStorage.setItem('usertype', response.UserType);
                    localStorage.setItem('isLoggedIn', true);

                    if (response.UserType === "SR") {
                        this.props.history.push('/RegAvalability');
                       // return <Redirect to='/RegAvalability' />
                    }
                    else if (response.UserType === "MedeferAdmin") {
                        this.props.history.push('/Admin')
                       // return <Redirect to='/Admin' />
                    }
                    else if (response.UserType === "PAT") {
                        this.props.history.push('/Appointment')
                        //return <Redirect to='/Appointment' />
                    }
                    else {
                        this.props.history.push('/Login')
                        //return <Redirect to='/Login' />
                    }
                }
            }).catch((error) => {
                this.setState({ error, loading: false })
                this.setState({ authError: true, isLoading: false, usertype: null });
                console.log(error);
                userService.clearall();

            }).then(()=>{
                this.props.dispatch({
                    type:actions.FETCHING_DONE
                })
            })
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        const username = event.target["inputEmail"].value
        const password = event.target["inputPassword"].value
        if ((!username && username === "") || (!password && password === "")) {
            return false;
        }
        else {
            this.userValidation(username, password)
        }
    };
    

    renderRedirect = () => {
        if (this.state.redirect) {
            if (this.state.usertype === "SR") {
                return <Redirect to='/RegAvalability' />
            }
            else if (this.state.usertype === "MedeferAdmin") {
                return <Redirect to='/Admin' />
            }
            else if (this.state.usertype === "PAT") {
                return <Redirect to='/Appointment' />
            }
            else {
                return <Redirect to='/Login' />
            }
        }
    };

    render() {
        const isLoading = this.state.isLoading;
        return (
            <div className="container">
                <div className="card card-login mx-auto mt-5">
                    <div className="card-header">Medefer Login</div>
                    <div className="card-body">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label htmlFor="inputEmail">Email address</label>
                                    <input className={"form-control " + (this.state.authError ? 'is-invalid' : '')} id="inputEmail" placeholder="Email address" type="text" name="email" autoFocus required />
                                    <div className="invalid-feedback">
                                        Please provide a valid Email.
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label htmlFor="inputPassword">Password</label>
                                    <input type="password" className={"form-control " + (this.state.authError ? 'is-invalid' : '')} id="inputPassword" placeholder="Password" name="password" required />
                                    <div className="invalid-feedback">
                                        Please provide a valid Password.
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="med-btn-primary" type="submit" disabled={this.state.isLoading ? true : false}>Login &nbsp;&nbsp;&nbsp;
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                            <span></span>
                                        )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* {this.renderRedirect()} */}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

