import React, { Component } from 'react';
// import faker from "faker";

import MyDataGrid from './MyDataGrid'
import actions from '../../../store/actions/constants'
class CancelRequest extends Component {
  constructor(props) {
    super(props);
    this.props = props;

  }
  UNSAFE_componentWillMount() {
    this.props.dispatch({
      type: actions.IS_FETCHING
    });
    this.props.getCancelledAppointments();
  }
  render() {

    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            {this.props.adminDetails && this.props.adminDetails.canceledAppointment &&
              <MyDataGrid {...this.props} rowData={this.props.adminDetails.canceledAppointment}></MyDataGrid>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default CancelRequest
