import React from 'react';

import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
        // <div
        //     role="tabpanel"
        //     hidden={value !== index}
        //     id={`full-width-tabpanel-${index}`}
        //     aria-labelledby={`full-width-tab-${index}`}
        //     {...other}
        // >
        //     {value === index && (
        //         <Box p={2}>
        //             <Typography>{children}</Typography>
        //         </Box>
        //     )}
        // </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};