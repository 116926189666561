import settings from '../AppSettings.json'
import moment from 'moment'
import { apiCall } from './Fetch/fetch';
import utility from '../util/utility'

var domainURL = utility.getUrlConfig(process.env.REACT_APP_DOMAIN);

function GetDate(date) {
    return moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss');
}
function GetUserAvailability(UserId) {
    var urlvalue = domainURL + "/api/Appointment/GetUserAvailability/";
    return apiCall.fetchApiCall(urlvalue, { "UserId": UserId });
}
function GetPatientUserSlots(UserId, ReferralId) {
    var urlvalue = domainURL + "/api/Appointment/GetPatientUserSlots/";
    return apiCall.fetchApiCall(urlvalue, { "PatientId": UserId, ReferralId: ReferralId });
}

function GetPatientAppointment(UserId, ReferralId) {
    var urlvalue = domainURL + "/api/Appointment/GetPatientAppointment/";
    return apiCall.fetchApiCall(urlvalue, { "PatientId": UserId, ReferralId: ReferralId });
}

function InsertUserAvailability(StartDate, EndDate, UserId, CreatedBy) {
    var bodyval = {
        "StartDate": GetDate(StartDate),
        "EndDate": GetDate(EndDate),
        "UserId": UserId,
        "CreatedBy": CreatedBy
    };
    var urlvalue = domainURL + "/api/Appointment/InsertUserAvailability/";
    return apiCall.fetchApiCall(urlvalue, bodyval);
}


function InsertPatientAppointment(StartDate, EndDate, ReferralId, CreatedBy, oldBookingId, ISPCT, RegistrarName, 
    registrarEmailAddress, RegistrarAvailabilityId) {
    var bodyval = {
        "StartDate": GetDate(StartDate),
        "EndDate": GetDate(EndDate),
        "ReferralId": ReferralId,
        "CreatedBy": CreatedBy,
        "OldPatientBookingId": oldBookingId,
        "ISPCT": ISPCT ? ISPCT : false,
        "RegistrarName": RegistrarName ?? "",
        "RegistrarEmailAddress" :registrarEmailAddress ?? "",
        "RegistrarAvailabilityId":RegistrarAvailabilityId
    };
    var urlvalue = domainURL + "/api/Appointment/InsertPatientAppointment/";
    return apiCall.fetchApiCall(urlvalue, bodyval);
}
function UpdateUserAvailability(AvailBookingID, PatientBookingID, StartDate, EndDate, Remarks, ModifiedBy, ReferralId, RegistrarName) {
    var bodyval = {
        "AvailBookingID": AvailBookingID,
        "PatientBookingID": PatientBookingID,
        "StartDate": GetDate(StartDate),
        "EndDate": GetDate(EndDate),
        "Remarks": Remarks,
        "ModifiedBy": ModifiedBy,
        ReferralId: ReferralId ?? 0,
        RegistrarName:RegistrarName??''
    };
    var urlvalue = domainURL + "/api/Appointment/UpdateUserAvailability/";
    return apiCall.fetchApiCall(urlvalue, bodyval);
}
function UpdatePatientAppointment(PatientBookingID, AvailBookingID, PatientId, Remarks, ModifiedBy, ReferralId, 
    ISPCT,startDate, endDate, RegistrarName, RegistrarEmail) {
    var bodyval = {
        "PatientBookingID": PatientBookingID,
        "AvailBookingID": AvailBookingID,
        "PatientId": PatientId,
        "Remarks": Remarks,
        "ModifiedBy": ModifiedBy,
        "ReferralId": ReferralId,
        "ISPCT": ISPCT ? ISPCT : false,
        StartDate: startDate,
        EndDate: endDate,
        RegistrarName:RegistrarName,
        RegistrarEmail : RegistrarEmail
    };
    var urlvalue = domainURL + "/api/Appointment/UpdatePatientAppointment/";
    return apiCall.fetchApiCall(urlvalue, bodyval);
}


export const AppontmentService = {
    GetUserAvailability,
    GetPatientUserSlots,
    GetPatientAppointment,
    InsertUserAvailability,
    InsertPatientAppointment,
    UpdateUserAvailability,
    UpdatePatientAppointment
};
