import React from 'react';
import moment from 'moment'
import Month from 'react-big-calendar/lib/Month';
import * as dates from 'date-arithmetic'

import { Navigate } from 'react-big-calendar'

export default class MyMonth extends React.Component {
    render() {
        let { date } = this.props;
        if(this.props.bindCountOfBookings){
            this.props.bindCountOfBookings(date,'month');
        }
        return <Month {...this.props}  />
    }

}
// MyMonth.range = date => {
//     return [dates.startOf(date, 'day')];
// }

MyMonth.navigate = (date, action) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return dates.add(date, -1, 'month')
        case Navigate.NEXT:
            return dates.add(date, 1, 'month')
        default:
            return date
    }
   
}

MyMonth.title = date => {
    return moment(date).format("MMMM YYYY");
}