import React from "react";
import Presentation from '../bookedpatients/presentation'
import utility from '../../../../util/utility'

const initialColumns = [
    {
        key: "ReferralId",
        name: "Referral Id",
        locked: true,
        filterable: true,
        width: 90
    },
    {
        key: "StartDate",
        name: "Appointment Date",
        formatter: utility.DateTimeFormatter,
        width: 170
    },
    {
        key: "Name",
        name: "Name",
        filterable: true,
    },
    {
        key: "NhsNumber",
        name: "NHS Number",
        filterable: true,
        width: 120
    },
    {
        key: "CCGName",
        name: "CCG Name",
    },
    {
        key: "AdminNonMedicalNote",
        name: "Admin Notes"
    },
    {
        key: "RegistrarNonMedicalNote",
        name: "Registrar Notes"
    },
    {
        key: "RegistrarFullName",
        name: "Registrar Name"

    }


].map(c => ({ ...c, ...utility.defaultColumnProperties }));

class ClerkedPatients extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

    }
    componentDidMount() {
        this.props.isFetching();
        this.props.getAllClerkedPatinets()
        //this.props.fetchingDone();
    }
    UNSAFE_componentWillMount() {
        this.props.resetClerkedPatinets()
    }
    componentWillUnmount() {

    }
    render() {

        return (
            <React.Fragment>
                {this.props.adminDetails && this.props.adminDetails.clerkedpatients &&
                    <div className="card">
                        <div className="card-body">
                            <Presentation initialColumns={initialColumns} showCalendar={false} {...this.props} rowData={this.props.adminDetails.clerkedpatients}></Presentation>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default ClerkedPatients