import React from "react";
import ReactDataGrid from "react-data-grid";
import { Toolbar, Data } from "react-data-grid-addons";
import moment from 'moment'
import PatientCalendar from '../calendar'
import utility from '../../../../util/utility'
import Swal from "sweetalert2";
import constants from '../../../../util/constants'
import Pagination from '@material-ui/core/TablePagination';

const selectors = Data.Selectors;

class BookingPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            columns: this.props.initialColumns,
            rows: utility.getpaginateData(this.props.rowData, constants.ROWSPERPAGE, 0),
            pageNumber: 0,
            RowsPerPage: constants.ROWSPERPAGE,
            filters: {},
            selectedRows: [], //for row selection (column with checkboxes)
            showDataGrid: true,            
        };
        this.onRowClick = this.onRowClick.bind(this);
        this.showGrid = this.showGrid.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }
    handleChangeRowsPerPage = (event) => {
        var perpage = parseInt(event.target.value, 10);
        var newData = utility.getpaginateData(this.props.rowData, perpage, 0);
        this.setState({ RowsPerPage: perpage, rows: newData, pageNumber: 0 });

    };
    handleChangePage = (event, newPage) => {
        // setPage(newPage);
        var newData = utility.getpaginateData(this.props.rowData, this.state.RowsPerPage, newPage);
        this.setState({ pageNumber: newPage, rows: newData })
        // console.log(newPage);
    };
    componentDidMount() {
        //this.props.getAllPendingPatinetBookings();
        //this.props.fetchingDone();
        utility.sendHeight();
    }
    componentWillUnmount() {
        this.setState({
            initialColumns: []
        })
    }
    UNSAFE_componentWillMount() {

    }

    onGridSort = (sortColumn, sortDirection) => {
        const tmpCol = this.state.columns.find(col => col.key === sortColumn);
        let numberSorting = false;
        if (tmpCol.type === "integer" || tmpCol.type === "real")
            numberSorting = true; //default sorting is comparing strings, here we need number-sorting

        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                if (numberSorting) {
                    return a[sortColumn] - b[sortColumn];
                }
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                if (numberSorting) {
                    return b[sortColumn] - a[sortColumn];
                }
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            } else {
                return a.id > b.id ? 1 : -1; //restore to sorting by id
            }
        };

        var sortData = [...this.props.rowData].sort(comparer);
        var filteredData = utility.getpaginateData(sortData, this.state.RowsPerPage, 0);
        // this.setState(prevState => ({
        //     rows: [...prevState.rows].sort(comparer)
        // }));
        this.setState(prevState => ({
            rows: filteredData
        }));
    };

    getColumns() {
        return this.state.columns;
    }

    getRows(rows, filters) {
        return selectors.getRows({ rows, filters });
    }

    filteredRows = () => {
        if (Object.keys(this.state.filters).length === 0) {
            return this.getRows(this.state.rows, this.state.filters);
        }
        else {
            return this.getRows(this.props.rowData, this.state.filters);
        }
    };

    handleFilterChange = filter => {
        this.setState(prevState => {
            const newFilters = { ...prevState.filters };
            if (filter.filterTerm) {
                newFilters[filter.column.key] = filter;
            } else {
                delete newFilters[filter.column.key];
            }
            return {
                filters: newFilters,
                selectedRows: [] //clear selected (checkboxes column) rows when filtering
            };
        });
    };

    onClearFilters = () => {
        this.setState({
            filters: {},
            selectedRows: [] //if you have column with checkboxes then clear them all
        });
    };

    // Needed to have selected row even when sorting (select)
    onRowsSelected = rows => {
        this.setState(prevState => ({
            selectedRows: prevState.selectedRows.concat(rows.map(r => r.row.id)) //assuming id is the key of each row
        }));
    };
    // Needed to have selected row even when sorting (deselect)
    onRowsDeselected = rows => {
        let rowIndexes = rows.map(r => r.row.id); //assuming id is the key of each row

        this.setState(prevState => ({
            selectedRows: prevState.selectedRows.filter(
                i => rowIndexes.indexOf(i) === -1
            )
        }));
    };

    //Also onGridRowsUpdated should update only filtered rows:
    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        this.setState(prevState => {
            const rows = prevState.rows;
            const filtered = this.filteredRows();
            for (let i = fromRow; i <= toRow; i++) {
                const rows_index = rows.map(x => x.id).indexOf(filtered[i].id); //assuming "id" is the key of each row
                rows[rows_index] = { ...filtered[i], ...updated };
            }

            return {
                rows: rows
            };
        });
    };
    getValidFilterValues(rows, columnId) {
        return rows
            .map(r => r[columnId])
            .filter((item, i, a) => {
                return i === a.indexOf(item);
            });
    }
    onRowClick = ({ rowIdx, row }) => {
        if (rowIdx !== -1 && this.props.showCalendar) {
            this.setState({
                selectedRowData: row,
            })
            
            var mydate = moment(row.StartDate, 'DD/MM/YYYY HH:mm');
            let bookedCOntext =
                `<div class="row">
            <div class="col-5 text-left font-weight-bold">Appointment Date</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left">${mydate.format('DD/MM/YYYY HH:mm')}</div>
        </div>
      
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Speciality</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left ">${row.PatientSpeciality}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">NHS Number</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left">${row.NhsNumber}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Patient Name</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left">${row.Name}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Referral Id</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left">${row.ReferralId}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Mobile Telephone</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left">${row.PreferedMobile}</div>
        </div>
        <div class="row">
            <div class="col-5 text-left font-weight-bold">Registrar Name</div>
            <div class="col-1 text-center">:</div>
            <div class="col-6 text-left">${row.RegistrarFullName}</div>
        </div>`
            Swal.fire({
                title: constants.BOOKED_APPOINTMENT_HEADING,
                html: bookedCOntext,
                showCloseButton: true,
                showCancelButton: true,
                reverseButtons: true,
                buttonsStyling: false,
                showConfirmButton: true,
                confirmButtonText: 'Reschedule',
                cancelButtonText: 'Close',
                customClass: {
                    confirmButton: 'btn btn-primary mr-2',
                    cancelButton: 'btn btn-secondary mr-2'
                },
                onBeforeOpen: function () {
                    // Swal.getPopup().className=constants.POPUP_CLASSNAME
                    Swal.getContainer().className=constants.CONTAINER_CLASSNAME
                },
            })
                .then((result) => {
                    if (result.value) {
                        this.setState({
                            showView: true,
                            showModal: false,
                            showDataGrid: false
                        })
                    }
                })
        }
    }
    reschedule = () => {
        this.setState({
            showView: true,
            showDataGrid: false
        })
    }
    showGrid = () => {
        var isBooked= this.props.isBooked;
        if(isBooked){
            this.props.resetAllBookedPatinets();
            this.props.getAllBookedPatinets();
        }else{
            this.props.resetAllAwaitingPatinets();
            this.props.getAllAwaitingPatinets();
        }
        this.setState({
            showView: false,
        })
    }
    render() {

        return (
            <React.Fragment>
                {this.state.showView &&
                    <PatientCalendar backText={this.props.isBooked?' Back to Booked list':' Back to Awaiting List'} showGrid={this.showGrid} patientId={this.state.selectedRowData.PatientId} selectedRowData={this.state.selectedRowData} {...this.props}></PatientCalendar>
                }
                {this.state.showDataGrid &&
                    <div className="row">
                        <div className="col-sm-12">
                            <ReactDataGrid
                                // rowRenderer={RowRenderer}
                                onGridRowsUpdated={this.onGridRowsUpdated}
                                columns={this.getColumns()}
                                rowGetter={i => this.filteredRows()[i]}
                                rowsCount={this.filteredRows()?this.filteredRows().length:0}
                                onGridSort={this.onGridSort}
                                onAddFilter={this.handleFilterChange}
                                onClearFilters={this.onClearFilters}
                                toolbar={<Toolbar enableFilter={true} />}
                                enableCellSelect={true}
                                emptyRowsView={utility.EmptyGridRowView}
                                onRowClick={(rowId, row) => this.onRowClick({ row: row, rowIdx: rowId })}
                                minHeight={550}
                                headerRowHeight={utility.heightOfHeaderRow}
                                //   getCellActions={(column, row) => this.getCellActions(column, row)}
                                rowHeight={utility.heightOfRow}
                                getValidFilterValues={columnKey => this.getValidFilterValues(this.props.rowData, columnKey)}
                            />
                        </div>
                        <div className="text-center">
                            <Pagination
                                rowsPerPageOptions={constants.ROWPERPAGE_OPTIONS}
                                component="div"
                                count={this.props.rowData!=null?this.props.rowData.length:0}
                                rowsPerPage={this.state.RowsPerPage}
                                page={this.state.pageNumber}
                                colSpan={12}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default BookingPresentation