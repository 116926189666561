import React from 'react';
import moment from 'moment';
import appSettings from '../AppSettings.json'

// import $ from 'jquery'

const heightOfRow = 40;
const heightOfHeaderRow = 40;
const defaultColumnProperties = {
  sortable: true,
};
const DateFormatter = ({ value }) => {
  var mydate = moment(value, 'DD/MM/YYYY');
  var convertedDate = moment(mydate).format("MM/DD/YYYY");
  var formatDate = new Date(convertedDate).toLocaleString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return formatDate;
}
const DateTimeFormatter1 = ({ value }) => {
 
  let start = moment(value).format();
  var mydate2 =  moment(new Date(start), 'DD/MM/YYYY HH:mm');
  var convertedDate2=mydate2.format('DD/MM/YYYY HH:mm');


  // var mydate =  moment(new Date(value), 'DD/MM/YYYY HH:mm');
  // var convertedDate=mydate.format('DD/MM/YYYY HH:mm');
  // if (!value)
  //   return '';
  return convertedDate2;
}

const DateTimeFormatter = ({ value }) => {
  var mydate = moment(value, 'DD/MM/YYYY HH:mm');
  var convertedDate=mydate.format('DD/MM/YYYY HH:mm');
  if (!value)
    return '';
  return convertedDate;
}
const sendHeight = (value) => {
  if (window && window.parent.postMessage) {
    // replace #wrapper with element that contains 
    // actual page content
    var height = document.getElementById('root').offsetHeight;
    window.parent.postMessage(value ? value : height, '*');
  }
}
const EmptyGridRowView = () => {
  const message = "No records to show";
  return (
    <div
      style={{ textAlign: "center", backgroundColor: "#ddd", padding: "200px" }}
    >
      <h3>{message}</h3>
    </div>
  );
};

const getUrlConfig = (domain) => {
  var urls = appSettings.serviceUrls;  
  if(urls == null)
  {
    urls = appSettings.serviceURL;
  }
    return urls;
}

function getpaginateData(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  if (array == null) {
    return [];
  } else {
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  }
}
export default { getpaginateData, getUrlConfig, EmptyGridRowView, sendHeight,DateTimeFormatter1, DateFormatter, DateTimeFormatter, defaultColumnProperties, heightOfRow, heightOfHeaderRow }