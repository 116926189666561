import thunk from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware, compose } from 'redux';
import rootreducer from '../reducer/index';
import rootEpic from '../epic/index'
import appSettings from '../../AppSettings.json'

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware();
let initialState;
var composeEnhancers = compose;
if (typeof window !== 'undefined' ) {
  initialState = window.__PRELOADED_STATE__ || {}
  if (appSettings.environment !== "development") {
    composeEnhancers = compose
  } else {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  }
  delete window.__PRELOADED_STATE__
}
const store = createStore(
  rootreducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, epicMiddleware))
);
epicMiddleware.run(rootEpic);

export default store;
