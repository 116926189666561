import React, { Component } from 'react';
import { Calendar, Views } from 'react-big-calendar'
import moment from 'moment'
import { AppontmentService } from '../../../../service/Appointment';
import Swal from 'sweetalert2';
import MyWeek from '../../../../components/MyCalendar/MyWeek'
import CalendarToolbar from '../../../../components/MyCalendar/ToolBar/index'
import { ColoredDateCellWrapper, localizer } from '../../../../components/MyCalendar/Utils/util'
import { connect } from 'react-redux'
import actions from '../../../../store/actions/constants'

import { userService } from '../../../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import fetch from '../../../../service/Fetch/fetchApi'
import urls from '../../../../util/urls'
import constants from '../../../../util/constants'
import utility from '../../../../util/utility'

import appSetting from '../../../../AppSettings.json'

const mapStateToProps = state => ({
    regAvailability: state.regAvailability.data,
    userDetails: state.userDetails.data
})

const mapDispatchToProps = dispatch => ({
    getRegistrarAvailability: (payload) => {
        dispatch({
            type: actions.FETCH_REGAVAILAIBILITY,
            payload
        });
    },
    dispatch: dispatch,

})
class PatientCalendar extends Component {

    constructor(props) {
        super(props);
        this.usertype = localStorage.getItem('usertype');
        this.selectedDate = new Date();
        this.isBooked = true;
        this.handlesaveappointment = this.handlesaveappointment.bind(this);
        this.state = {
            employees: [],
            UserId: localStorage.getItem('userid'),
            toDashboard: false,
            isLoading: true,
            events: [],
            userid: null,
            usertype: null,
            selectedDate: new Date(),
            isBooked: false,
            appointmenturl: null,
            patinetheader: null,
            patinetinfo: {
                patientBookingID: null,
                availBookingID: null,
                referralId: null,
                patientId: null,
                nhsNumber: null,
                patientName: null,
                mobileTelephone: null,
                startDate: null,
                endDate: null,
                statusCode: null,
                statusDesc: null,
                ccgid: null,
                ccgName: null,
                speciality: null,
                spcialistname: null,
                splhospitalname: null,
                gpName: null,
                gphospitalname: null,

            },

        };


    }

    UNSAFE_componentWillMount() {
        utility.sendHeight(3300);
        var data=this.props.selectedRowData;
        this.GetPatinetAppointment(this.props.patientId, data.ReferralId);
        this.GetPatientUserSlotsList(this.props.patientId, data.ReferralId);
    }

    GetRegistrarAvailability(UserId) {
        AppontmentService.GetUserAvailability(UserId)
            .then(
                result => {
                    if (result) {
                        var eventslist = [];
                        result.forEach((el, index) => {
                            let start = moment(el.StartDate).format()
                            let end = moment(el.EndDate).format()
                            eventslist.push({
                                id: el.ReferralId,
                                title: el.StatusDesc,
                                start: new Date(start),
                                end: new Date(end),
                                desc: el.PatientName,
                                ReferralId: el.ReferralId,
                                PatientName: el.PatientName ?? '',
                                NhsNumber: el.NhsNumber ?? '',
                                MobileTelephone: el.MobileTelephone ?? ''
                            });
                        })
                        this.setState({ events: eventslist });
                    }
                },
                error => {
                    this.setState({ toDashboard: true });
                    console.log(error);
                }
            );
    };

    getDropdownValue=(res)=>{
        var str = ` <select id="registrarDDl" >`;  
        res.forEach(value => {
           str= str.concat('<option value="' + value.AvailBookingID + '">' + value.Name + '</option>');
        });
        str=str.concat(' </select>')
        return str;
    }

    handlesaveappointment = (event, info) => {
        let patinetCreatedContent =
            `<div class="row">
                    <div class="col-5 text-left font-weight-bold">Referral ID</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.ReferralId}</div>
                </div>
                <div class="row">
                    <div class="col-5 text-left font-weight-bold">Patient Name</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left ">${event.PatientName}</div>
                </div>
                <div class="row">
                    <div class="col-5 text-left font-weight-bold">NHS Number</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left ">${event.NhsNumber}</div>
                </div>
                <div class="row">
                    <div class="col-5 text-left font-weight-bold">Mobile Telephone</div>
                    <div class="col-1 text-center">:</div>
                    <div class="col-6 text-left">${event.MobileTelephone}</div>
                </div>
                <div class="row">
                    <div class="col-5 text-left font-weight-bold">Date</div>
                    <div class="col-1">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                </div>
                <div class="row">
                    <div class="col-5 text-left font-weight-bold">Time</div>
                    <div class="col-1">:</div>
                    <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                </div>
                
            `;
        if (new Date() <= event.start) {
            if (event.title === "Available") {
                let data = { "StartDate": event.start.toLocaleString('en'), "EndDate": event.end.toLocaleString('en'), "ReferralId":event.ReferralId }
                fetch(`${urls.GetUserAvailableSlots}`,
                    data,
                    actions.AUTH_USER)
                    .then(response => {
                        if (response) {
                            var registrarName = response[0] && response[0] ? response[0].Name : ''
                            var emailAddress = response[0] && response[0]? response[0].EmailAddress : '';
                            var AvailBookingID= response[0] && response[0] ? response[0].AvailBookingID : ''; 
                            this.setState({ registrarList:response, registrarName: registrarName , EmailAddress: emailAddress, AvailBookingID:AvailBookingID});
                            
                            if(response.length>0){
                            patinetCreatedContent =
                                `<div class="row">
                                <div class="col-5 text-left font-weight-bold">Referral ID</div>
                                <div class="col-1 text-center">:</div>
                                <div class="col-6 text-left">${event.ReferralId}</div>
                            </div>
                            <div class="row">
                                <div class="col-5 text-left font-weight-bold">Patient Name</div>
                                <div class="col-1 text-center">:</div>
                                <div class="col-6 text-left ">${event.PatientName}</div>
                            </div>
                            <div class="row">
                                <div class="col-5 text-left font-weight-bold">NHS Number</div>
                                <div class="col-1 text-center">:</div>
                                <div class="col-6 text-left ">${event.NhsNumber}</div>
                            </div>
                            <div class="row">
                                <div class="col-5 text-left font-weight-bold">Mobile Telephone</div>
                                <div class="col-1 text-center">:</div>
                                <div class="col-6 text-left">${event.MobileTelephone}</div>
                            </div>
                            <div class="row">
                                <div class="col-5 text-left font-weight-bold">Date</div>
                                <div class="col-1">:</div>
                                <div class="col-6 text-left">${moment.parseZone(event.start).format('DD-MM-YYYY')}</div>
                            </div>
                            <div class="row">
                                <div class="col-5 text-left font-weight-bold">Time</div>
                                <div class="col-1">:</div>
                                <div class="col-6 text-left">${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')}</div>
                            </div>
                            <div class="row">
                                <div class="col-5 text-left font-weight-bold">Registrars</div>
                                <div class="col-1">:</div>
                                <div class="col-6 text-left">
                              ${this.getDropdownValue(response)}
                              </div>
                            </div>
                            
                        `;

                            Swal.fire({
                                title: info.referralId ?
                                    constants.RESCHEDULE_CONFIRM_HEADING : constants.CONFIRM_HEADING,
                                html: patinetCreatedContent,
                                showCloseButton: true,
                                showCancelButton: true,
                                reverseButtons: true,
                                buttonsStyling: false,
                                onBeforeOpen: function () {
                                    Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                                },
                                customClass: {
                                    confirmButton: 'btn btn-primary mr-2',
                                    cancelButton: 'btn btn-secondary mr-2'
                                },
                                confirmButtonText: info.referralId ? "Reschedule" : "Book",
                                cancelButtonText: 'Close',
                            })
                                .then((ok) => {
                                    if (ok.value) {
                                        var registrarList=this.state.registrarList;
                                        var selectedRegistrar=document.getElementById('registrarDDl').value;
                                        var selectedReeg=registrarList.filter(x=>x.AvailBookingID==selectedRegistrar)[0];
                                        var registrarName=selectedReeg.Name;
                                        var registrarEmailAddress= selectedReeg.EmailAddress;
                                        var AvailBookingID= selectedReeg.AvailBookingID;
                                        let oldBookingId = (this.state.patinetinfo && this.state.patinetinfo.patientBookingID) ?? null
                                         this.InsetPatientAppointment(event.start, event.end, event.id, this.state.UserId, oldBookingId, registrarName, registrarEmailAddress, AvailBookingID)
                                    }
                                });

                            }
                            else {
                                Swal.fire('No Registrars available',`Sorry Registrars are unavailable or booked with other referrals for ${moment.parseZone(event.start).format('HH:mm')} - ${moment.parseZone(event.end).format('HH:mm')} time slot.`);
                            }

                        }

                    })


            }
            else {
                let eventStateData = this.state.patinetinfo
                let patinetBookedContent =
                    `<div class="row">
                        <div class="col-5 text-left font-weight-bold">Appointment Date</div>
                        <div class="col-1 text-center">:</div>
                        <div class="col-6 text-left">${moment.parseZone(eventStateData.startDate).format('LLL')}</div>
                    </div>
                    <div class="row">
                        <div class="col-5 text-left font-weight-bold">Doctor Name</div>
                        <div class="col-1 text-center">:</div>
                        <div class="col-6 text-left ">${eventStateData.spcialistname + ',  ' + eventStateData.splhospitalname}</div>
                    </div>
                    <div class="row">
                        <div class="col-5 text-left font-weight-bold">Speciality</div>
                        <div class="col-1 text-center">:</div>
                        <div class="col-6 text-left ">${eventStateData.speciality}</div>
                    </div>
                    <div class="row">
                        <div class="col-5 text-left font-weight-bold">NHS Number</div>
                        <div class="col-1 text-center">:</div>
                        <div class="col-6 text-left">${eventStateData.nhsNumber}</div>
                    </div>
                    <div class="row">
                        <div class="col-5 text-left font-weight-bold">Patient Name</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-left">${eventStateData.patientName}</div>
                    </div>
                    <div class="row">
                        <div class="col-5 text-left font-weight-bold">Referral Id</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-left">${eventStateData.referralId}</div>
                    </div>
                    <div class="row">
                        <div class="col-5 text-left font-weight-bold">Mobile Telephone</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-left">${eventStateData.mobileTelephone}</div>
                    </div>
                    <div class="row">
                        <div class="col-5 text-left font-weight-bold">Registrar Name</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-left">${eventStateData.RegistrarName}</div>
                    </div>
                    `;
                Swal.fire({
                    title: constants.BOOKED_APPOINTMENT_HEADING,
                    text: constants.BOOKED_APPOINTMENT_SUBHEADING,
                    html: patinetBookedContent,
                    showCloseButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    buttonsStyling: false,
                    onBeforeOpen: function () {
                        Swal.getPopup().className = constants.POPUP_CLASSNAME
                        Swal.getContainer().className = constants.CONTAINER_CLASSNAME

                    },
                    customClass: {
                        confirmButton: 'btn btn-danger mr-2',
                        cancelButton: 'btn btn-secondary mr-2'
                    },
                    confirmButtonText: constants.CANCEL_APPOINTMENT_BTN_TEXT,
                    cancelButtonText: 'Close',
                }).then((ok) => {
                    if (ok.value) {

                        Swal.fire({
                            title: constants.CANCELLATION_COMMENT_TITLE,
                            text: constants.CANCELLATION_COMMENT_TEXT,
                            input: 'text',
                            inputAttributes: {
                                autocapitalize: 'off'
                            },
                            showCloseButton: true,
                            customClass: {
                                confirmButton: 'btn btn-primary mr-2',
                                cancelButton: 'btn btn-secondary mr-2 '
                            },
                            reverseButtons: true,
                            buttonsStyling: false,
                            cancelButtonText: 'Close',
                            showCancelButton: true,
                            confirmButtonText: 'Ok',
                            showLoaderOnConfirm: true,
                            onBeforeOpen: function () {
                                Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                            },
                            preConfirm: (result) => {
                                if (result !== '' && result.length >= 10) {
                                    this.DeleteAppointment(this.state.patinetinfo.patientBookingID, this.state.patinetinfo.availBookingID,this.state.patinetinfo.patientId, result, this.state.UserId, this.state.patinetinfo.referralId,this.state.patinetinfo.startDate,this.state.patinetinfo.endDate, this.state.patinetinfo.RegistrarName, this.state.patinetinfo.RegistrarEmail)
                                    this.setState({ isBooked: false });
                                }
                                else {
                                    var message = constants.COMMENTS_REQUIRED
                                    if (result !== '' && result.length <= 9) {
                                        message = constants.COMMENTS_MINIMUM;
                                    }
                                    Swal.showValidationMessage(
                                        message
                                    )
                                }
                            },
                            allowOutsideClick: () => !Swal.isLoading()
                        })


                    }
                })
            }
        }
        else {
            Swal.fire({
                title: "Appointment Confirmation",
                html: patinetCreatedContent,
                showCloseButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary mr-2',
                },
                buttonsStyling: false,
                onBeforeOpen: function () {
                    Swal.getContainer().className = constants.CONTAINER_CLASSNAME
                },
            })
        }
    };
    eventPropGetter = (event, start, end, isSelected) => {
        let newStyle = 'createdstatus';

        if (event.title === "Available") {
            newStyle = 'inprogressstatus';
        }

        return {
            className: newStyle,
            style: ""
        };
    };

    GetPatinetAppointment(UserId, ReferralId) {
        AppontmentService.GetPatientAppointment(UserId, ReferralId)
            .then(
                result => {
                    if (result.length > 0 && (result[0].StatusCode === "2" || result[0].StatusCode === "4")) {
                        let start = moment(result[0].StartDate).format();
                        let end = moment(result[0].EndDate).format();
                        var item = {
                            patientBookingID: result[0].PatientBookingID,
                            availBookingID: result[0].AvailBookingID,
                            referralId: result[0].ReferralId,
                            patientId: result[0].PatientId,
                            nhsNumber: result[0].NhsNumber,
                            patientName: result[0].PatientName,
                            mobileTelephone: result[0].MobileTelephone,
                            startDate: new Date(start),
                            endDate: new Date(end),
                            statusCode: result[0].StatusCode,
                            statusDesc: result[0].StatusDesc,
                            ccgName: result[0].CCGName,
                            ccgid: result[0].CCGId,
                            speciality: result[0].Speciality,
                            spcialistname: result[0].SpcialistName,
                            splhospitalname: result[0].SplHospitalName,
                            gpName: result[0].GPName,
                            gphospitalname: result[0].GPHospitalName,

                            id: result[0].ReferralId,
                            title: result[0].StatusDesc,
                            start: new Date(start),
                            end: new Date(end),
                            desc: result[0].PatientName,
                            ReferralId: result[0].ReferralId,
                            PatientName: result[0].PatientName ?? '',
                            NhsNumber: result[0].NhsNumber ?? '',
                            MobileTelephone: result[0].MobileTelephone ?? '',
                            RegistrarName: result[0].RegistrarName ?? '',
                            RegistrarEmail: result[0].RegistrarEmail ?? ''
                        }
                        if (this.state.patinetheader != null) {
                            this.setState({ patinetheader: "NHS Number : " + result[0].NhsNumber + " | Patient Name : " + result[0].PatientName + " | Referral Id : " + result[0].ReferralId })
                        }
                        this.setState({ isBooked: true });
                        this.setState({ patinetinfo: item });
                        var eventslist = this.state.events;
                        eventslist.push(item)
                        this.setState({ events: eventslist })
                        this.setState({ isLoading: this.state.isBooked ? false : true })
                    }
                    else {
                        this.setState({ isBooked: false });
                    }
                },
                error => {
                    this.setState({ toDashboard: true });
                    this.setState({ isLoading: false })
                    console.log(error);
                }
            );
    };
    GetPatientUserSlotsList(UserId, ReferralId) {
        this.props.dispatch({
            type: actions.IS_FETCHING
        })
        AppontmentService.GetPatientUserSlots(UserId, ReferralId)
            .then(
                result => {
                    if (result) {
                        var eventslist = this.state.events;
                        result.forEach((el, index) => {
                            let start = moment(el.StartDate).format()
                            let end = moment(el.EndDate).format()
                            eventslist.push({
                                id: el.ReferralId,
                                title: el.StatusDesc,
                                start: new Date(start),
                                end: new Date(end),
                                desc: el.PatientName,
                                ReferralId: el.ReferralId,
                                PatientName: el.PatientName ?? '',
                                NhsNumber: el.NhsNumber ?? '',
                                MobileTelephone: el.MobileTelephone ?? '',
                                SpcialistName: el.SpcialistName

                            });
                        })

                        //eventslist.push(item)
                        this.setState({ events: eventslist });
                        this.setState({ isLoading: false })
                        if (result.length > 0) {
                            this.setState({ patinetheader: "NHS Number : " + result[0].NhsNumber + " | Patient Name : " + result[0].PatientName + " | Referral Id : " + result[0].ReferralId })
                        }
                    }
                    this.setState({
                        slotuserid: UserId
                    })
                    this.props.dispatch({
                        type: actions.FETCHING_DONE
                    })
                },
                error => {
                    this.setState({ toDashboard: true });
                    this.setState({ isLoading: false })
                    this.setState({
                        slotuserid: UserId
                    })
                    this.props.dispatch({
                        type: actions.FETCHING_DONE
                    })
                }
            );
    };

    GetAppointmentURL(UserId, UserType, ReferralId, CurrentUser) {
        userService.GetPatientAppointmentURL(UserId, UserType, ReferralId, CurrentUser)
            .then(
                result => {
                    if (result.URL) {
                        this.setState({ appointmenturl: result.URL })
                    }
                    else {
                        this.setState({ appointmenturl: null })
                    }
                },
                error => {
                    this.setState({ appointmenturl: error })
                }
            );
    };
    InsetPatientAppointment(StartDate, EndDate, ReferralId, CreatedBy, oldBookingId, RegistrarName, registrarEmailAddress, AvailBookingID) {
        this.props.dispatch({
            type: actions.IS_FETCHING
        })
        AppontmentService.InsertPatientAppointment(StartDate, EndDate, ReferralId, CreatedBy, oldBookingId, true, RegistrarName, registrarEmailAddress, AvailBookingID)
            .then(
                result => {
                    if (result) {
                        this.setState({
                            events: []
                        })
                        this.GetPatinetAppointment(this.state.slotuserid,ReferralId);
                        this.GetPatientUserSlotsList(this.state.slotuserid, ReferralId);
                        this.props.dispatch({
                            type: actions.FETCHING_DONE
                        })
                    }
                },
                error => {
                    this.setState({ toDashboard: true });
                    console.log(error);
                    this.props.dispatch({
                        type: actions.FETCHING_DONE
                    })
                }
            );
    };
    DeleteAppointment(PatientBookingID, AvailBookingID,PatientId, Remarks, ModifiedBy, referralId,startDate, endDate,RegistrarName, RegistrarEmail) {
        AppontmentService.UpdatePatientAppointment(PatientBookingID, AvailBookingID,PatientId, Remarks, ModifiedBy, referralId, true, startDate, endDate, RegistrarName, RegistrarEmail)
            .then(
                result => {
                    if (result) {
                        this.setState({
                            events: [],
                            patinetinfo : {}
                        })
                        this.GetPatinetAppointment(this.state.slotuserid, referralId);
                        this.GetPatientUserSlotsList(this.state.slotuserid, referralId);
                    }
                },
                error => {
                    this.setState({ toDashboard: true });
                    console.log(error);
                }
            );
    };


    render() {
        let formats={dateFormat:'dd',
        timeGutterFormat:'HH:mm'
            }
        return (<React.Fragment>
            <div className="card mb-3 ">
                <div className="card-body">
                    {/* <div className="row text-right">

                    </div> */}
                    <div className="row m-auto">
                        <div className="col-sm-9 text-left">
                            {this.state.patinetheader &&
                                <p className="card border-darkp-5 text-left card-header">{this.state.patinetheader}</p>
                            }
                        </div>
                        <div className="col-sm-3 text-right">
                            <button className="btn btn-danger" onClick={() => this.props.showGrid()}> <FontAwesomeIcon icon={faListAlt} />{this.props.backText ? this.props.backText : " Back To Booked Patient"}</button>
                        </div>
                    </div>
                </div>
                <div className="card-body patient">
                    {this.state.slotuserid &&
                        <React.Fragment>
                            {this.state.events && this.state.events.length > 0 ?
                                <Calendar
                                    events={this.state.events}
                                    views={{ week: MyWeek, day: true, month: true }}
                                    step={20}
                                    titleAccessor="patient"
                                    localizer={localizer}
                                    timeslots={1}
                                    defaultView={Views.WEEK}
                                    startAccessor="start"
                                    endAccessor="end"
                                    showMultiDayTimes={true}
                                    defaultDate={this.state.selectedDate}
                                    onSelectEvent={event => this.handlesaveappointment(event, this.state.patinetinfo)}
                                    dayLayoutAlgorithm={"no-overlap"}
                                    min={new Date(2000, 0, 1, appSetting.startTime, 0)}
                                    max={new Date(2100, 0, 1, appSetting.endTime, 59)}
                                    formats={formats}
                                    components={{
                                        timeSlotWrapper: ColoredDateCellWrapper,
                                        toolbar: CalendarToolbar
                                    }} s
                                    toolbar={true}
                                    eventPropGetter={(this.eventPropGetter)}
                                />
                                :
                                <div className="card border-dark p-3 text-center card-header">
                                    <p className="m-auto">
                                        Slots are not available for <span className="font-weight-bold font-weight-italic">{this.state.UserName ?? 'selected Patient'}
                                        </span>
                                    </p>
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
        );

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientCalendar)