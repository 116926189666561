import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Presentation from './presentation'

class MyCalendar extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }



    UNSAFE_componentWillMount() {
        this.UserId = this.props.userDetails.UserId;
        this.usertype = this.props.userDetails.UserType;
        // this.GetRegistrarAvailability(this.UserId);
        if (this.props.userDetails && this.props.userDetails.UserId) {
            this.props.isFetching();
            this.props.getRegistrarAvailability({ "UserId": this.props.userDetails.UserId });
        }
    }
    componentWillUnmount() {
        this.props.resetData();
    }

    render() {
        if (this.usertype === null) {
            return <Redirect to='/Login' />
        }
        else if (this.usertype === "MedeferAdmin") {
            return <Redirect to='/Admin' />
        }
        else if
            (this.usertype === "PAT") {

            var isvalid = localStorage.getItem('isvalidpatinet');
            if (isvalid === "true") {
                return <Redirect to='/Appointment' />
            }
            else {
                return <Redirect to='/PatientLogin' />
            }
        }
        else {
            return (
                <React.Fragment>
                    {this.props.registrarDetails && this.props.registrarDetails.regAvailability&&
                        <Presentation {...this.props}></Presentation>
                    }
                </React.Fragment>
            );
        }
    }
}


export default MyCalendar
