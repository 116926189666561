import actions from '../../../store/actions/constants'

export default function loadingReducer(state = {}, action) {
  switch (action.type) {
    case actions.IS_FETCHING:
      return {
        ...state,
        isFetching: true
      }
    case actions.FETCHING_DONE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state;
  }
}
