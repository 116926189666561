import React from 'react';
import moment from 'moment'
import Day from 'react-big-calendar/lib/Day';
import * as dates from 'date-arithmetic'
import { Navigate } from 'react-big-calendar'


export default class MyDay extends React.Component {
    render() {
        let { date } = this.props;
        if(this.props.bindCountOfBookings){
            this.props.bindCountOfBookings(date,'day');
        }
        return <Day {...this.props}  />
    }

}
MyDay.range = date => {
    return [dates.startOf(date, 'day')];
}

MyDay.navigate = (date, action) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return dates.add(date, -1, 'day')
        case Navigate.NEXT:
            return dates.add(date, 1, 'day')
        default:
            return date
    }
   
}

MyDay.title = date => {
    return moment(date).format("dddd, MMMM D");
}