const PATIENT_CLOSE_MESSAGE = "Thank you for contacting Medefer, please be available during your appointment.."
const PATIENT_CANCEL_MESSAGE = "The Medefer Patient Care Team has been notified and they will contact you to explain your treatment options."
const PATIENT_NO_SLOTS = "We are sorry, there are currently no available appointments available, however we expect further slots to become available soon. Check back at a later time, or call 08000 112 113 Mon-Fri 8am-6pm for assistance. Thank you."
const SLOT_IS_IN_ADMIN = "Slot is cancelled by the Registrar"
//"Your cancellation request is pending approval by the Patient Care Team. Please do not change your plans until this is approved."
const CONFIRM_HEADING = "Select Book to confirm this appointment"
const RESCHEDULE_CONFIRM_HEADING = "Click Reschedule to update this referral to the below appointment"
const CANCELLATION_COMMENT_TEXT = "Cancellation comments are mandatory."
const REGISTRAR_CANCELLATION_COMMENT_TITLE = "Are you sure you wish to request cancellation?"
const REGISTRAR_CANCELLATION_COMMENT_TEXT = "Please explain why you need to cancel this appointment:"
const CANCELLATION_COMMENT_TITLE = "Cancellation Comments"
const CANCEL_AVAILABILITY_BTN_TEXT = "Cancel Availability"
const CANCEL_APPOINTMENT_BTN_TEXT = "Cancel Appointment"
const REGISTRAR_CLOSE_APPOINTMENT_BTN_TEXT = "Cancel Request" 
const REGISTRAR_CONFIRM_APPOINTMENT_BTN_TEXT = "Confirm Request" 
const PATINET_CANCEL_POPUP = "Do you wish to cancel the Appointment"
const CANCEL_POPUP_TITLE = "Do you wish to cancel the availability"
const CANCLE_POPUP_PATIENT_TITLE_24 = "You cannot cancel this appointment as it is less than 24 hours away"
const CANCEL_SLOTS_WARNING_TIME = ""
const CANCLE_POPUP_PATIENT_TITLE = "This appointment has already been booked with a patient. Do you wish request this booking be cancelled?"
const CANCEL_REASSIGN_HEADING = "Appointment Cancelled by Registrar"
const CANCEL_REASSIGN_SUBHEADING = "Please select a new Registrar for this appointment using the dropdown below for the same Time slot. Otherwise find the Patient in the Pending List and contact them to Rebook for different Time slot."
const REG_AVAILABILITY = "Please confirm you are available for the below time?"
const BOOKED_APPOINTMENT_HEADING = "Booked appointment summary"
const BOOKED_APPOINTMENT_SUBHEADING = "Do you need to cancel this booked appointment?"
const COMMENTS_MINIMUM = "Please enter at least 10 characters"
const COMMENTS_REQUIRED = "Comments are required."
const OTHER_PLATFORM_SLOTS = " Grey color slots are booked with patients on other platform."
const BOOKED_TOOGLE = " Toogle here to see Booked and awaiting registrars. By Default Booked referrals will be loaded."
const CONTAINER_CLASSNAME = "swal2-container swal2-center swal2-backdrop-show swal2Container"
const POPUP_CLASSNAME = "swal2-popup swal2-modal swal2-show swal2Popup"
const ROWSPERPAGE = 10
const ROWPERPAGE_OPTIONS = [10, 25, 50]
const SITE_UNAVAILABLE ="The Medefer Patient Portal is not available at the moment, please try again later."
const LINK_EXPIRY_BOOKED="Your link to the Medefer Patient Portal has expired as you have completed or cancelled your appointment."
const DIRECT_URL_ACCESS="The Patient Portal can only be accessed via the link Medefer sent you by email/Sms."
const UK_MOBILE_REGEX=/^((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}$/;
const UK_TELEPHONE_REGEX=/^\d{11}$/;
const EMAIL_REGEX=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const REGISTRAR_AUTOASSIGN='This cancellation request has been been accepted.'
const REGISTRAR_NOT_AUTOASSIGN='This cancellation request has been sent to Medefer for consideration. You should receive an email with the decision within 24 hours.'
export default {
    REGISTRAR_AUTOASSIGN,
    REGISTRAR_NOT_AUTOASSIGN,
    REGISTRAR_CANCELLATION_COMMENT_TITLE,
    REGISTRAR_CANCELLATION_COMMENT_TEXT,
    REGISTRAR_CLOSE_APPOINTMENT_BTN_TEXT,
    REGISTRAR_CONFIRM_APPOINTMENT_BTN_TEXT,
    EMAIL_REGEX,
    UK_MOBILE_REGEX,
    UK_TELEPHONE_REGEX,
    SITE_UNAVAILABLE,
    LINK_EXPIRY_BOOKED,
    DIRECT_URL_ACCESS,
    PATINET_CANCEL_POPUP,
    ROWPERPAGE_OPTIONS,
    ROWSPERPAGE,
    POPUP_CLASSNAME,
    CONTAINER_CLASSNAME,
    OTHER_PLATFORM_SLOTS,
    BOOKED_TOOGLE,
    COMMENTS_MINIMUM,
    COMMENTS_REQUIRED,
    BOOKED_APPOINTMENT_SUBHEADING,
    BOOKED_APPOINTMENT_HEADING,
    REG_AVAILABILITY,
    PATIENT_CANCEL_MESSAGE,
    PATIENT_CLOSE_MESSAGE,
    PATIENT_NO_SLOTS,
    SLOT_IS_IN_ADMIN,
    CONFIRM_HEADING,
    RESCHEDULE_CONFIRM_HEADING,
    CANCELLATION_COMMENT_TEXT,
    CANCELLATION_COMMENT_TITLE,
    CANCEL_AVAILABILITY_BTN_TEXT,
    CANCEL_APPOINTMENT_BTN_TEXT,
    CANCEL_POPUP_TITLE,
    CANCLE_POPUP_PATIENT_TITLE,
    CANCLE_POPUP_PATIENT_TITLE_24,
    CANCEL_SLOTS_WARNING_TIME,
    CANCEL_REASSIGN_HEADING,
    CANCEL_REASSIGN_SUBHEADING
}