import React from "react";
import ReactDataGrid from "react-data-grid";
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import utility from '../../../util/utility';
import Pagination from '@material-ui/core/TablePagination';
import constants from '../../../util/constants'

const selectors = Data.Selectors;
const {
    // AutoCompleteFilter,
    // MultiSelectFilter,
    SingleSelectFilter
} = Filters;
const initialColumns = [
    {
        key: "ReferralId",
        name: "Referral Id",
        locked: true,
        filterable: true,
        width: 90
    },
    {
        key: "StartDate",
        name: "Appointment Date",
        formatter: utility.DateTimeFormatter,
        width: 130
    },
    {
        key: "Name",
        name: "Name",
        filterable: true,
    },
    {
        key: "NhsNumber",
        name: "NHS Number",
        filterable: true,
        width: 120,
    },
    {
        key: "DateOfBirth",
        name: "Date Of Birth",
        formatter: utility.DateFormatter,
        width: 110
    },
    {
        key: 'PatientSpeciality',
        name: 'Speciality',
        filterable: true,
        filterRenderer: SingleSelectFilter
    },
    {
        key: "AdminNonMedicalNote",
        name: "Admin Notes"
    },
    {
        key: "RegistrarNonMedicalNote",
        name: "Registrar Notes"
    }


].map(c => ({ ...c, ...utility.defaultColumnProperties }));

class BookedPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            columns: initialColumns,
            rows: utility.getpaginateData(this.props.rowData, constants.ROWSPERPAGE, 0),
            RowsPerPage: constants.ROWSPERPAGE,
            filters: {},
            selectedRows: [], //for row selection (column with checkboxes)
            pageNumber: 0,
            userlist: [],
            showModal: false,
            modalData: {},
            showView: false
        };
        this.onRowClick = this.onRowClick.bind(this);
        this.showGrid = this.showGrid.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }
    handleChangeRowsPerPage = (event) => {
        var perpage = parseInt(event.target.value, 10);
        var newData = utility.getpaginateData(this.props.rowData, perpage, 0);
        this.setState({ RowsPerPage: perpage, rows: newData, pageNumber: 0 });

    };
    handleChangePage = (event, newPage) => {
        // setPage(newPage);
        var newData = utility.getpaginateData(this.props.rowData, this.state.RowsPerPage, newPage);
        this.setState({ pageNumber: newPage, rows: newData })
       
    };
    UNSAFE_componentWillMount() {
        //this.props.getAllPendingPatinetBookings();
        //this.props.fetchingDone();
        utility.sendHeight(1025);
    }

    onGridSort = (sortColumn, sortDirection) => {
        const tmpCol = this.state.columns.find(col => col.key === sortColumn);
        let numberSorting = false;
        if (tmpCol.type === "integer" || tmpCol.type === "real")
            numberSorting = true; //default sorting is comparing strings, here we need number-sorting

        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                if (numberSorting) {
                    return a[sortColumn] - b[sortColumn];
                }
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                if (numberSorting) {
                    return b[sortColumn] - a[sortColumn];
                }
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            } else {
                return a.id > b.id ? 1 : -1; //restore to sorting by id
            }
        };

        this.setState(prevState => ({
            rows: [...prevState.rows].sort(comparer)
        }));
    };

    getColumns() {
        return this.state.columns;
    }

    getRows(rows, filters) {
        return selectors.getRows({ rows, filters });
    }

    filteredRows = () => {
        if (Object.keys(this.state.filters).length === 0) {
            return this.getRows(this.state.rows, this.state.filters);
        }
        else {
            return this.getRows(this.props.rowData, this.state.filters);
        }
    };

    handleFilterChange = filter => {
        this.setState(prevState => {
            const newFilters = { ...prevState.filters };
            if (filter.filterTerm) {
                newFilters[filter.column.key] = filter;
            } else {
                delete newFilters[filter.column.key];
            }
            return {
                filters: newFilters,
                selectedRows: [] //clear selected (checkboxes column) rows when filtering
            };
        });
    };

    onClearFilters = () => {
        this.setState({
            filters: {},
            selectedRows: [] //if you have column with checkboxes then clear them all
        });
    };

    // Needed to have selected row even when sorting (select)
    onRowsSelected = rows => {
        this.setState(prevState => ({
            selectedRows: prevState.selectedRows.concat(rows.map(r => r.row.id)) //assuming id is the key of each row
        }));
    };
    // Needed to have selected row even when sorting (deselect)
    onRowsDeselected = rows => {
        let rowIndexes = rows.map(r => r.row.id); //assuming id is the key of each row

        this.setState(prevState => ({
            selectedRows: prevState.selectedRows.filter(
                i => rowIndexes.indexOf(i) === -1
            )
        }));
    };

    //Also onGridRowsUpdated should update only filtered rows:
    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        this.setState(prevState => {
            const rows = prevState.rows;
            const filtered = this.filteredRows();
            for (let i = fromRow; i <= toRow; i++) {
                const rows_index = rows.map(x => x.id).indexOf(filtered[i].id); //assuming "id" is the key of each row
                rows[rows_index] = { ...filtered[i], ...updated };
            }

            return {
                rows: rows
            };
        });
    };
    getValidFilterValues(rows, columnId) {
        return rows
            .map(r => r[columnId])
            .filter((item, i, a) => {
                return i === a.indexOf(item);
            });
    }


    hideModal = () => {
        this.setState({ showModal: false });
    };

    getCellActions(column, row) {
        if (column.key === 'modify' && row.UserType === "Registrar") {
            return [
                {
                    icon: <FontAwesomeIcon color='#3766bd' className="modifyIcon" icon={faEdit} />,
                    callback: () => {
                        this.props.getAvilableUserSlots({ "StartDate": row.StartDate, "EndDate": row.EndDate })
                        this.setState({ showModal: true, modalData: row });
                    }
                }
            ];

        }
        else if (column.key === 'modify' && row.UserType === "Patient") {
            return [
                {
                    icon: <FontAwesomeIcon color='#468671' className="modifyIcon" icon={faEye} />,
                    callback: () => {
                        this.setState({ showModal: true, modalData: row });
                    }
                }
            ];
        }
    }

    onRowClick = ({ rowIdx, row }) => {
        if (rowIdx !== -1) {
            var url = window.location.ancestorOrigins[0] + '/Registrar/RespondReferral/' + row.ReferralId;
            window.top.location.replace(url)
            //window.location.href=url;
            // this.setState({
            //     showView: true,
            //     selectedRowData: row
            // })
        }
    }
    showGrid = () => {
        this.props.resetAllBookedPatinets();
        this.props.getAllBookedPatinets({ UserId: this.props.userDetails.UserId });
        this.setState({
            showView: false,
        })
    }
    SaveClerkingNotes = (dataObj, type, rowData) => {

    }
    render() {

        return (
            <React.Fragment>
                {!this.state.showView &&
                    //     <PatientView SaveClerkingNotes={this.SaveClerkingNotes} {...this.props} showGrid={this.showGrid} viewData={this.state.selectedRowData}></PatientView>
                    // :
                    <div className="row">
                        <div className="col-sm-12">
                            <ReactDataGrid
                                // rowRenderer={RowRenderer}
                                onGridRowsUpdated={this.onGridRowsUpdated}
                                columns={this.getColumns()}
                                rowGetter={i => this.filteredRows()[i]}
                                rowsCount={this.filteredRows().length}
                                onGridSort={this.onGridSort}
                                onAddFilter={this.handleFilterChange}
                                onClearFilters={this.onClearFilters}
                                toolbar={<Toolbar enableFilter={true} />}
                                enableCellSelect={true}
                                emptyRowsView={utility.EmptyGridRowView}
                                onRowClick={(rowId, row) => this.onRowClick({ row: row, rowIdx: rowId })}
                                minHeight={550}
                                headerRowHeight={utility.heightOfHeaderRow}
                                //   getCellActions={(column, row) => this.getCellActions(column, row)}
                                rowHeight={utility.heightOfRow}
                                getValidFilterValues={columnKey => this.getValidFilterValues(this.props.rowData, columnKey)}
                            />
                        </div>
                        <div className="text-center">
                            <Pagination
                                rowsPerPageOptions={constants.ROWPERPAGE_OPTIONS}
                                component="div"
                                count={this.props.rowData.length}
                                rowsPerPage={this.state.RowsPerPage}
                                page={this.state.pageNumber}
                                colSpan={12}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default BookedPresentation