import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '500px',
        [theme.breakpoints.down('xs')]: {
            height: '500px',
            top: '5%',
            position: 'absolute',
            width: '70%',
            overflow: 'auto',
        },
    },
}));
export default function EmailUpdate(props) {
    const classes = useStyles();
    const [isValid, setisValid] = React.useState(false);
    const [emailId, setEmailId] = React.useState('');
    const onSubmit = () => {
        props.submitEmailForm(emailId)
        
    }
    const handleKeyPress = e => {
        
        var value = e.target.value;
        var regex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        if (regex.test(value)) {
            setisValid(true);
            setEmailId(value);
        }
        else {
            setEmailId(value)
            setisValid(false);
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <div className="row">

                            <h3 className="col-lg-12 font-weight-bold">Use a different email address</h3>
                        </div>
                        <div className="my-3">
                            <div className="row">
                                <div className="col-sm-12">If you would prefer us to contact you using a different email address, please enter it here. </div>
                            </div>

                            <div className="row my-3 ">
                                <div className="col-lg-12">
                                    <label>Your preferred email address</label>
                                </div>
                                <div className="col-lg-12">
                                    <input onChange={handleKeyPress} type="text" min="0" placeholder="Email address" className="form-control" ></input>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-lg-12">We will use this email address for all communications to you about your care in future?</div>
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-12 text-center med-btn-group">
                                <button className="btn med-btn-default mr-3" onClick={props.handleClose}>Cancel</button>
                                <button className="btn med-btn-primary" disabled={!isValid} onClick={onSubmit}>Change email address</button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
