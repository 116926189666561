import { combineEpics, ofType } from 'redux-observable'
import actions from '../../store/actions/constants'
import urls from '../../util/urls'
import { mergeMap } from 'rxjs/operators';
import fetch from '../../service/Fetch/fetchApi';
//import constructResponse from './index'
const getCancelAppointmentFulfilled = payload => (
  { type: actions.GET_CANCELLED_APPOINTMENTS_SUCCESS, payload }
);
const getAvailableSlotUsersEpicFulfilled = payload => (
  { type: actions.GET_AVAILABLESLOT_USERS_SUCCESS, payload }
);

const UpdateAdminAutomMapEpicFulfilled = payload => (
  { type: actions.UPDATE_ADMIN_AUTO_MAP_REGISTRAR_SUCCESS, payload }
);

const GetAllPatientBookingsEpicFulfilled = payload => (
  { type: actions.GET_ALL_PENDING_PATINET_BOOKINGS_SUCCESS, payload }
);

const GetAllBookedPatientsEpicFulfilled = payload => (
  { type: actions.GET_ALL_BOOKED_PATIENTS_SUCCESS, payload }
);

const GetAllBookedAwaitingPatientsEpicFulfilled = payload => (
  { type: actions.GET_ALL_AWAITING_BOOKED_PATIENTS_SUCCESS, payload }
)

const GetAllClerkedPatientsEpicFulfilled = payload => (
  { type: actions.GET_ALL_CLERKED_PATIENTS_SUCCESS, payload }
);
const GetAllRegistratDNAEpicFulfilled = payload => (
  { type: actions.GET_ALL_REGISTRAT_DNA_SUCCESS, payload }
);
const GetAllPatientDNAEpicFulfilled = payload => (
  { type: actions.GET_ALL_PATIENT_DNA_SUCCESS, payload }
);

const GetUserListEpicFulfilled = payload => (
  { type: actions.GET_USER_LIST_SUCCESS, payload }
);

const updateRegistrarAppointmentEpicFulfilled = payload => (
  { type: actions.GET_CANCELLED_APPOINTMENTS, }
);

const updateRegistrarAppointmentEpic = (action$, store) =>
  action$.pipe(ofType(actions.UPDATE_CANCELED_APPOINTMENT)
    , mergeMap(action =>
      fetch(`${urls.UpdateRegistrarAppointment}`, action.payload, action.type)
        .then(response => updateRegistrarAppointmentEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));


const getCancelAppointmentEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_CANCELLED_APPOINTMENTS)
    , mergeMap(action =>
      fetch(`${urls.GetCancelledAppointments}`, action.payload, action.type)
        .then(response => getCancelAppointmentFulfilled(response))
        .catch(() => console.log('errors'))
    ));

const getAvailableSlotUsersEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_AVAILABLESLOT_USERS)
    , mergeMap(action =>
      fetch(`${urls.GetUserAvailableSlots}`, action.payload, action.type)
        .then(response => getAvailableSlotUsersEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));

const UpdateAdminAutomMapEpic = (action$, store) =>
  action$.pipe(ofType(actions.UPDATE_ADMIN_AUTO_MAP_REGISTRAR)
    , mergeMap(action =>
      fetch(`${urls.UpdateAdminAutoMapRegistrar}`, action.payload, action.type)
        .then(response => UpdateAdminAutomMapEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));
const GetAllPatientBookingsEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_ALL_PENDING_PATINET_BOOKINGS)
    , mergeMap(action =>
      fetch(`${urls.GetAllPendingPatients}`, action.payload, action.type)
        .then(response => GetAllPatientBookingsEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));
const GetAllBookedPatientsEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_ALL_BOOKED_PATIENTS)
    , mergeMap(action =>
      fetch(`${urls.GetAllBookedPatients}`, action.payload, action.type)
        .then(response => GetAllBookedPatientsEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));
const GetAllBookedAwaitingPatientsEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_ALL_AWAITING_BOOKED_PATIENTS)
    , mergeMap(action =>
      fetch(`${urls.GetAllBookedAwaitingPatients}`, action.payload, action.type)
        .then(response => GetAllBookedAwaitingPatientsEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));

const GetAllClerkedPatientsEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_ALL_CLERKED_PATIENTS)
    , mergeMap(action =>
      fetch(`${urls.GetAllClerkedPatients}`, action.payload, action.type)
        .then(response => GetAllClerkedPatientsEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));
const GetAllRegistratDNAEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_ALL_REGISTRAT_DNA)
    , mergeMap(action =>
      fetch(`${urls.GetAllRegistrarDNA}`, action.payload, action.type)
        .then(response => GetAllRegistratDNAEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));
const GetAllPatientDNAEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_ALL_PATIENT_DNA)
    , mergeMap(action =>
      fetch(`${urls.GetAllPatientDNA}`, action.payload, action.type)
        .then(response => GetAllPatientDNAEpicFulfilled(response))
        .catch(() => console.log('errors'))
    ));
const GetUserListEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_USER_LIST)
    , mergeMap(action =>
      fetch(`${urls.GetUserList}`, action.payload, action.type)
        .then(response => GetUserListEpicFulfilled(constructResponse(response)))
        .catch(() => console.log('errors'))
    ));

const constructResponse = (response) => {
  let eventslist = [];
  response.forEach(el => {
    var resp = {
      value: el.UserId,
      title: el.UserId + " - " + el.UserName,
      UserName: el.UserName
    }
    eventslist.push(resp);
  });
  return eventslist;
}
export default combineEpics(getCancelAppointmentEpic, getAvailableSlotUsersEpic,
  UpdateAdminAutomMapEpic, GetAllPatientBookingsEpic, GetAllBookedPatientsEpic,
  GetAllClerkedPatientsEpic, GetAllRegistratDNAEpic, GetAllPatientDNAEpic, GetUserListEpic,
  GetAllBookedAwaitingPatientsEpic, updateRegistrarAppointmentEpic)