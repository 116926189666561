import React, { Component } from 'react';

export default class Footer extends Component {

    render() {
        const year= new Date().getFullYear();
        return (
            <footer className="sticky-footer py-2">
                <div className="container text-center">&#169; 2013 - {year}  Medefer Limited, Unit 1, 89-93 Fonthill Road, London N4 3JH.  Registered in England and Wales, Company No. 08770754. All rights reserved.</div>
            </footer>
        );
    }
}
