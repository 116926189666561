import React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '500px',
        [theme.breakpoints.down('xs')]: {
            height: '500px',
            top: '5%',
            position: 'absolute',
            width: '70%',
            overflow: 'auto',
        },
    },
}));
export default function ChangeAppointment(props) {
    const classes = useStyles();
 
    // const onSubmit = () => {
    //     props.submitEmailForm(emailId)
        
    // }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <div className="row">

                            <h3 className="col-lg-12 font-weight-bold">Change the time and date of this appointment?</h3>
                        </div>
                        <div className="my-3">
                            <div className="row">
                                <div className="col-6">Date </div>
                                <div className="col-6 font-weight-bold">{moment.parseZone(props.patInfo.startDate).format('DD-MM-YYYY')} </div>
                            </div>

                            <div className="row">
                                <div className="col-6">Time </div>
                                <div className="col-6 font-weight-bold">{moment.parseZone(props.patInfo.startDate).format('HH:mm A')} - {moment.parseZone(props.patInfo.endDate).format('HH:mm A')} </div>
                            </div>
                            <div className="row pt-2 pb-4">
                                <div className="col-lg-8 text-center">
                                    <a href="#" onClick={props.cancelAppointment}>Cancel appointment </a></div>
                            </div>                           
                        </div>
                        <div className="row">
                            <div className="col-12 text-center med-btn-group">
                                <button className="btn med-btn-default mr-3" onClick={props.handleClose}>Keep appointment</button>
                                <button className="btn med-btn-primary" onClick={props.reScheduleAppointment}>Reschedule appointment</button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
