import React from "react";
import Presentation from '../bookedpatients/presentation'
import Switch from '@material-ui/core/Switch';
import utility from '../../../../util/utility'

const initialColumns = [
    {
        key: "ReferralId",
        name: "Referral Id",
        llocked: true,
        filterable: true,
        width: 90
    },
    {
        key: "StartDate",
        name: "Appointment Date",
        formatter: utility.DateTimeFormatter,
        width: 170
    },
    {
        key: "Name",
        name: "Name",
        filterable: true,
    },
    {
        key: "NhsNumber",
        name: "NHS Number",
        filterable: true,
        width: 120
    },
    {
        key: "CCGName",
        name: "CCG Name",
    },
    {
        key: "AdminNonMedicalNote",
        name: "Admin Notes"
    },
    {
        key: "RegistrarNonMedicalNote",
        name: "Registrar Notes"
    },
    {
        key: "RegistrarFullName",
        name: "Registrar Name"
    }
].map(c => ({ ...c, ...utility.defaultColumnProperties }));

class PatinetDNA extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = { active: false }
    }
    componentDidMount() {
        this.props.isFetching();
        this.props.getAllPatinetDNA()
        //this.props.fetchingDone();
    }
    // UNSAFE_componentWillMount() {
    //     this.props.resetPatinetDNA()
    // }

    componentWillUnmount() {
        this.props.resetPatinetDNA();
        this.props.resetRegistrarDNA();
    }
    handleToggle = (toogle) => {
        this.props.isFetching();
        if (toogle) {
            this.props.resetPatinetDNA();
            this.props.resetRegistrarDNA();
            this.props.getAllRegistrarDNA();
        }
        else {
            this.props.resetRegistrarDNA();
            this.props.resetPatinetDNA();
            this.props.getAllPatinetDNA();
        }
        this.setState({ active: !this.state.active });
    };
    render() {

        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <label>PDNA
                                <Switch checked={this.state.active}
                                    onClick={() => this.handleToggle(!this.state.active)} color="primary"></Switch>
                                RDNA</label>
                            <small className="text-info font-weight-bold">    Toogle here to see Patient DNA and Registrar DNA Patients. By Default Patient DNA will be loaded</small>
                        </div>


                        {this.props.adminDetails && (this.props.adminDetails.patientDNA || this.props.adminDetails.registrarDNA) &&
                            <Presentation initialColumns={initialColumns} showCalendar={false} {...this.props}
                                rowData={this.state.active ? this.props.adminDetails.registrarDNA : this.props.adminDetails.patientDNA}></Presentation>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PatinetDNA