import actions from '../../store/actions/constants'

export default function loginReducer(state = {}, action) {
  switch (action.type) {
    case actions.AUTH_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
     
    default:
      return state;
  }
}
