import React from 'react'
import moment from 'moment'
import ChangeAppointment from '../Modal/changeAppointment'

class PatientBooked extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IsBooked: true,
            ShowChangeModal: false,
            IsRejectReasonValid: false
        }
        this.changeAppointmentClick = this.changeAppointmentClick.bind(this);
    }
    hideModal = () => {
        this.setState({ ShowChangeModal: false });
    };
    changeAppointmentClick = () => {
        this.setState({ ShowChangeModal: true })
    }
    cancelAppointment = () => {
        this.setState({ IsBooked: false, ShowChangeModal: false })
    }
    showBooked = () => {
        this.setState({ IsBooked: true })
    }
    reSchedule = () => {
        this.hideModal()
        this.props.reScheduleAppointment()
    }
    handleKeyPress = e => {

        var value = e.target.value;
        if (value.length >= 10) {
            this.setState({ IsRejectReasonValid: true });
        }
        else {
            this.setState({ IsRejectReasonValid: false });
        }
    }
    isSubmit = () => {
        var reason = document.getElementById('rejectReason');
        this.props.deleteappointment(reason.value);
    }
    render() {
        return (
            <React.Fragment>
                {this.state.ShowChangeModal &&
                    <ChangeAppointment reScheduleAppointment={this.reSchedule} cancelAppointment={this.cancelAppointment} handleClose={this.hideModal} patInfo={this.props.patInfo} {...this.props}></ChangeAppointment>
                }
                <div className="row pb-2">
                    <div className="col-12"><h1>{this.state.IsBooked ? "Appointment Confirmed" : "Cancel this appointment"}</h1></div>
                </div>
                {this.props.patInfo && this.state.IsBooked &&
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-2">
                                    <h2 className="col-sm-12">Appointment details</h2>
                                </div>
                                <div className="row ">
                                    <div className="col-4  mb-2">Appointment Date</div>
                                    <div className="col-8 font-weight-bold">{moment.parseZone(this.props.patInfo.startDate).format('LLLL')}</div>
                                </div>
                                <div className="row ">
                                    <div className="col-4 mb-2">Doctor Name</div>
                                    <div className="col-8 font-weight-bold">{this.props.patInfo.spcialistname + ',  ' + this.props.patInfo.splhospitalname}</div>
                                </div>
                                <div className="row ">
                                    <div className="col-4 mb-2">Speciality</div>
                                    <div className="col-8 font-weight-bold">{this.props.patInfo.speciality}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <a href="#" onClick={this.changeAppointmentClick} >Change this appointment</a>

                                    </div>
                                </div>
                                {/* <div className="row m-1">

                                    <div className="col-md-6 med-btn-group">
                                        <input type="button" onClick={this.props.deleteappointment} className={"btn med-btn-default mr-2"} value={constants.CANCEL_APPOINTMENT_BTN_TEXT}
                                        />
                                        <input type="button" onClick={this.props.reScheduleAppointment} className={"btn med-btn-primary"} value="Reschedule Appointment"
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="card my-3">
                            <div className="card-body">
                                <div className="row">
                                    <h2 className="col-sm-12 mb-2">Your details</h2>
                                </div>
                                <div className="row ">
                                    <div className="col-4 mb-2">Name</div>
                                    <div className="col-8 font-weight-bold">{this.props.patInfo.patientName}</div>
                                </div>
                                <div className="row ">
                                    <div className="col-4 mb-2">Medefer Referral Number</div>
                                    <div className="col-8 font-weight-bold">{this.props.patInfo.referralId}</div>
                                </div>
                                <div className="row ">
                                    <div className="col-4 mb-2">NHS Number</div>
                                    <div className="col-8 font-weight-bold">{this.props.patInfo.nhsNumber}</div>
                                </div>
                                <div className="row ">
                                    <div className="col-4 mb-2">Phone</div>
                                    <div className="col-4 font-weight-bold">{this.props.patInfo.mobileTelephone} </div>
                                    <a href="#" className="col-md-4" onClick={this.props.updatemobilenumber} >Use a different number</a>
                                </div>
                                <div className="row ">
                                    <div className="col-4 mb-2">Email address</div>
                                    <div className="col-4 font-weight-bold">{this.props.patInfo.emailId} </div>
                                    <a href="#" className="col-md-4" onClick={this.props.updateemailid} >Use a different address</a>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <h2 className="col-sm-12">What happens next?</h2>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">At your Appointment Time, the Medefer Doctor will call you on your phone number shown above. If you need to change this appointment time, you can return here to change it or speak to our helpdesk on 08000 112 113. These details will also be sent to you by email.</div>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                }
                {this.props.patInfo && !this.state.IsBooked &&
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-2">
                                    <h2 className="col-sm-12">Appointment details </h2>
                                </div>
                                <div className="row ">
                                    <div className="col-4  mb-2">Appointment Date</div>
                                    <div className="col-8 font-weight-bold">{moment.parseZone(this.props.patInfo.startDate).format('LLLL')}</div>
                                </div>
                                <div className="row ">
                                    <div className="col-4 mb-2">Doctor Name</div>
                                    <div className="col-8 font-weight-bold">{this.props.patInfo.spcialistname + ',  ' + this.props.patInfo.splhospitalname}</div>
                                </div>
                                <div className="row ">
                                    <div className="col-4 mb-2">Speciality</div>
                                    <div className="col-8 font-weight-bold">{this.props.patInfo.speciality}</div>
                                </div>

                            </div>
                        </div>
                        <div className="card my-3">
                            <div className="card-body">
                                <div className="row">
                                    <h2 className="col-sm-12 mb-2">Reason for cancelling</h2>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-12 mb-2">Please tell us why you need to cancel your appointment today in ten or more words.</div>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-12 mb-2"><textarea id="rejectReason" onChange={this.handleKeyPress} minLength="10" type="text"></textarea></div>
                                </div>

                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <h2 className="col-sm-12">What happens next?</h2>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">The Medefer Patient Care Team has been notified and they will contact you to explain your treatment options.</div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">

                            <div className="col-md-6 med-btn-group">
                                <input type="button" onClick={this.showBooked} className={"btn med-btn-default mr-2"} value={'Keep appointment'}
                                />
                                <input type="button" disabled={!this.state.IsRejectReasonValid} onClick={this.isSubmit} className={"btn med-btn-primary"} value="Cancel Appointment"
                                />
                            </div>
                        </div>

                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}
// }
export default PatientBooked