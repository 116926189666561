import React from 'react'
class PatientInfo extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }
    render() {
        return (
            <React.Fragment>
                {this.props.userDetails &&
                    <React.Fragment>
                        {/* <div className="card-body"> */}
                            <div className="row">
                                <div className="col-sm-3">
                                    {'Name'}
                                </div>
                                <div className="col-sm-6 font-weight-bold">
                                    {this.props.userDetails.UserName}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 ">
                                    {'NHS Number'}
                                </div>
                                <div className="col-sm-6 font-weight-bold">
                                    {this.props.userDetails.NHSNum}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 ">
                                    {'Speciality'}
                                </div>
                                <div className="col-sm-6 font-weight-bold">
                                    {this.props.userDetails.Speciality ?? ' N/A'}
                                </div>
                            </div>
                            {this.props.data.length > 0 &&
                                <div className="row pt-3 pb-4">
                                    <div className="col-sm-12">Please select a convenient appointment time from the calendar</div>
                                </div>
                            }

                        {/* </div> */}
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}
export default PatientInfo