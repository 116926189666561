const GetUserAvailability = '/api/Appointment/GetUserAvailability/'
const UpdateUserAvailability = "/api/Appointment/UpdateUserAvailability/";
const InsertPatinetAppointment = "/api/Appointment/InsertPatientAppointment/";
const AuthUser = "/api/User/UserAuth/";
const AuthTokenUser = "/api/User/UserAuthToken/";
const GetPatientUserSlots = "/api/Appointment/GetPatientUserSlots/"
const GetPatientAppointment = "/api/Appointment/GetPatientAppointment/"
const GetCancelledAppointments = "/api/Appointment/GetAllCancelledAppointments"
const GetUserAvailableSlots = "/api/Appointment/GetAvailableSlotUsers"
const UpdateAdminAutoMapRegistrar = "/api/Appointment/UpdateAadminAutoMapRegistrar"
const GetAllPendingPatients = "/api/Appointment/GetAllPendingPatients"
const GetAllBookedPatients = "/api/Appointment/GetAllBookedPatient"
const GetAllClerkedPatients = "/api/Appointment/GetAllClerkedPatients"
const GetAllPatientDNA = "/api/Appointment/GetAllPatientDNA"
const GetAllRegistrarDNA = "/api/Appointment/GetAllRegistrarDNA"
const SaveClerkingNotes = "/api/Appointment/SaveClerkingNotes"
const GetRegistrarBookedPatients = "/api/Appointment/GetRegistrarBookedPatients"
const SaveRegistrarPatients = "/api/Appointment/SaveRegistrarPatients"
const GetUserList = "/api/User/GetUserList/"
const GetAllBookedAwaitingPatients = "/api/Appointment/GetAllAwaitingPatients"
const UpdateRegistrarAppointment = "/api/Appointment/UpdateRegistrarAppointment"
export default {
    GetAllClerkedPatients,
    GetAllRegistrarDNA,
    GetAllPatientDNA,
    GetUserAvailability,
    UpdateUserAvailability,
    InsertPatinetAppointment,
    AuthUser,
    AuthTokenUser,
    GetPatientUserSlots,
    GetPatientAppointment,
    GetCancelledAppointments,
    GetUserAvailableSlots,
    UpdateAdminAutoMapRegistrar,
    GetAllPendingPatients,
    GetAllBookedPatients,
    GetAllBookedAwaitingPatients,
    SaveClerkingNotes,
    GetRegistrarBookedPatients,
    SaveRegistrarPatients,
    GetUserList,
    UpdateRegistrarAppointment
}