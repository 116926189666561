import moment from 'moment'
function bindRegistrar(modalData) {
    let data=
    `<div class="row mb-2 mt-2">
                <div class="col-5 text-left font-weight-bold">Referral ID</div>

                <div class="col-6 text-left">${modalData.ReferralId}</div>
            </div>

            <div class="row mb-2">
                <div class="col-5 text-left font-weight-bold">Patient Name</div>

                <div class="col-6 text-left ">${modalData.PatientName}</div>
            </div>

            <div class="row mb-2">
                <div class="col-5 text-left font-weight-bold">NHS Number</div>

                <div class="col-6 text-left ">${modalData.NhsNumber}</div>
            </div>

            <div class="row mb-2">
                <div class="col-5 text-left font-weight-bold">Mobile Telephone</div>

                <div class="col-6 text-left">${modalData.MobileTelephone}</div>
            </div>

            <div class="row mb-2">
                <div class="col-5 text-left font-weight-bold">Date</div>

                <div class="col-6 text-left">${moment.parseZone(modalData.StartDate).format('DD-MM-YYYY')}</div>
            </div>

            <div class="row mb-2">
                <div class="col-5 text-left font-weight-bold">Time</div>

                <div class="col-6 text-left">${moment.parseZone(modalData.StartDate).format('HH:mm A')} - ${moment.parseZone(modalData.EndDate).format('HH:mm A')}</div>
            </div>
            `
    return data;
}

export default { bindRegistrar }