import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import constants from '../../../util/constants'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '500px',
        [theme.breakpoints.down('xs')]: {
            height: '500px',
            top: '5%',
            position: 'absolute',
            width: '70%',
            overflow: 'auto',
        },
    },
}));
export default function MobileUpdate(props) {
    const classes = useStyles();
    const [isValid, setisValid] = React.useState(false);
    const [mobileNumber, setmobileNumber] = React.useState('');
    const onSubmit = () => {
        props.submitForm(mobileNumber)
    }
    const handleKeyPress = e => {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
            return false;
        }
        var value = e.target.value;
        if (constants.UK_MOBILE_REGEX.test(value)) {
            setisValid(true);
            setmobileNumber(value);
        }
        else {
            setmobileNumber(value)
            setisValid(false);
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <div className="row">

                            <h3 className="col-lg-12 font-weight-bold">Use a different phone number</h3>
                        </div>
                        <div className="my-3">
                            <div className="row">
                                <div className="col-sm-12">If you would prefer us to call you on a different number, please enter it here. </div>
                            </div>

                            <div className="row mt-3 ">
                                <div className="col-lg-12">
                                    <label>Your preferred phone number</label>
                                </div>
                                <div className="col-lg-12">
                                    <input onChange={handleKeyPress} type="text"  placeholder="Mobile number" className="form-control" ></input>
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col-lg-12">Would you like us to use this number for all communications to you about your care in future?</div>
                            </div>
                            {/* <div className="row mt-3">
                                <div className="col-lg-12"><input id="scales" type="checkbox"
                                checked={this.state.SecurityCheck}
                                                    onClick={() => {
                                                        this.setState({
                                                            showContact: !this.state.showContact ?? true,
                                                            SecurityCheck: !this.state.SecurityCheck
                                                        })
                                                    }}
                                ></input><label for="scales">Make this my preferred phone number</label></div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-12 text-center med-btn-group">
                                <button className="btn med-btn-default mr-3" onClick={props.handleClose}>Cancel</button>
                                <button className="btn med-btn-primary" disabled={!isValid} onClick={onSubmit}>Change number</button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
