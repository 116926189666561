import React from 'react'
import HelpSection from '../../components/Help/index'
import appConstants from '../../util/constants'
class ErrorPage extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                {/* <Header /> */}
                <div class="container ">
                    <div class="patientContainer">
                        <div className=" med-login mx-auto mt-5">
                            {this.props.errorObject ?
                                <React.Fragment>
                                    {this.props.errorObject.UserType == "PAT" ?
                                        <React.Fragment>
                                            <h1>Sorry</h1>
                                            <div className="mt-5">
                                            <p>{appConstants.LINK_EXPIRY_BOOKED}</p>
                                            </div>
                                        </React.Fragment>
                                        : <p>{this.props.errorObject.errorMessage}</p>
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <h1>Sorry</h1>
                                    <div className="mt-5">
                                        <p>{appConstants.SITE_UNAVAILABLE}</p>
                                    </div>
                                </React.Fragment>
                            }
                            <HelpSection></HelpSection>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* <Footer /> */}
            </React.Fragment>
        )
    }
}
export default ErrorPage
