import { combineEpics, ofType } from 'redux-observable'
import actions from '../../store/actions/constants'
import urls from '../../util/urls'
import { mergeMap } from 'rxjs/operators';
import fetch from '../../service/Fetch/fetchApi';
//import constructResponse from './index'
const fetchPatientEpicFulfilled = payload => ({ type: actions.GET_PATIENT_USER_SLOTS_SUCCESS, payload });

const fetchPatientEpic = (action$, store) =>
  action$.pipe(ofType(actions.GET_PATIENT_USER_SLOTS)
    , mergeMap(action =>
      fetch(`${urls.GetPatientUserSlots}`, action.payload, action.type)
        .then(response => fetchPatientEpicFulfilled(constructResponse(response)))
        .catch(() => console.log('errors'))
    ));

// const updateRegAvailabilityEpic = (action$, store) =>
//   action$.pipe(ofType(actions.UPDATE_REGAVAILABILITY)
//     , mergeMap(action =>
//       fetch(`${urls.UpdateUserAvailability}`, action.payload, action.type)
//         .then(response => fetchPatientEpicFulfilled(constructResponse(response)))
//         .catch(() => console.log('errors'))
//     ));
    

const constructResponse = (response) => {
    let eventslist=[];
    // response.forEach(el => {
    //     var resp={
    //     "id": el.AvailBookingID,
    //     "title": el.StatusDesc,
    //     "start": new Date(el.StartDate),
    //     "end": new Date(el.EndDate),
    //     "desc": 'Referral ID : ' + el.ReferralId + ', Patient Name : ' + el.PatientName,
    //     "ReferralId": el.ReferralId,
    //     "PatientName": el.PatientName?? '',
    //     "NhsNumber": el.NhsNumber??''
    //   }
    //   eventslist.push(resp);
    // });
    return eventslist;
}
export default combineEpics(fetchPatientEpic)