import React from 'react'
import HelpSection from '../../components/Help/index'
export default function Error500() {
    return (
        <React.Fragment>
            {/* <Header></Header> */}
            <div className="container patientContainer ">

                <div className=" med-login mx-auto mt-5">
                    <h1>Sorry</h1>
                    <div className="mt-5">
                        <p>Something has gone wrong</p>
                        <HelpSection></HelpSection>
                    </div>
                </div>
            </div>
            {/* <div class="container errorContainer">
                <div class="row justify-content-center errorContent">
                    <div class="col-lg-6">
                        <div class="text-center mt-4">
                            <h1 class="text-info">Something has gone wrong. </h1>
                            <div className="card mt-4">
                                <div className="card-body">
                                    <p className="text-left"><strong>What do I do now?</strong></p>
                                    <p className="text-left">You can contact our team on
                                <a class="InfinityNumber" href="tel:+448000112113"> 08000 112 113</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <Footer></Footer> */}
        </React.Fragment>
    )
}