import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import mainlogo from "../assets/Images/logo2.png";
import { Helmet } from 'react-helmet';
import { withRouter, BrowserRouter } from 'react-router-dom';

class Header extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.handleClickLogout = this.handleClickLogout.bind(this)
        this.state = {
            usertype: null,
            isToken: false,
            isDevice: window.matchMedia("(max-width: 768px)").matches
        };
        // this.state = { matches: window.matchMedia("(min-width: 768px)").matches };
    }



    UNSAFE_componentWillMount() {
        if (localStorage.getItem('token')) {
            this.setState({ isToken: true })
        }
        const handler = e => this.setState({ isDevice: e.matches });
        window.matchMedia("(max-width: 768px)").addListener(handler);
        this.setState({ usertype: localStorage.getItem('usertype') });
    }

    handleClickLogout() {
        localStorage.clear();
        window.location.href = "/PatientLogin"
    }
    renderRedirect = () => {
        // if (this.state.toDashboard === true) {
        //     if (this.state.usertype === "SR") {
        //         return <Redirect to='/RegAvalability' />
        //     }
        //     else if (this.state.usertype === "MedeferAdmin") {
        //         return <Redirect to='/Admin' />
        //     }
        //     else if (this.state.usertype === "PAT") {
        //         var isvalid = window.localStorage.getItem('isvalidpatinet');
        //         if (isvalid === "true") {
        //             return <Redirect to='/Appointment' />
        //         }
        //         else {
        //             return <Redirect to='/PatientLogin' />
        //         }
        //     }
        //     else {
        return <Redirect to='/PatientLogin' />
        // }
        // }
    };
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <style>{'body { background-color: #ffffff; } #fc_frame {display: block; }'}</style>
                </Helmet>
                {this.state.isDevice ?
                    <nav className="navbar navbar-expand headerSection static-top">
                        <div className="m-auto row headerSection">

                            <div className="col-1 text-left mr-3"> <div>Help</div></div>
                            <div className="col-6 text-center">
                                <BrowserRouter><Link to={'/'} className=""><img src={mainlogo} className="logo" alt="Logo" /></Link></BrowserRouter>
                            </div>

                            {/* <div className="col-4"> <h3>Patient Portal</h3></div> */}
                            {window.location.href.indexOf('Appointment') > 0 &&
                            <div className="col-2 text-right">
                                <input type="button" className="med-btn-primary logOutbtn" onClick={this.handleClickLogout} value="Log out" />
                                </div>
                            }
                        </div>
                    </nav>
                :
                <nav className="navbar navbar-expand headerSection static-top">
                    <div className="m-auto row headerSection">

                        <div className="col-5">
                            <BrowserRouter><Link to={'/'} className=""><img src={mainlogo} className="logo" alt="Logo" /></Link></BrowserRouter>
                        </div>

                        <div className="col-4"> <h3>Patient Portal</h3></div>
                        <div className="col-1 text-right"> <div>Support</div></div>
                        {window.location.href.indexOf('Appointment') > 0 &&
                            <input type="button" className="med-btn-primary logOutbtn" onClick={this.handleClickLogout} value="Log out" />
                        }
                    </div>
                </nav>
                }
            </React.Fragment>
        );
    }
}

// export default withRouter(Header);
export default Header;